@use 'src/style/common';

.header-row {
    background-color: common.$color-neutral-9;
    border: 0 0 1px 0 solid common.$color-neutral-8;
    font-size: common.$size-font-0;
    line-height: common.$size-font-3;
}

.content-row {
    border-style: solid;
    border-color: common.$color-neutral-8;
    border-width: 0 0 1px 0;

    &:last-of-type {
        border-bottom-width: 0;
    }

    &.small {
        font-size: common.$size-font-1;
        line-height: common.$size-font-3;
    }

    &:hover {
        background-color: common.$color-neutral-9;
    }
}
