@use 'src/style/common';

.action-dropdown {

    .dropdown {
        .action {
            .dropdown-item {
                .cancel-action-button {
                    color: common.$color-red-4;
                }
            }

            .action-icon {
                color: common.$color-neutral-0;
                margin-right: common.$size-spacing-0;
                font-size: common.$size-font-1;
                vertical-align: middle;

                &.cancel {
                    color: common.$color-red-4;
                }
            }

            .edit-auction-deadline-action-name {
                display: inline-block;
                width: 125px;
            }

            .add-on-demand-carrier-action-name {
                display: inline-block;
                width: 140px;
            }
        }
    }

    .action-dropdown-popup.loading {
        text-align: center;

        .heading {
            margin-top: common.$size-spacing-3;
        }
    }

    .action-dropdown-popup {
        height: auto;
        white-space: normal;
        font-weight: 400;

        .description {
            margin-top: common.$size-spacing-3;
            margin-bottom: common.$size-spacing-4;

            .bold {
                font-weight: 600;
            }
        }

        .subheading {
            font-weight: 600;
            margin-left: 0;
            color: common.$color-neutral-0;
        }

        .select {
            height: 40px;
            margin-bottom: common.$size-spacing-5;
        }

        .date-picker {
            margin-bottom: common.$size-spacing-4;
        }

        .note-input {
            margin-bottom: common.$size-spacing-5;
        }

        .buttons-wrapper {
            text-align: end;
        }

        &.change-deadline-popup .date-picker {
            margin-bottom: 0;
        }
    }
}
