@use 'src/style/common';

.profile-overview {
    display: flex;
    flex-direction: column;
    
    .row-info-card {
        margin-top: common.$size-spacing-5;
        margin-bottom: common.$size-spacing-5;
        display: flex;

        &.contact {
            margin-top: 0;
        }

        .row-info-card-heading {
            width: 432px;  

            .title {
                font-weight: 600; 
            }

            .description {
                padding-top: common.$size-spacing-0;
                display: block;
                width: 360px;
                padding-bottom: common.$size-spacing-2;
                color: common.$color-neutral-2;
            }
        }

        .row-info-card-content {
            display: flex;
            gap: common.$size-spacing-4;

            .row-info-card-column {
                display: flex;
                flex-direction: column;
                gap: common.$size-spacing-4;
                width: 408px;

                .field {
                    &.equipment-type {
                        .value {
                            display: flex;
                            gap: common.$size-spacing-5;
                        }

                        .equipment-type-icon {
                            color: common.$color-neutral-5;
                            margin-right: common.$size-spacing-1;
                        }
                    }

                    .label {
                        color: common.$color-neutral-2;
                        
                        .info-icon-div {
                            position: relative;
                            display: inline;
                            margin-left: common.$size-spacing-0;

                            .info-icon {
                                @include common.icon-hover;
                                color: common.$color-neutral-6;
                            }
                        }
                    }

                    .value {
                        @include common.ellipsis-word-wrap;
                        display: inline-block;
                        max-width: 408px;
                        font-weight: 600; 
                    }

                    .input-field {
                        width: 408px;

                        .input-value {
                            @include common.ellipsis-word-wrap;
                            width: 320px;
                        }

                        .edit-options {
                            display: flex;
                            gap: common.$size-spacing-2;
                            margin-left: auto;
                        }

                        .icon {
                            width: common.$size-font-3;
                            height: common.$size-font-3;
                            margin-right: 0;

                            &.disabled {
                                cursor: not-allowed;
                            }
                        }
                    }
                }
            }
        }
    }
}
