@use 'src/style/common';

.upload-attachments-container {
    .upload-file-popup,
    .uploading-status-div {
        white-space: initial;
    }

    .attachment-upload-description {
        font-weight: 400;
    }
}
