@use 'src/style/common';

.breadcrumb {
    display: flex;
    text-transform: uppercase;
    font-size: common.$size-font-0;
    color: common.$color-neutral-6;
    margin-bottom: common.$size-spacing-2;
    padding-left: 0;
    line-height: 18px;
    font-weight: 600;

    .breadcrumb-item {
        display: flex;
        align-items: center;
        .breadcrumb-icon,
        .breadcrumb-text {
            &.inactive {
                color: common.$color-neutral-6;
            }

            &.active {
                color: common.$color-neutral-0;
            }
        }

        .breadcrumb-text {
            max-width: 300px;
            @include common.ellipsis-word-wrap;
            text-decoration: none;
        }

        .breadcrumb-icon {
            margin-left: common.$size-spacing-0;
            margin-right: common.$size-spacing-0;
        }
    }

    .back-item {
        margin-left: auto;
        display: flex;
        align-items: center;

        .breadcrumb-back {
            text-decoration: none;
            color: common.$color-neutral-6;
            max-width: 300px;
            @include common.ellipsis-word-wrap;
        }

        .breadcrumb-back-icon {
            margin-right: common.$size-spacing-0;
        }

        &:hover * {
            color: common.$color-neutral-0;
        }
    }
}
