@use 'src/style/common';

.spot-market-details {
    .empty-table {
        background-image: url('../../file/svg/medium-empty-table.svg');
    }

    .content-card {
        .header {
            height: 72px;
            padding: common.$size-spacing-2 common.$size-spacing-3;

            .info-group {
                .expansion-view .info-label {
                    margin-left: 0;
                }
                
                .menu {
                    order: 1;
                    margin-right: 0;
                    margin-left: common.$size-spacing-2;
                }

                .actions {
                    width: 245px;
                    margin-left: 0;
                }
            }
        }

        .separator {
            margin: 0;
        }

        .content-card-content {
            padding: common.$size-spacing-4;
        }
    }

    &.current {
        .offer-display {
            .content-card {
                border: 1px solid common.$color-primary-5;
            }
    
            .header {
                background: transparentize(common.$color-primary-5, 0.98);
            }
        }

        &.ready {
            .content-card {
                border: 1px solid common.$color-cyan-3;
            }
    
            .header {
                background: transparentize(common.$color-cyan-3, 0.98);
            }
        }

        &.pending {
            .content-card {
                border: 1px solid common.$color-orange-5;
            }
    
            .header {
                background: transparentize(common.$color-orange-5, 0.98);
            }
        }

        &.no-bids {
            .content-card {
                border: 1px solid common.$color-primary-5;
            }
    
            .header {
                background: transparentize(common.$color-primary-5, 0.98);
            }
        }
    }

    .header {
        margin: 0;

        .heading {
            font-weight: 600;
            font-size: common.$size-font-3;
            line-height: common.$size-spacing-4;
            color: common.$color-primary-5;

            .skipped {
                color: common.$color-neutral-0;
            }

            .heading-icon {
                margin-right: common.$size-spacing-0;
            }

            .focused-bid {
                display: flex;
                flex-direction: row;

                .field {
                    display: flex;
                    flex-direction: column;
                    line-height: common.$size-spacing-4;
                    justify-content: space-between;
                    margin-right: common.$size-spacing-4;

                    .field-title {
                        color: common.$color-neutral-0;

                        &.spot-market {
                            color: common.$color-primary-5;
                        }
                    }

                    .field-content {
                        color: common.$color-neutral-0;
                        font-weight: 600;

                        &.pending {
                            color: common.$color-orange-5;
                        }

                        &.ready {
                            color: common.$color-cyan-3;
                        }

                        &.error {
                            color: common.$color-red-4;
                        }
                    }

                    &.name {
                        width: 365px;
                        margin-left: 0;
                        position: relative;

                        a {
                            color: inherit;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        .field-content {
                            @include common.ellipsis-word-wrap;
                        }

                        .icon-wrapper {
                            display: inline-flex;
                            font-weight: 400;

                            &.terminated-carrier {
                                .tooltip {
                                    &.bottom {
                                        transform: translate(-191%);
                                    }
                                }
                            }

                            &.missing-documents {
                                .tooltip {
                                    &.bottom {
                                        transform: translate(-141%);
                                    }
                                }
                            }

                            .warning-icon {
                                padding-top: common.$size-spacing-0;
                                color: common.$color-red-4;
                            }
                        }
                    }

                    &.bid-total {
                        width: 115px;
                        text-align: right;
                        margin-right: 40px;

                        .text-emphasis-box {
                            display: inline;
                        }
                    }

                    &.bid-per-mile {
                        width: 65px;
                        text-align: right;
                        margin-right: 48px;
                    }

                    &.date {
                        width: 160px;
                        text-align: left;
                        margin-right: common.$size-spacing-3;
                    }

                    &.status {
                        margin-left: 100px;
                        margin-right: 0;
                        width: 210px;

                        .field-title {
                            display: inline-flex;
                        }

                        .status-label {
                            margin-left: common.$size-spacing-0;

                            &.skipped,
                            &.falloff {
                                color: common.$color-neutral-6;
                            }

                            &.spot-market {
                                color: common.$color-primary-5;
                            }

                            &.pending {
                                color: common.$color-orange-5;
                            }

                            &.ready {
                                color: common.$color-cyan-3;
                            }

                            &.under-review {
                                color: common.$color-orange-5;
                            }

                            &.canceled {
                                color: common.$color-red-4;
                            }
                        }

                        .title-tooltip {
                            margin-left: common.$size-spacing-0;
                            position: relative;
                            color: common.$color-neutral-6;

                            &:hover {
                                color: common.$color-neutral-0;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .info-group {
            .menu {
                margin-left: 0;
                margin-right: common.$size-spacing-2;

                .focused-bid-menu {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
            
                    .menu-icon-container {
                        display: flex;
                        align-items: center;
            
                        .icon {
                            font-size: common.$size-font-3;
                            color: common.$color-neutral-6;
                            position: relative;
                        }
                    }
            
                    .dropdown {
                        white-space: unset;
                    }
                }
            }

            .expansion-view {
                margin-right: 0;
            }
        }
    }

    .price-options {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        height: 44px;
        margin-right: 140px;

        .separator {
            margin: 0 !important;
            width: common.$size-border;
        }

        .prices {
            display: flex;
            flex-direction: row;

            .book-now {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .book-now-label {
                    text-align: right;
                }

                .price {
                    font-weight: 600;
                    display: inline-flex;
                    justify-content: flex-end;

                    .add-icon {
                        margin-right: common.$size-spacing-0;
                        color: common.$color-cyan-3;
                    }

                    .edit-icon,
                    .reset-icon {
                        margin-left: common.$size-spacing-0;
                        color: common.$color-default-icon-state;

                        &:hover {
                            color: common.$color-hover-state;
                        }
                    }
                }
            }

            .suggested {
                position: relative;
                margin-left: common.$size-spacing-4;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .price {
                    font-weight: 600;
                }
            }

            .suggested {
                .icon-div {
                    position: relative;
                    display: inline;
                    color: common.$color-default-icon-state;
                    margin-left: common.$size-spacing-0;

                    .icon {
                        font-size: common.$size-font-1;
                    }

                    &:hover {
                        color: common.$color-hover-state;
                    }
                }
            }
        }
    }

    .content {
        margin-top: common.$size-spacing-2;
        margin-left: 0 !important;
    }

    .candidates,
    .bids {
        .title {
            font-weight: 600;

            .amount {
                margin-left: common.$size-spacing-0;
                color: common.$color-neutral-6;
            }

            .expansion-prompt {
                margin-left: common.$size-spacing-1;
                font-weight: 600;
                color: common.$color-primary-5;
                cursor: pointer;
            }
        }
    }

    .bids {
        margin-bottom: common.$size-spacing-4;

        .list {
            margin-top: common.$size-spacing-1;

            .pagination-container {
                margin-top: common.$size-spacing-1; 
                background-color: inherit;
            }

            .separator {
                margin: 0;
            }
        }
    }

    .candidates {
        .list {
            margin-top: common.$size-spacing-3;

            .remaining-candidates {
                font-weight: 600;
                color: common.$color-neutral-4;
            }
        }
    }

    .tab-container {
        .sm-candidates-search {
            padding-top: common.$size-spacing-4;

            &.active {
                margin-bottom: common.$size-spacing-3;
            }

            .search-filter {
                .text-input {
                    width: 494px;
                }
            }
        }

        .sm-bids-table {
            max-width: 1176px;

            .header-cell {
                border-bottom: 1px solid common.$color-neutral-8;
                padding: common.$size-spacing-2 common.$size-spacing-4;
    
                &.name {
                    width: 343px;
                }
    
                &.bid {
                    width: 175px;
                }
    
                &.per-mile {
                    width: 86px;
                }
    
                &.date-of-bid {
                    &:last-child {
                        width: 556px;
                        padding: 0 common.$size-spacing-4;
                    }

                    padding-left: common.$size-spacing-4;
                    width: 238px;
                }

                &.actions {
                    width: 238px;
                }
            }

            .table-cell {
                padding: 0 common.$size-spacing-4;
            }
        }

        .sm-participants-table {
            margin-top: common.$size-spacing-3;
            max-width: 1174px;

            .header-cell {
                border-bottom: 1px solid common.$color-neutral-8;

                &.name {
                    width: 372px;
                }

                &.origin {
                    width: 300px;
                }

                &.email {
                    width: 300px;
                }

                &.phone {
                    width: 202px;
                }
            }
        }
        
        .pagination-container {
            width: 100%;
            margin-top: common.$size-spacing-4;
        }
    }
}
