@use 'src/style/common';

.banner {
    width: fit-content;
    color: common.$color-neutral-0;
    display: flex;
    align-items: flex-start;
    @include common.rounded-corners-0;

    .icon {
        font-size: common.$size-font-1;
        margin-right: common.$size-spacing-0;
        margin-top: common.$size-spacing-0;
    }

    .exit {
        width: 8px;
        height: 11px;
        margin-left: common.$size-spacing-1;
        cursor: pointer;
    }

    .banner-content {
        line-height: 22px;

        &.bold {
            font-weight: 600;
        }

        ul {
            margin-bottom: 0;
            margin-top: common.$size-spacing-0;
            line-height: common.$size-spacing-4;
        }
    }

    .banner-actions {
        cursor: pointer;
        margin-left: auto;
    }

    &.large {
        padding: common.$size-spacing-2 common.$size-spacing-1;
    }

    &.medium {
        padding: common.$size-spacing-1 common.$size-spacing-1;
    }

    &.small {
        padding: common.$size-spacing-0 common.$size-spacing-0;
        padding-right: common.$size-spacing-1;
    }

    &.info {
        background: common.$color-primary-9;

        .icon {
            color: common.$color-primary-5;
        }
    }

    &.warn {
        background: common.$color-orange-9;

        .icon {
            color: common.$color-orange-5;
        }
    }

    &.error {
        background: common.$color-red-9;

        .icon {
            color: common.$color-red-4;
        }
    }

    &.success {
        background: common.$color-cyan-9;

        .icon {
            color: common.$color-cyan-3;
        }
    }
}
