@use 'src/style/common';

.header-quick-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .quick-action {
        position: relative;
        font-size: common.$size-font-3;
        margin: 0 common.$size-spacing-0;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            color: common.$color-dark-text-2;
            cursor: pointer;
            user-select: none;

            &:hover {
                color: common.$color-dark-text-0;
            }
        }
    }
}
