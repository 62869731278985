@use 'src/style/common';

.header-account-info {
    position: relative;
    padding-left: common.$size-spacing-4;

    .account-info-picture {
        background-color: common.$color-neutral-8;
        width: 32px;
        height: 32px;
        border-radius: common.$size-spacing-3;
        text-align: center;
        line-height: 32px;
        font-size: common.$size-font-1;
        font-weight: 600;
        user-select: none;
        cursor: pointer;

        .initials {
            max-width: 32px;
            @include common.ellipsis-word-wrap;
        }

        &.avatar {
            background-color: transparent;
            width: auto;
            height: auto;
            max-width: 32px;
            max-height: 32px;
        }
    }

    a {
        text-decoration: none;
        color: common.$color-neutral-0;
    }

    .account-warning-icon {
        position: absolute;
        bottom: common.$size-negative-min;
        right: 0;
        font-size: common.$size-font-1;

        svg {
            cursor: pointer;
        }
    }

    .dropdown-item {
        display: flex;
        align-items: center;
        color: common.$color-neutral-0;

        .text {
            width: 58px;
            font-weight: inherit;
            line-height: common.$size-spacing-3;
            margin-left: common.$size-spacing-1;
        }
    }
}
