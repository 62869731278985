@use 'src/style/common';

.page-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: common.$size-spacing-2;

    .page-title-container {
        display: inherit;
        flex-direction: inherit;
        width: 100%;

        .page-title {
            .page-title-description {
                font-size: common.$size-font-2;
                color: common.$color-neutral-4;
            }
        }

        .page-title-tooltip-container {
            position: relative;
            height: 40px;
            color: common.$color-default-icon-state;
            margin-left: common.$size-spacing-0;

            .page-title-tooltip-icon {
                @include common.component-hover;
                height: inherit;
            }

            &:hover {
                color: common.$color-hover-state;
            }
        }
    }
}
