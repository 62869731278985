@use 'src/style/common';

.survey-question-component {
    height: 316px;

    .survey-question-step {
        padding-top: common.$size-spacing-4;
        padding-left: common.$size-spacing-4;
        color: common.$color-neutral-4;
        font-size: common.$size-font-0;
        font-weight: 700;
    }

    .survey-question-text {
        margin: common.$size-spacing-0 common.$size-spacing-4 common.$size-spacing-4;
        font-size: common.$size-font-3;
        font-weight: 700;
    }

    .survey-question-content {
        padding-left: common.$size-spacing-4;
        padding-right: common.$size-spacing-4;

        .textarea-input {
            height: common.$size-spacing-8;
        }
        
        .survey-rating-labels {
            display: flex;
            justify-content: space-between;
            padding-top: common.$size-spacing-0;
            font-size: common.$size-font-1;
            font-weight: 400;
            color: common.$color-neutral-4;
        }

        &.rating {
            margin-bottom: 70px;
        }

        &.comment {
            margin-bottom: 14px;
        }
    }

    .survey-question-actions {
        display: flex;
        justify-content: space-between;
        padding-bottom: common.$size-spacing-4;
        padding-right: common.$size-spacing-4;
        padding-left: common.$size-spacing-4;
        
        .termination-section {
            display: flex;
            justify-content: flex-start;

            .button {
                border: none;
            }
        }

       .navigation-section {
            display: flex;
            justify-content: flex-end;
            gap: common.$size-spacing-1;

            .survey-back-button {
                &.hidden {
                    display: none;
                }
            }
        }
    }
    
    &.hidden {
        display: none;
    }
}
