@use 'src/style/common';

.notification-menu {
  height: 32px;

  .notification-dropdown-link {
    display: block;
    margin:common.$size-spacing-1;  
    margin-top: common.$size-spacing-0;
  }

  .red-dot-indicator {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 6px;
    top: 17px;
    width: 8px;
    height: 8px;
    background-color: common.$color-red-5;
    border: 1px solid common.$color-component-background-light;
    border-radius: 100%;
  }
    
  &.opened {
    @include common.rounded-corners-1;
    background: common.$color-neutral-9;
  }
}
