@use 'src/style/common';

.ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item {
        line-height: 30px;
        height: 30px;

        &:hover {
            background-color: common.$color-neutral-9;
        }
    
        &.ant-menu-item-selected {
            background-color: common.$color-component-background-light;
        }
    }

    .ant-menu-submenu {
        font-size: 12px;
        line-height: 16px;

        .ant-menu-submenu-title {
            color: common.$color-neutral-0;

            .ant-menu-submenu-arrow {
                color: common.$color-neutral-6;
            }
        }
    }
}
