@use 'src/style/common';

.rfp-lane-details-page {

    .empty-list {
        margin: common.$size-spacing-7 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: common.$size-spacing-1;
    }

    .content-card {
        .content-card-content {
            .rfp-lane-bids-table {
                margin-top: 0;

                .rfp-lane-bids-candidate-name {
                    max-width: 350px;
                    width: 350px;
                    @include common.ellipsis-word-wrap;
            
                    .link {
                        color: inherit;
            
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
        
                .rfp-lane-bids-rank {
                    width: 1px; // Keeps the column at the width of its content
                }
        
                .rfp-lane-bids-status {
                    .proposed {
                        color: common.$color-orange-5;
                    }
        
                    .accepted {
                        color: common.$color-cyan-3;
                    }
        
                    .expired {
                        color: common.$color-neutral-4;
                    }
        
                    .rejected {
                        color: common.$color-red-4;
                        display: flex;
                        align-items: center;
                        
                        .reject-reason-tooltip-container {
                            @include common.tooltip-container;
        
                            .tooltip-icon {
                                font-size: common.$size-font-0;
                            }
                        }
                    }
                }
        
                .award-bid-action {
                    color: common.$color-neutral-7;
        
                    &:hover {
                        color: common.$color-hover-state;
                        cursor: pointer;
                    }
        
                    &.disabled:hover {
                        color: common.$color-neutral-7;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}
