@use 'src/style/common';

.search-filter {
    .text-input {
        width: 323px;

        .icon.unfocused {
            cursor: default;
            color: common.$color-neutral-6;
        }
    }

    .submit {
        display: none;
    }
}
