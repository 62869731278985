@use 'src/style/common';

.lane-visualization {
    $location-max-width: 170px;

    position: relative;
    display: flex;
    align-items: baseline;
    width: 450px;
    margin-bottom: common.$size-spacing-0;

    .lane-origin,
    .lane-destination {
        max-width: $location-max-width;

        .content {
            @include common.ellipsis-word-wrap;
            display: block;
        }
    }

    .lane-origin {
        margin-right: common.$size-spacing-2;
    }

    .lane-destination {
        margin-left: common.$size-spacing-2;
        min-width: $location-max-width;
        width: $location-max-width;
    }

    .lane-illustration {
        position: relative;
        flex-grow: 1;
        font-size: common.$size-font-1;
        display: flex;
        align-items: center;
        color: common.$color-neutral-4;

        > .icon {
            margin-top: common.$size-spacing-0;
            margin-right: common.$size-spacing-1;
        }

        .arrow-container {
            position: relative;
            width: 100%;
            text-align: center;

            .distance {
                @include common.component-background;
                color: inherit;
                position: relative;
                top: 0;
                z-index: 50;
                padding: common.$size-spacing-0 common.$size-spacing-1;
            }

            .arrow {
                position: absolute;
                z-index: 49;
                top: 50%;
                left: 0;
                right: 0;
                height: common.$size-border;
                text-align: right;
                font-size: common.$size-font-2;
                background-color: common.$color-neutral-0;

                &.dedicated {
                    background-color: common.$color-dedicated-carrier;
                }

                &.spot-market {
                    background-color: common.$color-spot-market-carrier;
                }

                .icon {
                    display: block;
                    position: absolute;
                    right: common.$size-negative-min;
                    transform: translateY(-47%);
                }
            }
        }
    }

    &.labeled {
        align-items: flex-end;

        .lane-illustration {
            margin-bottom: common.$size-spacing-0;
        }

        .lane-origin,
        .lane-destination {
            display: flex;
            flex-direction: column;
            min-width: 0;

            .label {
                color: common.$color-neutral-4;

                &.with-tooltip {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center
                }

                .tooltip-container {
                    @include common.tooltip-container;
                    font-weight: initial;
                    letter-spacing: initial;
                    text-transform: initial;
                }
            }
        }
    }

    &.large {
        width: fit-content;
        max-width: 100%;

        .label {
            font-weight: 700;
            letter-spacing: 0.094rem;
            font-size: common.$size-font-0;
            text-transform: uppercase;
        }

        .lane-origin,
        .lane-destination {
            font-size: common.$size-font-5;
            max-width: initial;
            width: initial;
        }

        .lane-illustration {
            margin-bottom: common.$size-spacing-1;

            .arrow-container {
                min-width: 200px;

                .distance {
                    font-weight: 600;
                    font-size: common.$size-font-1;
                }
            }
        }  
    }

    &.dedicated {
        .lane-illustration {
            color: common.$color-dedicated-carrier;

            .arrow-container {
                .arrow {
                    background-color: common.$color-dedicated-carrier;
                }
            }
        }
    }

    &.spot-market {
        .lane-illustration {
            color: common.$color-spot-market-carrier;

            .arrow-container {
                .arrow {
                    background-color: common.$color-spot-market-carrier;
                }
            }
        }
    }

    &.lost,
    &.rejected {
        color: common.$color-neutral-6;

        .lane-illustration {
            color: common.$color-neutral-6;
        }
    }

    &.neutral {
        .lane-illustration {
            color: common.$color-neutral-0;
    
            .arrow-container {
                .arrow {
                    background-color: common.$color-neutral-0;
                }
            }
        }
    }
}
