@use 'src/style/common';

.remove-lane-carrier,
.remove-dedicated-carrier {
    display: inline-block;
    
    .remove-lane-carrier-popup,
    .remove-dedicated-carrier-popup {
        text-align: left;

        .description-info {
            .additional-explanation {
                color: common.$color-neutral-5;
                display: block;
                margin-top: common.$size-spacing-0;
            }

            .banner {
                align-items: flex-start;

                .icon {
                    margin-bottom: auto;
                    margin-top: common.$size-spacing-min;
                }
            }
        }
    }

    .action-container {
        position: relative;
    }
}
