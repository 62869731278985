@use 'src/style/common';

.bid-list {
    font-size: common.$size-font-1;
    width: 100%;
    max-height: 455px;
    overflow-y: scroll;

    .column-bid,
    .column-mile {
        width: 10%;
    }

    .column-bidder {
        width: 25%;
    }

    .column-date {
        width: 55%;
    }

    .align-right {
        text-align: right;
    }

    .list-header {
        display: flex;
        flex-direction: row;
    }

    .banner-div {
        margin: common.$size-spacing-4 0 common.$size-spacing-2 0;
    }

    .top-padding {
        padding-top: common.$size-spacing-2;
    }

    .list-content {
        .row {
            margin: 0;
            padding: common.$size-spacing-1 0;
            color: common.$color-neutral-4;

            &:last-of-type {
                padding-bottom: initial;
            }
        }

        .lowest-bid {
            color: common.$color-neutral-0;
        }

        .losing-bid {
            color: common.$color-red-4;
        }

        .winning-bid {
            color: common.$color-cyan-3;
        }

        .winning-bid,
        .losing-bid,
        .lowest-bid {
            small {
                font-weight: bold;
            }
        }

        .lowest-bidder-label {
            color: common.$color-neutral-4;
            font-weight: initial;
        }
    }
}
