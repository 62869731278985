@use 'src/style/common';
@use 'src/style/colors';

.tooltip {
    position: absolute;
    z-index: 102;
    display: flex;
    visibility: hidden;
    cursor: default;
    max-width: 680px;

    justify-content: center;
    align-items: center;
    text-align: center;

    &:hover {
        cursor: text;
    }

    &.left-align {
        text-align: left;
    }

    &.right-align {
        text-align: right;
    }

    &.center-align {
        text-align: center;
    }

    &.top {
        bottom: 100%;
        left: 50%;
        padding-bottom: 10px;
        transform: translate(-50%);

        .tooltip-arrow {
            top: 100%;
            margin-top: -10px;

            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top-width: 6px;
        }
    }

    &.top-left {
        bottom: 100%;
        right: 100%;
        padding-bottom: 10px;
        margin-right: -20px;

        .tooltip-arrow {
            top: 100%;
            left: 100%;
            margin-top: -10px;
            margin-left: -20px;

            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top-width: 6px;
        }
    }

    &.right {
        left: 100%;
        top: 0;
        bottom: 0;
        padding-left: 10px;

        .tooltip-arrow {
            right: 100%;
            margin-right: -10px;

            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-right-width: 6px;
        }
    }

    &.bottom {
        top: 100%;
        left: 50%;
        padding-top: 10px;
        transform: translate(-50%);

        .tooltip-arrow {
            bottom: 100%;
            margin-bottom: -10px;

            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom-width: 6px;
        }
    }

    &.left {
        right: 100%;
        top: 0;
        bottom: 0;
        padding-right: 10px;

        .tooltip-arrow {
            left: 100%;
            margin-left: -10px;

            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left-width: 6px;
        }
    }

    &.bottom-left {
        top: 100%;
        right: 100%;
        padding-top: 10px;
        margin-right: -20px;

        .tooltip-arrow {
            bottom: 100%;
            left: 100%;
            margin-bottom: -10px;
            margin-left: -20px;

            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom-width: 6px;
        }
    }

    .tooltip-arrow {
        @include common.component-border;
        border-color: colors.$neutral-0;
        position: absolute;
        width: 0;
        height: 0;
    }

    .tooltip-content {
        @include common.rounded-corners-0;

        font-size: common.$size-font-1;
        padding: common.$size-spacing-0 common.$size-spacing-1;
        white-space: pre-wrap;
        color: colors.$light-text-0;
        background-color: colors.$neutral-0;
        text-align: center;
        font-weight: 400;

        max-width: 660px;
        width: max-content;
        overflow-wrap: break-word;
    }
}
