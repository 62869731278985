.infinite-scroll {
    overflow: auto;

    .loader {
        text-align: center;
    }

    .end-message {
        display: flex;
        justify-content: center;
    }
}
