@use 'src/style/common';

.load-requirements-popover {

    .load-requirements-icon-container {
        display: flex;
        align-items: center;
        gap: common.$size-spacing-0;

        .list-icon {
            width: common.$size-spacing-4;
            font-size: common.$size-font-3;
            color: common.$color-cyan-3;
        }

        .prompt {
            font-weight: 600;
            color: common.$color-neutral-0;
        }

        .info-icon {
            width: common.$size-spacing-3;
            font-size: common.$size-font-1;
            color: common.$color-neutral-6;
        }

        &.active,
        &:hover {
            .prompt {
                text-decoration: underline;
            }
    
            .info-icon {
                color: common.$color-neutral-0;
            }
        }
    }

    .load-requirements-popover-content {
        padding: 20px;
        padding-bottom: 28px;
        display: flex;
        flex-direction: column;
        gap: common.$size-spacing-3;

        .load-requirements-popover-content-row {
            width: 226px;

            ul {
                padding-left: common.$size-spacing-4;
                margin-bottom: 0;

                li {
                    white-space: normal;
                }
            }

            .semibold {
                font-weight: 600;
            }
        }
    }
}
