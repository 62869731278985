@use 'src/style/common';

.lane-header-card-container {
    .lane-header-card {
        padding: common.$size-spacing-4;
        margin-bottom: common.$size-spacing-4;

        .lane-header-card-title-row {
            margin-bottom: common.$size-spacing-4;
        }

        .lane-header-card-details-row {
            display: flex;
            justify-content: space-between;

            .lane-header-card-details-row-group {
                display: flex;
                gap: common.$size-spacing-4;

                .book-now-price {
                    display: flex;
                    flex-direction: row;
                }

                .lane-header-card-component.rg-name {
                    .horizontal-card-component-body {
                        @include common.ellipsis-word-wrap;
                        max-width: 230px;
                    }
                }

                .notifications .horizontal-card-component-body {
                    display: flex;
                    justify-content: flex-end;

                    .not-subscribed {
                        color: common.$color-neutral-4;
                    }

                    .icon-div {
                        position: relative;
                        cursor: pointer;
                        color: common.$color-default-icon-state;
                        margin-left: common.$size-spacing-0;

                        .icon {
                            font-size: common.$size-font-1;
                        }
        
                        &:hover {
                            color: common.$color-hover-state;
                        }

                        .tooltip-content {
                            width: 257px;
                        }
                    }
                }
            }

            .horizontal-card-component-title {
                font-weight: 700;
            }
        }
    }
}
