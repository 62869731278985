@use 'src/style/common';

.stop-details {
    margin-top: common.$size-spacing-4;
    display: flex;
    flex-direction: column;

    .title {
        font-weight: 600;
        font-size: common.$size-font-3;
        margin-bottom: common.$size-spacing-2;
    }

    .info-provided {
        display: flex;
        flex-direction: row;
        width: 100%;

        .field {
            display: flex;
            flex-direction: column;
            margin-right: common.$size-spacing-2;

            .title {
                font-size: common.$size-font-1;
                font-weight: 400;
                margin-bottom: common.$size-spacing-3;
            }

            .content {
                @include common.ellipsis-word-wrap;
                font-weight: 600;
                margin: 0;
            }

            &:nth-last-child(1) {
                margin-right: 0;
            }

            &.location {
                width: 270px;
            }

            &.date {
                width: 180px;
            }

            &.text {
                width: 130px;
            }

            &.ref-numbers {
                width: 220px;

                .ref-numbers-content {
                    display: flex;
                    flex-direction: column;

                    &.expand-on-side {
                        flex-direction: row;

                        .content {
                            max-width: 125px;
                        }

                        .expand-label {
                            margin-top: auto;
                            margin-left: common.$size-spacing-1;
                        }
                    }

                    .reference-list {
                        display: flex;
                        flex-direction: column;
                    }

                    .toggle-expand {
                        color: common.$color-primary-5;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
