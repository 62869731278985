@use 'src/style/common';

.notification-pane {
    width: 472px;
    padding-left: common.$size-spacing-2;
    padding-right: common.$size-spacing-2;
    padding-top: common.$size-spacing-2;
    padding-bottom: common.$size-spacing-1;

    .notification-header {
        line-height: 22px;
        margin-bottom: common.$size-spacing-3;

        .title {
            display: flex;
            align-items: center;
            color: common.$color-neutral-1;
            font-weight: 600;
        }

        .link {
            cursor: pointer;
            text-align: right;
            text-decoration-line: underline;
            top: 0;
            line-height: unset;

            &.disabled {
                color: common.$color-neutral-7;
                cursor: auto;
            }
        }
    }

    .notification-list {
        width: 470px;
        max-height: 440px;
        overflow: scroll;
        margin-left: -(common.$size-spacing-3);
        padding-left: common.$size-spacing-1;
        padding-right: common.$size-spacing-1;

        .separator {
            width: 440px;
            margin-left: common.$size-spacing-3;
            margin-right: common.$size-spacing-3;
        }
    }

    .notification-pane-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 416px;

        .notification-pane-icon {
            color: common.$color-neutral-6;
        }

        .notification-pane-empty-description {
            color: common.$color-neutral-4;
        }
    }

    .notification-footer {
        padding-top: common.$size-spacing-2;
        border-top: common.$size-border solid common.$color-border-light;
        line-height: common.$size-spacing-3;

        .notification-footer-link {
            position: relative;
            left: 187px;
            color: common.$color-primary-5;
            text-decoration: underline;

            &.disabled {
                color: common.$color-neutral-7;
                cursor: auto;
            }
        }
    }
}
