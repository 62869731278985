@use 'src/style/common';

.rfp-bid-popup {
    width: 344px;

    .inputs {
        display: flex;
        gap: common.$size-spacing-2;
    }

    .per-load-description {
        line-height: common.$size-spacing-3;
        margin-top: common.$size-spacing-0;
        color: common.$color-neutral-4;
        font-weight: 600;
    }
}
