@use 'src/style/common';

.ant-drawer-content {
    .ant-drawer-wrapper-body {
        .ant-drawer-header {
            display: flex;

            .ant-drawer-title {
                margin-top: 14px;
            }

            .ant-drawer-close {
                position: static;
                margin-left: auto;
                padding-top: common.$size-spacing-1;
                
                .anticon-close svg{
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}
