@use 'src/style/common';

.rfp-lane-list-container {
    margin-top: common.$size-spacing-2;

    .empty-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: common.$size-spacing-1;
        padding-top: common.$size-spacing-4;
        padding-bottom: common.$size-spacing-8;

        .lane-icon {
            font-size: common.$size-font-8;
            margin-bottom: common.$size-spacing-2;
        }

        .csv-upload-container {
            margin-top: common.$size-spacing-2;
        }

        &.filter-result {
            padding-top: common.$size-spacing-8;
            row-gap: initial;
        }
    }

    .page-header-container {
        .page-title-container {
            width: inherit;
        }

        .header-actions {
            margin-left: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: common.$size-spacing-1;
        }
    }

    .rfp-lanes-filters {
        margin-top: common.$size-spacing-3;
    }
}
