@use 'src/style/common';

.notification-center-page {

    .breadcrumb {
        margin-bottom: common.$size-spacing-4;
    }

    .page-header-container {
        margin-bottom: common.$size-spacing-4;
    }

    .notification-center-tab-section {
        margin-bottom: common.$size-spacing-7;
        padding-top: common.$size-spacing-1;
        display: flex;
        gap: common.$size-spacing-4;

        .summary {
            min-width: 300px;
            width: 300px;
            display: flex;
            flex-direction: column;
            gap: common.$size-spacing-0;
        }

        .additional-information-popover {
            margin-top: common.$size-spacing-2;

            .popover-icon-container:hover {
                text-decoration: underline;
                color: common.$color-primary-5;
            }

            .additional-information-content {
                padding: common.$size-spacing-3;
                display: flex;
                flex-direction: column;
                gap: common.$size-spacing-2;

                .additional-information-content-title {
                    display: block;
                    line-height: 18px;
                }
            }
        }

        .notification-center-tab-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: common.$size-spacing-3;

            .notification-subscriptions-actions {
                display: flex;
                flex-direction: row;
                gap: common.$size-spacing-2;
                align-items: flex-end;

                .search-filter-container {
                    width: 100%;

                    .text-input {
                        width: 100%;
                    }
                }
            }

            .add-notification-subscriptions-popup {
                .multiple-input-field {
                    height: 96px;
                }
        
                .tooltip-content { 
                    width: 285px;
                }

                .custom-select-container {
                    margin-top: common.$size-spacing-3;
                }
            }

            .empty-list {
                height: 240px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: common.$size-spacing-0;
                border: common.$size-border solid common.$color-neutral-9;
                border-radius: common.$size-spacing-0;
                padding-top: 30px;
                background-image: url('../file/svg/empty-table-state.svg');

                .empty-list-icon {
                    margin-bottom: common.$size-spacing-1;
                }

                .empty-list-description {
                    color: common.$color-neutral-4;
                }
        
                .subscriptions-add-actions {
                    margin-top: common.$size-spacing-1;
                    display: flex;
                    gap: common.$size-spacing-2;
                }
            }

            .custom-table {
                width: 100%;

                .table-cell {
                    &.subscription-name {
                        width: 288px;
                        max-width: 288px;
                        @include common.ellipsis-word-wrap;
    
                        &.default-logistics-coordinator-name {
                            width: 680px;
                            max-width: 680px;
                        }

                        &.default-logistics-coordinator-name,
                        &.lane-specific-logistics-coordinator-cell {
                            position: relative;
                            overflow: visible;

                            a {
                                color: initial;
                            }

                            .additional-information-popover {
                                margin-top: 0;
                            }
                        }
                    }
            
                    &.subscription-email {
                        width: 288px;
                        max-width: 288px;
                        @include common.ellipsis-word-wrap;
                    }

                    &.lane-specific-logistics-coordinator-cell {
                        width: 170px;
                        max-width: 170px;
                        @include common.ellipsis-word-wrap;
                    }
                }
        
                .remove-subscription-action {
                    color: common.$color-neutral-6;
            
                    &:hover {
                        cursor: pointer;
                        color: common.$color-hover-state;
                    }
                }
        
                .remove-subscription-popup {
                    text-align: left;
                }
            }
        }
    }
}
