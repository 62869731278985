@use 'src/style/common';

.notification-overlay {
    position: absolute;
    top: 60px;
    right: -72px;
    min-height: common.$size-spacing-1;
    min-width: 448px;
    display: flex;
    flex-direction: column;

    padding-top: common.$size-spacing-2;
    padding-right: common.$size-spacing-2;
}
