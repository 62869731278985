@use 'src/style/common';

.contact-us-popover {
    
    .contact-us-icon-container {
        width: 118px;
        height: common.$size-spacing-5;

        background-color: common.$color-component-background-light;
        color: common.$color-neutral-0;
        border: 1px solid common.$color-neutral-8;
        border-radius: common.$size-spacing-0;

        display: flex;
        align-items: center;

        &.active,
        &:hover {
            border-color: common.$color-neutral-6;
        }

        .icon {
            width: common.$size-spacing-4;
            margin-left: common.$size-spacing-1;
            margin-right: common.$size-spacing-0;
        }
    }

    .contact-us-popover-content {
        padding: 20px;
        padding-bottom: 22px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .contact-us-popover-content-row {
            display: flex;
            align-items: center;

            a {
                color: inherit;
            }

            .icon {
                width: common.$size-spacing-3;
                color: common.$color-neutral-6;
                margin-right: common.$size-spacing-1;
            }
        }
    }
}
