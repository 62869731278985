@use 'src/style/common';

.csv-upload-container {
    .csv-upload-descriptipn {
        margin-bottom: common.$size-spacing-2;

        .description-list {
            margin-top: common.$size-spacing-2;
            padding-left: common.$size-spacing-4;
        }

        .description-section {
            margin-top: common.$size-spacing-2;
        }
    }

    .input-field {
        margin-top: common.$size-spacing-3;
    }

    .icon-div {
        position: relative;
        display: inline;
        color: common.$color-default-icon-state;
        margin-left: common.$size-spacing-0;

        .icon {
            font-size: common.$size-font-1;
        }

        &:hover {
            color: common.$color-hover-state;
            cursor: pointer;
        }
    }

    .loading-warning .banner {
        align-items: flex-start;
        margin-top: common.$size-spacing-1;
    }
}
