@use 'src/style/common';

.view-attachments-container {
    .dropdown-item .action-name {
        display: inline-block;
        width: 147px;
    }
    
    .see-attachments-popup {
        white-space: initial;

        .attachment-list {
            .attachments-heading {
                display: inline-block;
                margin-top: common.$size-spacing-1;
                margin-bottom: common.$size-spacing-3;
                text-transform: uppercase;
            }

            .attachment {
                display: flex;
                justify-content: space-between;

                .attachment-name {
                    cursor: text;
                    @include common.ellipsis-word-wrap;
                }

                .actions-wrapper {
                    min-width: 55px;
                    text-align: right;

                    .download-icon,
                    .remove-icon {
                        color: common.$color-neutral-6;
                        margin-right: common.$size-spacing-0;
                        margin-left: common.$size-spacing-1;

                        &:hover {
                            color: common.$color-neutral-0;
                            cursor: pointer;
                        }
                    }
                }
            }

            .separator {
                margin-top: common.$size-spacing-0;
                margin-bottom: common.$size-spacing-0;
            }
        }
    }

    .remove-attachment-popup {
        width: 250px;
        white-space: initial;
    }
}
