@use 'src/style/common';

.create-rfp-popup {
    white-space: normal;

    .create-rfp-input-field {
        &.bill-to-field {
            margin-bottom: common.$size-spacing-4;
        }

        margin-bottom: common.$size-spacing-2;

        .tooltip {
            width: 250px;
        }
    }

    .dynamic-lanes-switch {
        width: 344px;
    }

    .custom-radio-group {
        margin-bottom: common.$size-spacing-3;
    }

    .custom-range-picker-container {
        .tooltip {
            width: 300px;
        }
    }

    .ant-input-affix-wrapper,
    .multiple-input-field {
        height: 40px;
    }

    .multiple-input-field {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .error-message {
        display: block;
        color: common.$color-red-4;
        margin-top: common.$size-spacing-3;
        text-align: center;
        white-space: normal;
        overflow-wrap: break-word;
    }
}
