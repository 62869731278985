@use 'src/style/common';

.documents-list .content-card-content {
    .document-card {
        display: flex;
        flex-direction: row;
    
        .field {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: common.$size-spacing-5;
    
            .field-title {
                color: common.$color-neutral-0;
            }
    
            .field-content {
                color: common.$color-neutral-0;
                font-weight: 600;
                max-width: 100%;
    
                &.expired {
                    color: common.$color-red-4;
                }
            }
    
            &.name {
                width: 300px;
                margin-left: 0;
    
                .required {
                    color: common.$color-red-4;
                    margin-left: common.$size-spacing-0;
                }
            }
    
            &.uploaded-by {
                width: 150px;
    
                .field-content {
                    @include common.ellipsis-word-wrap;
                }
            }
    
            &.date {
                width: 160px;
                text-align: right;
            }
    
            &.expiration {
                width: 135px;
                text-align: right;
            }
    
            &.status {
                width: 230px;
    
                .field-content {
                    position: relative;
    
                    &.missing {
                        color: common.$color-neutral-4;
                    }
    
                    &.pending {
                        color: common.$color-orange-4;
                    }
    
                    &.approved {
                        color: common.$color-cyan-3;
                    }
    
                    &.rejected,
                    &.expired {
                        color: common.$color-red-4;
                    }
    
                    .icon-div {
                        cursor: pointer;
                        position: relative;
                        display: inline;
                        color: common.$color-default-icon-state;
                        margin-left: common.$size-spacing-0;
                        font-weight: 400;
    
                        .icon {
                            font-size: common.$size-font-2;
                        }
    
                        &:hover {
                            color: common.$color-hover-state;
                        }
                    }
                }
            }
    
            &.actions {
                width: 70px;
                margin-right: 0;
    
                .field-content {
                    display: flex;
                    flex-direction: row;
                    font-size: common.$size-font-3;
    
                    .action-icon {
                        color: common.$color-neutral-6;
                        cursor: pointer;
                        margin-right: common.$size-spacing-1;
    
                        &.approve {
                            color: common.$color-cyan-3;
                        }
    
                        &.reject {
                            color: common.$color-red-4;
                        }
    
                        &.disabled {
                            color: common.$color-neutral-8;
                        }
    
                        &:last-child {
                            margin-right: 0;
                        }
    
                        &.tooltip-container {
                            position: relative;
                            display: inline;
                        }
    
                        a {
                            color: inherit;
                        }
                    }
                }
    
                .popup-approve-document,
                .popup-reject-document,
                .popup-edit-document {
                    .subheading {
                        margin-bottom: common.$size-spacing-0;
                    }
    
                    .document-reject-reason-select {
                        margin-bottom: common.$size-spacing-3;
                    }
    
                    .bold-text {
                        font-weight: 600;
                    }
                }
    
                .popup-edit-document {
                    .status-options {
                        margin-bottom: common.$size-spacing-3;
    
                        &.approved .ant-radio-wrapper-checked {
                            color: common.$color-cyan-3;
                        }
    
                        &.rejected .ant-radio-wrapper-checked {
                            color: common.$color-red-4;
                        }
                    }
    
                    .additional-edit-data {
                        margin-bottom: common.$size-spacing-3;
                    }
                }
            }
        }
    }

    .separator {
        margin: common.$size-spacing-2 0;
        width: unset;
    }
}
