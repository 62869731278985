@use 'src/style/common';

.dropdown-container {
    display: flex;
    z-index: 199;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    
    &.clickable {
        pointer-events: none;
    }

    &.dynamic {
        position: absolute;
        width: 100vw;
        height: 100vh;
    }

    &:target {
        visibility: visible;
    }

    &.hidden {
        display: none;
    }

    .overlay {
        display: block;
        position: fixed;
        z-index: 200;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0;
        cursor: default;
    }

    .dropdown {
        @include common.component-background;
        @include common.component-border;
        @include common.rounded-corners-0;
        @include common.dark-shadow-1;

        position: relative;
        z-index: 201;
        padding: common.$size-spacing-0;
        cursor: default;
        border-width: common.$size-border;
        white-space: nowrap;

        > .tooltip {
            z-index: 202;
        }

        > .close {
            color: inherit;
            position: absolute;
            top: common.$size-spacing-0;
            right: common.$size-spacing-1;
            text-decoration: none;
            cursor: pointer;
        }

        .action {
            color: inherit;
            cursor: pointer;
            padding: common.$size-spacing-1 common.$size-spacing-1;

            .action-name {
                color: common.$color-neutral-0;
                font-weight: inherit;
            }

            &:hover {
                background: common.$color-neutral-9;
                font-weight: 600;
            }
        }
    }

    // Arrow dimensions
    $arrow-inner-width: 6px; // Required so that the whole arrow base has the width of 12px
    $arrow-border-width: 8px;
    $arrow-border-adjacent: $arrow-inner-width solid transparent;
    $arrow-border-opposite:  $arrow-inner-width solid common.$color-component-background-light;

    &.arrow-top,
    &.arrow-top-right,
    &.arrow-top-left {
        .dropdown::after,
        .dropdown::before {
            content: '';
            position: absolute;
            border-left: $arrow-border-adjacent;
            border-right: $arrow-border-adjacent;
            border-bottom: $arrow-border-opposite;
            margin-left: calc(-1 * #{$arrow-inner-width});
            top: calc(-1 * #{$arrow-inner-width});
            left: 50%;
        }

        .dropdown::before {
            border-left: $arrow-border-width solid transparent;
            border-right: $arrow-border-width solid transparent;
            border-bottom: $arrow-border-width solid;
            border-bottom-color: common.$color-neutral-8;
            top: calc(-1 * #{$arrow-border-width});
            margin-left: calc(-1 * #{$arrow-border-width});
        }
    }

    &.arrow-bottom,
    &.arrow-bottom-right,
    &.arrow-bottom-left {
        .dropdown::after,
        .dropdown::before {
            content: '';
            position: absolute;
            border-left: $arrow-border-adjacent;
            border-right: $arrow-border-adjacent;
            border-top: $arrow-border-opposite;
            margin-left: calc(-1 * #{$arrow-inner-width});
            bottom: calc(-1 * #{$arrow-inner-width});
            left: 50%;
        }

        .dropdown::before {
            border-left: $arrow-border-width solid transparent;
            border-right: $arrow-border-width solid transparent;
            border-top: $arrow-border-width solid;
            border-top-color: common.$color-neutral-8;
            bottom: calc(-1 * #{$arrow-border-width});
            margin-left: calc(-1 * #{$arrow-border-width});
        }
    }

    &.arrow-top-right,
    &.arrow-bottom-right {
        .dropdown::after,
        .dropdown::before {
            left: calc(100% - #{common.$size-spacing-3});
        }
    }

    &.arrow-top-left,
    &.arrow-bottom-left {
        .dropdown::after,
        .dropdown::before {
            left: common.$size-spacing-3;
        }
    }

    &.arrow-right {
        .dropdown::after,
        .dropdown::before {
            content: '';
            position: absolute;
            border-bottom: $arrow-border-adjacent;
            border-top: $arrow-border-adjacent;
            border-right: $arrow-border-opposite;
            left: calc(-1 * #{$arrow-inner-width});
            top: common.$size-spacing-3;
        }

        .dropdown::before {
            border-bottom: $arrow-border-width solid transparent;
            border-top: $arrow-border-width solid transparent;
            border-right: $arrow-border-width solid;
            border-right-color: common.$color-neutral-8;
            left: calc(-1 * #{$arrow-border-width});
            margin-top: calc(#{$arrow-inner-width} - #{$arrow-border-width});
        }
    }
}
