@use 'src/style/common';

$arrow-width: 8px;
$arrow-adjacent: 8px solid transparent;

@mixin arrow-height-sides {
    border-left: $arrow-adjacent;
    border-right: $arrow-adjacent;
}

@mixin arrow-width-sides {
    border-top: $arrow-adjacent;
    border-bottom: $arrow-adjacent;
}

.message-bubble {
    position: absolute;
    z-index: 10;
    display: flex;
    max-width: 680px;
    align-items: center;
    margin-left: common.$size-spacing-min;

    &.top {
        bottom: 100%;
        left: 50%;
        padding-bottom: 10px;
        transform: translate(-50%);

        .message-bubble-arrow {
            @include arrow-height-sides;
            top: 100%;
            left: 8.7%;
            margin-top: -11.5px;
            border-top-width: $arrow-width;
        }

        .message-bubble-content::before {
            @include arrow-height-sides;
            top: 96.4%;
            border-top-width: $arrow-width;
        }
    }

    &.right {
        left: 90%;
        top: 0;
        bottom: 50%;
        padding-left: 10px;

        .message-bubble-arrow {
            @include arrow-width-sides;
            right: 100%;
            margin-top: 10px;
            margin-right: -11.5px;
            border-right-width: $arrow-width;
        }

        .message-bubble-content::before {
            @include arrow-width-sides;
            right: 96.7%;
            bottom: 36%;
            top: 10px;
            border-right-width: $arrow-width;
        }
    }

    &.bottom {
        top: 100%;
        left: 50%;
        padding-top: 10px;
        transform: translate(-50%);

        .message-bubble-arrow {
            @include arrow-height-sides;
            bottom: 100%;
            left: 8.7%;
            margin-bottom: -11.5px;
            border-bottom-width: $arrow-width;
        }

        .message-bubble-content::before {
            @include arrow-height-sides;
            bottom: 96.4%;
            border-bottom-width: $arrow-width;
        }
    }

    &.left {
        right: 100%;
        top: 0;
        bottom: 0;
        padding-right: 10px;
        margin-left: 500px;
    
        .message-bubble-arrow {
            @include arrow-width-sides;
            left: 96.7%;
            margin-left: -1.5px;
            border-left-width: $arrow-width;
        }

        .message-bubble-content::before {
            @include arrow-width-sides;
            left: 96.7%;
            bottom: 42.8%;
            border-left-width: $arrow-width;
        }
    }

    .message-bubble-arrow {
        @include common.component-border;
        border-color: common.$color-component-background-light;
        display: block;
        position: absolute;
        width: 0;
        height: 0;
    }

    .message-bubble-content::before {
        @include common.component-border;
        content: '';
        position: absolute;
        z-index: -1;
    }

    .message-bubble-content {
        @include common.rounded-corners-0;
        font-size: common.$size-font-1;
        padding: common.$size-spacing-4;
        white-space: pre-wrap;
        border: 1px solid common.$color-neutral-8;
        color: common.$color-neutral-0;
        background-color: common.$color-component-background-light;
        box-shadow: 0 12px 12px -8px rgba(31, 41, 51, 0.16);
        margin-bottom: 100px;
        max-width: 298px;
        width: max-content;
        overflow-wrap: break-word;
    }
}
