@use 'src/style/common';

.settings-page {
    .settings-page-body {
        background-color: common.$color-component-background-light;
        margin-top: common.$size-spacing-4;
        position: absolute;
        min-width: calc(100% - 3.5rem);
        left: 3.5rem;
        height: 100%;
        
        .settings-page-content {
            width: common.$size-page-width;
            margin: auto;
            margin-top: common.$size-spacing-5;
        }
    }
}
