@use 'src/style/common';

.reject-offer-popup {

    .carrier-name {
        font-weight: 600;
    }

    .reason-text-area {
        margin-top: common.$size-spacing-3;
    }

    .subheading {
        margin-bottom: common.$size-spacing-1;
    }

    .select {
        font-weight: normal;
        height: 40px;
    }
}
