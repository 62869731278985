@use 'src/style/common';

.notification-item {

    position: relative;
    left: 0;
    width: inherit;
    cursor: pointer;
    white-space: normal;
    transform: translateX(0px);
    margin: common.$size-spacing-0 0;
    transition: transform ease-in-out .4s, opacity linear .2s;
    display: flex;
    padding: common.$size-spacing-2 common.$size-spacing-3;

    sup {
        top: 0;
    }

    &.notification-component {
        @include common.component-shadow-0;
        transform: translateX(150%);
        margin: common.$size-spacing-2 0 0;
        cursor: pointer;

        &.visible {
            transform: translateX(0);
        }
    }

    &.info {
        background: linear-gradient(0deg, rgba(common.$color-primary-5, 0.05), rgba(common.$color-primary-5, 0.05)), #FFFFFF;
    }

    &.alert {
        background: linear-gradient(0deg, rgba(common.$color-red-4, 0.05), rgba(common.$color-red-4, 0.05)), #FFFFFF;
    }

    &.success {
        background: linear-gradient(0deg, rgba(common.$color-cyan-3, 0.05), rgba(common.$color-cyan-3, 0.05)), #FFFFFF;
    }

    &.seen {
        color: common.$color-neutral-4;
    }

    .notification-item-header {
        width: 14px;
        margin-right: common.$size-spacing-0;

        .notification-type-icon {
            width: 12px;
            line-height: 22px;
            margin-right: common.$size-spacing-0;

            &.seen {
                color: common.$color-neutral-4;
            }

            &.info {
                color: common.$color-primary-5;
            }

            &.alert {
                color: common.$color-red-5;
            }

            &.success {
                color: common.$color-cyan-3;
            }

        }
    }

    .notification-item-content {
        flex-grow: 1;
        font-weight: 400;
        margin-right: 28px;

        &.notification-component {
            max-width: 486px;

            .separator {
               margin-top: common.$size-spacing-2;
               margin-bottom: common.$size-spacing-1;
            }
        }

        .message {
            line-height: 18px;
            color: common.$color-neutral-0;
            background: transparent;
            display: inline-block;

            &.seen {
                color: common.$color-neutral-4;
            }
        }

        .time {
            line-height: common.$size-spacing-3;
            margin-left: common.$size-spacing-min;
            color: common.$color-neutral-6;
            background: transparent;

            &.inline {
                white-space: nowrap;
            }
        }

        .notification-info {
            display: block;
            line-height: common.$size-spacing-3;
            margin-top: common.$size-spacing-0;

            .title {
                font-weight: 400;
            }

            .inline {
                white-space: nowrap;
            }

            .text {
                font-weight: 600;
                margin-left: common.$size-spacing-min;
                margin-right: common.$size-spacing-1;
                word-break: break-word;

                &.lane-right {
                    margin-left: common.$size-spacing-0;
                }

                &.lane-left {
                    margin-right: common.$size-spacing-0;
                }
            }

            .info {
                color: common.$color-primary-5;
            }

            .alert {
                color: common.$color-red-5;
            }

            .success {
                color: common.$color-cyan-3;
            }

            &.seen {
                color: common.$color-neutral-4;
            }
        }

    }

    .notification-actions {
        line-height: common.$size-spacing-3;
        position: fixed;
        top: 17px;
        right: common.$size-spacing-3;
        color: common.$color-neutral-7;

        .notification-action {
            cursor: pointer;
        }

        &:hover {
            color: common.$color-neutral-0;
        }
    }
}
