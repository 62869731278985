@use 'src/style/common';

.bid {
    margin-top: common.$size-spacing-1;

    &.bordered {
        &.pending {
            @include common.pending-background;
        }

        &.ready {
            @include common.ready-background;
        }

        &.falloff {
            @include common.falloff-background;
        }
    }

    &.borderless {
        border: 0;
        padding: 0;
    }

    .data {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        height: 48px;

        .field-content {
            font-weight: 600;

            &.relevant {
                color: common.$color-neutral-0;

                &.exceeded {
                    color: common.$color-red-4;
                }
            }

            &.irrelevant {
                color: common.$color-neutral-6;
            }

            .status-time {
                font-weight: 600;
            }
        }

        .field-title {
            .title-tooltip {
                display: inline-flex;
                position: relative;
                color: common.$color-neutral-6;
                font-size: common.$size-font-0;
                line-height: initial;

                &:hover {
                    color: common.$color-neutral-0;
                    cursor: pointer;
                }

                .tooltip-content {
                    font-weight: normal;
                    padding: common.$size-spacing-1;
                }

                &.exceeded {
                    font-weight: 600;
                    color: common.$color-red-4;
                }
            }
        }

        .field {
            margin-right: common.$size-spacing-4;
            display: flex;
            flex-direction: column;
            line-height: 24px;
            justify-content: space-between;

            &.name {
                width: 365px;
                margin-left: 0;

                .field-content {
                    display: flex;

                    &.ready {
                        color: common.$color-cyan-3;
                    }

                    &.pending {
                        color: common.$color-orange-5;
                    }

                    &.expired {
                        color: common.$color-red-4;
                    }

                    .carrier-name {
                        display: inline-block;
                        @include common.ellipsis-word-wrap;
    
                        a {
                            color: inherit;
        
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .warning-icon {
                        position: relative;
                        display: inline;
                        font-weight: normal;
                        font-size: common.$size-font-1;
                        vertical-align: bottom;
                        margin-right: common.$size-spacing-0;

                        .icon {
                            &.irrelevant {
                                color: common.$color-neutral-6;

                                &:hover {
                                    color: common.$color-neutral-0;
                                    cursor: pointer;
                                }
                            }

                            &.relevant {
                                color: common.$color-red-4;

                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }

            &.bid-total {
                width: 115px;
                text-align: right;
                margin-right: 40px;

                .field-content {
                    .text-emphasis-box {
                        width: fit-content;
                        margin-left: auto;
                    }
                }
            }

            &.bid-per-mile {
                width: 65px;
                text-align: right;
                margin-right: 48px;
            }

            &.date {
                width: 170px;
                text-align: left;
                margin-right: common.$size-spacing-3;
            }

            &.weekly-limit {
                width: 95px;

                .field-title {
                    text-align: right;
                }

                .field-content {
                    text-align: right;
                }
            }

            &.offer-status {
                width: 195px;
                text-align: left;

                .status-label {
                    color: common.$color-neutral-6;

                    &.failed {
                        color: common.$color-red-4;
                    }

                    &.pending {
                        color: common.$color-orange-5;
                    }

                    &.ready {
                        color: common.$color-cyan-3;
                    }
                }

                .field-content {
                    .number {
                        font-size: common.$size-font-4;

                        &:nth-child(2) {
                            margin-left: common.$size-spacing-1;
                        }
                    }

                    .descriptor {
                        font-weight: normal;
                        font-size: common.$size-font-1;
                    }
                }
            }
        }

        .broker-bid-actions {
            margin-left: auto;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-top: common.$size-spacing-0;

            .menu-icon-container {
                display: flex;
                align-items: center;

                .icon {
                    font-size: common.$size-font-3;
                    color: common.$color-neutral-6;
                    position: relative;
                }
            }

            .dropdown {
                .popup {
                    white-space: normal;
                }

                .reject-offer-for-carrier-action-name {
                    display: inline-block;
                    width: 133px;
                }
            }
        }
    }

    .banner-wrapper {
        margin-top: common.$size-spacing-0;
    }
}
