@use 'src/style/common';

.upload-file-popup-container {
    .upload-file-popup {

        .popup-section {
            margin-bottom: common.$size-spacing-4;
            width: 100%;

            .subheading {
                display: flex;
                justify-content: space-between;

                .accepted-formats {
                    color: common.$color-neutral-6;
                    font-style: italic;
                    font-weight: initial;
                }

                .download-template-action {
                    display: inline;
                    color: common.$color-cyan-3;

                    .action-icon {
                        color: common.$color-cyan-3;
                    }

                    .action-name {
                        text-decoration: underline;
                        margin-left: common.$size-spacing-0;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .uploading-status-div {
        width: 100%;
        min-height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .uploading-status-icon {
            margin-bottom: common.$size-spacing-1;
        }

        h6 {
            font-weight: 600;
            margin-bottom: common.$size-spacing-0;
        }

        .upload-status-button {
            margin-top: common.$size-spacing-2;
        }

        &.success {
            .uploading-status-icon {
                color: common.$color-cyan-3;
                font-size: common.$size-font-6;
            }
        }

        &.error {
            .uploading-status-icon {
                color: common.$color-red-4;
                font-size: common.$size-font-6;
            }
        }
    }
}
