@use 'src/style/common';

.activity-empty-state {
    display: flex;
    justify-content: center;

    &.activity-log-empty-state {
        margin-top: 73px;
        margin-bottom: 68px;
    }

    &.activity-drawer-empty-state {
        margin-top: 150px;
    }

    .activity-empty-state-content {
        text-align: center;

        .activity-empty-state-description {
            margin-top: common.$size-spacing-5;
            font-weight: 600;
        }
    }
}
