
@use 'src/style/common';

.ant-picker,
.ant-picker-range {
    height: 40px;
    align-items: center;
    border: common.$size-border solid common.$color-neutral-8;
    box-sizing: border-box;
    border-radius: common.$size-border-wide;
    width: 100%;
    cursor: pointer;
}

.ant-picker:hover {
    border-color: common.$color-neutral-7;
}

.ant-picker .ant-picker-range .ant-picker-focused {
    border-color: common.$color-primary-5;
    outline: 0;
    box-shadow: none;
}

.ant-picker-focused {
    border-color: common.$color-primary-5;
}

.ant-picker-range .ant-picker-active-bar,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: common.$color-primary-5;
    cursor: pointer;
}

.ant-picker-input > input {
    cursor: pointer;
    text-align: left;
}
