@use 'src/style/common';

.edit-dedicated-carrier-popup {
    text-align: left;

    .input-fields-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: common.$size-spacing-1;
    }

    .per-mile-price {
        display: block;
        margin-top: common.$size-spacing-0;
        color: common.$color-neutral-4;
        font-weight: 600;
    }
}
