@use 'src/style/common';

.holiday-notice {
    color: inherit;
    display: flex;
    cursor: pointer;
    margin-right: common.$size-spacing-4;
    margin-top: common.$size-spacing-min;

    &:hover {
        color: inherit;
    }

    .calendar-icon {
        color: common.$color-primary-5;
        width: common.$size-font-2;
        height: common.$size-font-3;
        margin-right: common.$size-spacing-1;
        margin-top: 1px;
    }

    .holiday-break {
        padding-top: 1px;
        font-weight: 600;
    }

    .info-icon {
        width: 12px;
        height: 11px;
        margin-left: 6px;
        margin-top: common.$size-spacing-0;
        color: common.$color-neutral-6;

        &.active {
            color: common.$color-neutral-0;
        }
    }
}

.holiday-popover-content {
    padding: common.$size-spacing-4;

    .holiday-popover-label {
        padding-bottom: common.$size-spacing-3;
    }

    .holiday-popover-message {
        width: 250px;
        white-space: normal;

        b{
            font-weight: 600;
        }
    }
}
