@use 'src/style/common';

.current-bid {
    width: 100%;

    h6 {
        line-height: common.$size-spacing-5;
        font-weight: 600;
    }

    &.component {
        padding: common.$size-spacing-4 common.$size-spacing-5 common.$size-spacing-7 common.$size-spacing-5;
    }

    .title {
        padding: common.$size-spacing-4 0 common.$size-spacing-1 0;

        .win-indicator {
            padding-left: common.$size-spacing-1;
            display: inline-flex;
            align-items: center;

            .icon {
                margin-left: common.$size-spacing-0;
                font-size: common.$size-font-3;
            }
        }
    }

    .main-title {
        padding-top: 0;
    }

    small {
        display: block;
        line-height: common.$size-spacing-3;
    }

    .negotiated-rate {
        color: common.$color-neutral-0;
        margin-top: common.$size-spacing-0;
        margin-bottom: common.$size-spacing-3;

        .bold-price {
            font-weight: 600;
            margin: 0 common.$size-spacing-0;
            display: inline;
        }

        .icon-div {
            position: relative;
            color: common.$color-neutral-6;
            display: inline;

            .icon {
               &:hover {
                    color: common.$color-neutral-0;
                    cursor: pointer;
                } 
            }
        }
    }

    .flex-segment {
        width: 100%;
        display: flex;
        text-align: left;
        margin-bottom: common.$size-spacing-4;

        .price-summary {
            width: 65%;
        }

        .label {
            display: block;
            margin-bottom: common.$size-spacing-1;
        }

        .price {
            font-weight: 600;
            line-height: common.$size-spacing-5;
        }
    }

    .losing-bid {
        color: common.$color-red-4;
    }

    .winning-bid {
        color: common.$color-cyan-3;
    }

    .auction-closed {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 72px 0;

        .auction-closed-icon{
            color: common.$color-primary-5;
            font-size: common.$size-font-6;
            margin-bottom: common.$size-spacing-1;
        }

        h6 {
            line-height: 32px;
        }

        small {
            line-height: common.$size-spacing-3;
            color: common.$color-neutral-4;
        }
    }
}
