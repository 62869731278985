@use 'src/style/common';

.retry-sync-trigger {
    color: common.$color-red-4;
    font-weight: 600;

    .icon {
        font-size: common.$size-spacing-2;
        margin-left: 6px;
    }
}

.retry-sync-popup {
    .carrier-code {
        font-weight: 600;
    }
}
