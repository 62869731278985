@use 'src/style/common';

.popup-container {
    display: flex;
    z-index: 199;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    
    &.static {
        position: fixed;
    }

    &:target {
        visibility: visible;
    }

    &.hidden {
        display: none;
    }

    .overlay {
        display: block;
        position: fixed;
        z-index: 200;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0;
        cursor: default;

        &.dimmed {
            background-color: transparentize(common.$color-neutral-0, .85);
        }
    }

    .popup {
        @include common.component-background;
        @include common.component-border;
        @include common.rounded-corners-0;
        @include common.dark-shadow-2;

        width: 298px;
        position: relative;
        z-index: 201;
        padding: common.$size-spacing-4;
        cursor: default;
        border-width: common.$size-border;

        > .tooltip {
            z-index: 202;
        }

        > .close {
            color: inherit;
            position: absolute;
            top: common.$size-spacing-0;
            right: common.$size-spacing-1;
            text-decoration: none;
            cursor: pointer;
        }

        // Styles that are always used with popups
        .heading {
            font-weight: bold;
            line-height: 22px;
            color: common.$color-neutral-0;
            margin-bottom: common.$size-spacing-3;
            margin-left: 0;
        }

        .subheading {
            font-weight: bold;
            display: block;
        }

        .description {
            line-height: 20px;
            letter-spacing: 0.25px;
            margin-bottom: common.$size-spacing-3;
            word-break: break-word;
        }

        .buttons-wrapper {
            display: flex;
            gap: common.$size-spacing-1;
            justify-content: flex-end;
            margin-top: common.$size-spacing-5;
        }

        &.medium {
            width: 408px;
            padding: common.$size-spacing-5;

            .heading {
                margin-bottom: common.$size-spacing-0;
                line-height: common.$size-spacing-5;
            }
        }

        &.large {
            width: 640px;
            padding: common.$size-spacing-5;

            .heading {
                margin-bottom: common.$size-spacing-3;
                line-height: common.$size-spacing-5;
            }
        }
    }
}
