@use 'src/style/common';

.lane-header-card-container {
    .lane-header-card-component {
        &.active {
            color: common.$color-cyan-3;
        }
    
        &.pending {
            color: common.$color-orange-5;
        }
    
        &.rejected {
            color: common.$color-red-3;
        }

        &.expired,
        &.deleted {
            color: common.$color-neutral-4;
        }

        &.with-icon {
            .horizontal-card-component-body {
                display: flex;
                justify-content: flex-end;
                align-items: baseline;
                gap: common.$size-spacing-0;

                .status-icon {
                    font-size: common.$size-font-1;
                }
            }
        }

        &.offer-status {
            .status-tooltip-container {
                @include common.tooltip-container;
                display: inline;
                position: relative;
        
                .tooltip-icon {
                    font-size: 13px;
                }
            }
        }
    }

    .lane-header-card-details-row-group {
        &.pending {
            .horizontal-card-component-body {
                color: common.$color-orange-5;
            }
        }
        
        &.rejected,
        &.expired,
        &.deleted {
            .horizontal-card-component-body {
                color: common.$color-neutral-4;
            }
        }
    }
}
