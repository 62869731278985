@use 'src/style/common';

.survey-radion-button-group-container {
    .survey-radio-button-group {
        width: 408px;

        .ant-radio-button-wrapper {
            height: 48px;
            width: 65.6px;
            padding-top: 10px;
            font-size: common.$size-font-3;
            font-weight: 700;
            color: common.$color-neutral-4;
            margin-right: common.$size-spacing-1;
            border-radius: common.$size-border-wide;
            border: common.$size-border solid common.$color-neutral-8;
            text-align: center;

            &:hover {
                border: common.$size-border solid common.$color-neutral-6;
            }

            &:focus-within {
                box-shadow: none;
            }

            .ant-radio-button-checked {
                background-color: common.$color-cyan-9;
                border-radius: common.$size-border-wide;
            }
        }
    }

    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background: white;
        color: common.$color-cyan-3;
        border: 1px solid common.$color-cyan-3; 
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background: white;
    }

    .ant-radio-button-wrapper:not(:first-child)::before  {
        background-color: common.$color-neutral-8;
        border-radius: common.$size-border-wide;
        width: 0;
    }
}
