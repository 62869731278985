@use 'src/style/common';

.payment-details {
    .column-with-overflow {
        @include common.ellipsis-word-wrap;
    }

    .carrier-name,
    .description {
        width: 350px;
        max-width: 350px;
    }

    .simple-table {
        margin: 0;
    }
}
