@use 'src/style/common';

.auction-list-page {
    .page-header-container {
        margin-bottom: 16px;
    }

    .tab-container {
        height: calc(100% - 137.5px);
        display: flex;
        flex-direction: column;

        .tab-content-container {
            flex: 1 1 auto;
        }
    }

    .auction-list-page-content-background {
        background-color: common.$color-component-background-light;
        border-top: 1px solid #E4E7EB;
        margin-top: common.$size-spacing-1;
        position: absolute;
        min-width: calc(100% - 3.5rem);
        left: 3.5rem;
        height: calc(100% - 152px);
        overflow-y: auto;

        .auction-list-page-content {
            width: common.$size-page-width;
            margin: auto;
            margin-top: common.$size-spacing-4;
        }
    }

    .content-header {
        min-height: common.$size-spacing-4;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;
    }

    .collapsable-filter-container {
        margin-bottom: 16px;
    }

    .auctions-table-container {
        max-height: 660px;
        overflow-y: auto;
        overflow-x: hidden;
        border: 1px solid common.$color-neutral-8;
        border-radius: 4px;

        .auctions-table {
            border-top: 0;
            border-bottom: 0;
            width: 100%;
    
            tbody {
                width: 100%;
            }
    
            .header-row {
                .load {
                    border-left: 3px solid common.$color-neutral-9;
                }

                .arrow-header-cell {
                    padding: 0;
                }
            }
    
            .auction-table-row {
                &:not(.header-row):not(:hover) {
                    background-color: common.$color-component-background-light;
                }
    
                &.dedicated {
                    .table-cell.load {
                        border-left: 3px solid common.$color-dedicated-carrier;
                    }
                }
    
                &.spot-market {
                    .table-cell.load {
                        border-left: 3px solid common.$color-spot-market-carrier;
                    }
                }
    
                .auction-table-cell {
                    .auction-table-cell-title {
                        font-weight: 500;
                    }
    
                    .auction-table-cell-content {
                        color: common.$color-neutral-4;
                        font-size: common.$size-font-0;
                    }

                    &.load {                        
                        .auction-table-cell-title > div,
                        .auction-table-cell-content div {
                            width: 100px;
                            max-width: 100px;
                            cursor: pointer;
                            @include common.ellipsis-word-wrap;
                        }

                        .auction-table-cell-title {
                            position: relative;

                            .cargo-type-icon-container {
                                margin-right: 4px;
                                position: absolute;
                                display: inline;
                                cursor: pointer;

                                .cargo-type-icon {
                                    width: 12px;
                                }
        
                                .dry {
                                    color: common.$color-neutral-5;
        
                                    &:hover {
                                        color: common.$color-neutral-3; 
                                    }
                                }
        
                                .reefer {
                                    color: common.$color-primary-7;
        
                                    &:hover {
                                        color: common.$color-primary-5;
                                    }
                                }
        
                                .flatbed {
                                    color: common.$color-orange-6;
        
                                    &:hover {
                                        color: common.$color-orange-4;
                                    }
                                }
                            }
        
                            .load-link {
                                color: inherit;
    
                                &::before {
                                    content: '';
                                    display: inline-block;
                                    width: 18px;
                                }
                            }
                        }

                        .auction-table-cell-content .rfp-link {
                            color: inherit;
                            width: 100px;
                            display: block;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
    
                    &.region div {
                        @include common.ellipsis-word-wrap;
                    }
    
                    &.arrow {
                        color: common.$color-neutral-6;
                    }
    
                    &.carrier-link {
                        width: 120px;
                        max-width: 120px;
    
                        .auction-table-cell-title {
                            color: inherit;
    
                            div {
                                cursor: pointer;
                                @include common.ellipsis-word-wrap;
                            }

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
    
                    &.rank {
                        .auction-table-cell-content {
                            display: flex;
                            
                            .winning {
                                color: common.$color-cyan-3;
                                font-weight: 600;
                            }
        
                            .losing {
                                color: common.$color-red-4;
                                font-weight: 600;
                            }
                        }
                    }

                    &.book-now,
                    &.rate {
                        max-width: 70px;
                        
                        div {
                            @include common.ellipsis-word-wrap;
                        }
                    }
    
                    &.expiration {
                        &.warning .auction-table-cell-title {
                            color: common.$color-red-4;
                        }
                    }
    
                    &.status {
                        .expiration-time {
                            color: common.$color-neutral-0;
                            font-weight: 500;
                        }
    
                        &.with-mismatch {
                            display: flex;
                            justify-content: flex-end;
                            
                            .tmw-warning-icon-container {
                                position: relative;
        
                                .tmw-warning-icon {
                                    width: common.$size-spacing-2;
                                    margin-right: 2px;
                                    color: common.$color-red-4;
        
                                    &:hover {
                                        color: common.$color-red-2;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
    
                        &.title {
                            font-weight: 500;
                        }
    
                        &.pending,
                        &.in-progress {
                            color: common.$color-orange-5;
                        }
    
                        &.progress {
                            color: common.$color-primary-5;
                        }
    
                        &.success,
                        &.completed {
                            color: common.$color-cyan-3;
                        }
    
                        &.error,
                        &.failed {
                            color: common.$color-red-4;
                        }

                        &.falloff,
                        &.expired {
                            color: common.$color-neutral-6;
                        }
                    }
    
                    &.actions {
                        .carrier-actions {
                            display: flex;
                            justify-content: flex-end;
                            
                            > .buttons-wrapper {
                                gap: 2px;

                                .action-button-container {
                                    position: relative;
                                }
                            }
    
                            .auctions-list-action {
                                width: common.$size-spacing-4;
                                height: common.$size-spacing-4;
                                padding: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 3px;
                                border: 1px solid common.$color-neutral-8;
    
                                &:hover:not([disabled]) {
                                    border-color: common.$color-neutral-6;
                                }
    
                                .icon {
                                    margin: 0;
                                    font-size: common.$size-font-1;
                                }
    
                                &.accept {
                                    color: common.$color-cyan-3;
                                }
    
                                &.reject {
                                    color: common.$color-red-4;
                                }
                            }
                        }
                    }
                }
    
                .header-cell,
                .table-cell {
                    &.load {
                        max-width: 125px;
                    }
    
                    &.region {
                        max-width: 149px;
                        width: 149px;
                    }
    
                    &.distance {
                        width: 84px;
                    }
    
                    &.weight {
                        max-width: 76px;
                    }
    
                    &.temperature {
                        width: 79px;
                    }
    
                    &.book-now,
                    &.rate {
                        width: 94px;
                    }
    
                    &.carrier {
                        width: 120px;
                    }
    
                    &.rank {
                        width: 92px;
                    }

                    &.bids {
                        max-width: 47px;
                    }
    
                    &.expiration {
                        width: 118px;
                    }
                }

                .linked-table-cell {
                    padding: 0;

                    &.side-padding > a {
                        padding-left: common.$size-spacing-2;
                        padding-right: common.$size-spacing-2;
                    }
                
                    &:first-of-type > a {
                        padding-left: common.$size-spacing-4;
                    }
                    
                    &:last-of-type > a {
                        padding-right: common.$size-spacing-4;
                    }
                
                    &.regular > a {
                        padding-top: common.$size-spacing-2;
                        padding-bottom: common.$size-spacing-2;
                    }

                    &> a {
                        width: 100%;
                        height: 100%;
                        display: block;
                        color: inherit;
                    }
                }
            }
        }
    }

    .pagination-container {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .empty-table,
    .loading-table {
        border: none;
        text-align: center;
        margin-top: 144px;

        .empty-table-icon-container {
            width: 80px;
            height: 80px;
            margin-bottom: 16px;

            .empty-table-icon {
                width: 32px;
                height: 32px;
            }
        }

        .loading-icon {
            font-size: common.$size-font-6;
            font-weight: 900;
            color: common.$color-primary-5;
        }
    }
}
