@use 'src/style/common';

.survey-popup-page {
    &.survey-popup-questions {
        .popup {
            border-top: 0;
        }
    }

    .survey-popup-component {
        height: 316px;
        text-align: center;
        padding: common.$size-spacing-5;

        .survey-popup-icon {
            color: common.$color-cyan-3;
            font-size: common.$size-font-6;
        }

        .survey-popup-heading {
            font-size: common.$size-font-4;
            padding-top: common.$size-spacing-4;
        }

        .survey-popup-content {
            padding-top: common.$size-spacing-0;
            padding-bottom: common.$size-spacing-6;
        }

        .survey-popup-actions {
            .first-button {
                margin-right: common.$size-spacing-1;
            }
        }
    }

    .cancel-survey-container {
        margin-left: common.$size-spacing-4;
        margin-right: common.$size-spacing-4;

        .cancel-survey-heading {
            margin-top: common.$size-spacing-4;
            font-size: common.$size-font-2;
            font-weight: 700;
        }

        .cancel-survey-content {
            margin-top: common.$size-spacing-3;
            font-size: common.$size-font-2;
        }

        .cancel-survey-actions {
            float: right;
            margin-top: common.$size-spacing-5;
            margin-bottom: common.$size-spacing-4;

            .tertiary {
                margin-right: common.$size-spacing-1;
            }
        }
    }

    .complete-survey-container {
        height: 256px;
        padding: common.$size-spacing-5;
    }

    // Original popup padding overridden so we can place progress bar correctly above popup
    .popup-container {
        .popup {
            padding: 0;
        }
    }
}
