// The root size (reference for REM values)
$root: 16px;

// The maximum width of non-full-width pages.
$page-width: 79.5rem;

// Common sizes
$border: 1px;
$border-wide: 4px;

// Negative spacing (used for overlapping components)
$negative-min: -1px;

// All font sizes
$font-0:  0.625rem; // 10 px
$font-1:  0.750rem; // 12 px
$font-2:  0.875rem; // 14 px
$font-3:  1.000rem; // 16 px
$font-4:  1.250rem; // 20 px
$font-5:  1.500rem; // 24 px
$font-6:  2.000rem; // 32 px
$font-7:  3.000rem; // 48 px
$font-8:  3.750rem; // 60 px
$font-9:  6.000rem; // 96 px
$font-10: 8.000rem; // 128 px

// All spacing sizes
$spacing-min: 0.125rem; //   2 px
$spacing-0:   0.25rem;  //   4 px
$spacing-1:   0.50rem;  //   8 px
$spacing-2:   0.75rem;  //  12 px
$spacing-3:   1.00rem;  //  16 px
$spacing-4:   1.50rem;  //  24 px
$spacing-5:   2.00rem;  //  32 px
$spacing-6:   3.00rem;  //  48 px
$spacing-7:   4.00rem;  //  64 px
$spacing-8:   6.00rem;  //  96 px
$spacing-9:   8.00rem;  // 128 px
$spacing-10: 12.00rem;  // 192 px
$spacing-11: 16.00rem;  // 256 px
$spacing-12: 24.00rem;  // 384 px
$spacing-13: 32.00rem;  // 512 px
$spacing-15: 40.00rem;  // 640 px

// Letter spacing options
$letter-spacing-0: 0.009rem; // 0.15px
$letter-spacing-1: 0.016rem; // 0.25px
$letter-spacing-2: 0.06rem; // 1px

// Page padding options
$page-padding: $spacing-5 0;
$page-left-right-padding: 0px 56px;
