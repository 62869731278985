@use 'src/style/common';

.custom-range-picker-container {
    .label {
        display: block;
        font-weight: 600;
        margin-bottom: common.$size-spacing-0;

        .icon-div {
            @include common.tooltip-container;
            display: inline;
            font-weight: initial;
        }
    }

    .ant-picker,
    .ant-picker-range {
        height: 40px;
        align-items: center;
        border: common.$size-border solid common.$color-neutral-8;
        box-sizing: border-box;
        border-radius: common.$size-border-wide;
        width: 100%;
        cursor: pointer;

        &.small {
            height: common.$size-spacing-5;
            font-size: common.$size-font-1;

            .ant-picker-input input {
                font-size: common.$size-font-1;
            }
        }
    }
    
    .ant-picker:hover {
        border-color: common.$color-neutral-7;
    }
    
    .ant-picker .ant-picker-range .ant-picker-focused {
        border-color: common.$color-primary-5;
        outline: 0;
        box-shadow: none;
    }
    
    .ant-picker-focused {
        border-color: common.$color-primary-5;
    }
    
    .ant-picker-range .ant-picker-active-bar,
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        background: common.$color-primary-5;
        cursor: pointer;
    }
    
    .ant-picker-input > input {
        cursor: pointer;
        text-align: center;
    }
}
