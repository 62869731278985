@use 'src/style/common';

.tmw-sync-rules-card {
    .tmw-sync-content-card {
        .tooltip-icon {
            @include common.tooltip-container;

            .icon {
                font-size: common.$size-font-3;
            }

            .tooltip-content {
                font-weight: initial;
                width: 390px;
            }
        }
    }

    .tmw-preview-sync-rules {
        padding-top: common.$size-spacing-4;
        padding-left: common.$size-spacing-4;
        padding-bottom: common.$size-spacing-1;
        border: common.$size-border solid common.$color-neutral-8;
        border-radius: common.$size-border-wide;

        ul {
            margin-top: common.$size-spacing-3;
            padding-left: common.$size-spacing-4;

            li {
                margin-top: 20px;
                margin-bottom: common.$size-spacing-3;
            }
        }
    }
    
    .tmw-enter-sync-rules-content {
        .tmw-enter-sync-rules {
            padding-top: common.$size-spacing-4;
            padding-left: common.$size-spacing-4;
            padding-bottom: common.$size-spacing-1;
            border: common.$size-border solid common.$color-neutral-8;
            border-radius: common.$size-border-wide;
            
            .fields {
                display: flex;
                margin-bottom: common.$size-spacing-3;
                align-items: center;
                
                .tmw-sync-rules-input-field {
                    width: 136px;     
                    margin-left: common.$size-spacing-2;
                    margin-right: common.$size-spacing-2;
                }

                .ant-checkbox-inner {
                    margin-top: -2px;
                }

                .checkbox-label {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
        
        .buttons-wrapper {
            display: flex;
            justify-content: right;
            margin-top: common.$size-spacing-4;
            gap: common.$size-spacing-2;
        }
    }    
}
