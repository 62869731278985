@use 'src/style/common';
@use 'sass:math';

.rfp-list-page {

    .rfp-list {
        $item-gap: common.$size-spacing-4;

        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        column-gap: $item-gap;
        row-gap: $item-gap;
        margin-top: common.$size-spacing-2;
        margin-bottom: common.$size-spacing-4;

        &.empty-list {
            padding-top: common.$size-spacing-10;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: initial;

            .rfp-icon {
                font-size: common.$size-font-8;
                color: common.$color-brand-red-1;
                margin-bottom: common.$size-spacing-2;
            }
        }
    }

    .rfp-tabs {
        margin-top: common.$size-spacing-4;

        .tab-content-container {
            padding-bottom: common.$size-spacing-7;

            .actions-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                column-gap: common.$size-spacing-2;

                .action-field {
                    height: 65px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    margin-bottom: common.$size-spacing-3;

                    &.search {
                        flex-grow: 1;
                        .search-filter-container {
                            .text-input {
                                width: 100%;
                            }
                        }
                    }

                    &.status {
                        width: 164px;
                    }
                }
            }

            .rfp-table {
                margin-bottom: common.$size-spacing-6;
                width: 100%;
                overflow: visible;
            }

            .rg-name {
                min-width: 325px;
            }

            .bill-to {
                width: 190px;
            }

            .effective-on,
            .expires-on {
                width: 125px;
            }

            .status {
                width: 285px;
            }

            .update {
                width: 220px;
            }

            .text-emphasis-box {
                width: fit-content;
            }

            .rg-name-content {
                font-weight: 600;

                a {
                    color: common.$color-neutral-0;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
