@use 'src/style/common';

.tendering-candidates {
    .content-card {
        .header {
            margin-left: 0;

            .info-group {
                .phase-box {
                    position: relative;
                    display: inline;
                    margin-left: common.$size-spacing-3;
                }
            }
        }

        .content-card-content {
            .spot-market-details {
                .component {
                    padding: 0;
                    margin-bottom: 0;
                }
            }
        }
    }

    .carrier-order-popover {
        .prompt {
            font-weight: 600;
            color: common.$color-neutral-0;
            text-decoration-line: underline;
            text-decoration-style: dashed;
            text-decoration-color: common.$color-primary-5;
            text-underline-offset: common.$size-spacing-0;

            &:hover {
                cursor: pointer;
            }
        }

        .icon {
            display: inline;
            color: common.$color-default-icon-state;
            margin-left: common.$size-spacing-0;

            &.active {
                color: common.$color-hover-state;
            }

            &:hover {
                color: common.$color-hover-state;
                cursor: pointer;
            }
        }

        .order-popover-content {
            padding: common.$size-spacing-2 common.$size-spacing-4;

            .title {
                font-weight: 600;
                margin-bottom: common.$size-spacing-2;
            }

            ul {
                margin-top: common.$size-spacing-2;
                padding-left: common.$size-spacing-4;

                li {
                    margin-bottom: common.$size-spacing-2;
                }
            }
        }
    }

    .unsuccessful-bids,
    .current-bid,
    .future-candidates {
        .candidates-heading {
            height: 100%;
            display: inline-block;
            margin-left: 0;
            margin-bottom: common.$size-spacing-1;
            font-weight: 600;
        }
    }

    .unsuccessful-bids,
    .future-candidates,
    .candidates-list {
        .component {
            padding-bottom: 0;
        }

        .separator {
            margin-top: common.$size-spacing-2;
            margin-bottom: common.$size-spacing-2;
        }

        .spot-market-details {
            .separator {
                margin: 0;
            }

            &.current {
                .content-card {
                    border: 1px solid common.$color-neutral-8;
                }
            
                .header {
                    background: none;
                }
            }
        }
    }

    .future-candidates {
        .component {
            &.dedicated {
                margin-bottom: common.$size-negative-min;
            }
        }
    }

    .candidates-list {
        .candidates-heading {
            display: block;
            margin-bottom: common.$size-spacing-1;
            font-weight: 600;
        }

        .waterfall-cards {
            margin-bottom: common.$size-negative-min;
        }

        .spot-market-details {
            position: relative;

            .component {
                margin-bottom: 0;
                padding-bottom: common.$size-spacing-2;

                .header {
                    .heading {
                        margin: 0;
                    }
                }
            }
        }

        .winner-declaration-cards {
            margin-top: common.$size-spacing-2;
            padding-bottom: common.$size-spacing-2;

            .component {
                margin-bottom: 0;
            }
        }
    }

    .unsuccessful-bids {
        .expansion-prompt {
            margin-left: common.$size-spacing-1;
            font-size: common.$size-font-1;
            cursor: pointer;
            font-weight: 600;

            .amount,
            .expand-icon {
                margin-left: common.$size-spacing-0;
                color: common.$color-neutral-6;
            }
        }
    }
}
