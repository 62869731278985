@use 'src/style/common';

.cargo-type-component {
    .lane-cargo-type {
        .lane-cargo-type-icon {
            margin-right: common.$size-spacing-0;
            color: common.$color-neutral-6;
        }
    }
}
