@use 'src/style/common';

.heading-component {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    cursor: initial;
    width: common.$size-page-width;

    &.component {
        padding: common.$size-spacing-4;
    }
    
    .heading-titles {
        display: flex;
        flex-direction: row;

        .horizontal-card-component-body {
            margin-top: -4px;
        }

        .title {
            margin-bottom: common.$size-spacing-3;
            align-items: flex-start;
            display: flex;
        
            .load-number {
                display: inline;
            }

            .icon-div {
                @include common.icon-hover;

                position: relative;
                display: inline;
                margin-left: common.$size-spacing-1;
                margin-right: common.$size-spacing-4;
                margin-top: common.$size-spacing-3;
                font-size: common.$size-font-5;
                font-weight: 900;
                color: common.$color-neutral-6;
            }
        }

        .lane-origin-and-destination {
            margin-top: 0;
        }

        .header-load-button {
            flex-direction: column;
            align-items: flex-end;
            margin-left: auto;

            .arrow-icon {
                margin-left: common.$size-spacing-0;
            }
        }
    }

    .fields {
        display: flex;
        flex-direction: row; 
        margin-top: common.$size-font-4;
        gap: common.$size-font-4;

        .field {
            display: flex;
            flex-direction: column;

            &.weight {
                width: 100px;
                margin-left: auto;
            }

            .horizontal-card-component-body {
                margin-top: common.$size-spacing-0;
            }

            .routing-guide-link {
                color: initial;
            }

            .content {
                font-weight: 700;
                line-height: 24px;  
            }
            
            .routing-guide-name {
                max-width: 190px;
                @include common.ellipsis-word-wrap;
            }

            .bill-to-name {
                max-width: 90px;
                @include common.ellipsis-word-wrap;
            }
        }
    }
}
