@use 'src/style/common';

.carrier-profile-page {

    .heading-card {
        height: 80px;
        padding-top: 0;
        padding-left: common.$size-spacing-5;
        margin-bottom: 40px;
        display: flex;

        .carrier-name-icon {
            position: relative;
            background-color: common.$color-neutral-8;
            width: common.$size-spacing-6;
            height: common.$size-spacing-6;
            margin-top: common.$size-spacing-3;
            border-radius: common.$size-spacing-4;

            &.invalid-docs {
                border: common.$size-border solid common.$color-red-4;
            }

            text-align: center;
            line-height: common.$size-spacing-6;
            font-weight: 600;
            font-size: common.$size-font-3;

            .initials {
                max-width: common.$size-spacing-6;
                @include common.ellipsis-word-wrap;
            }

            .account-warning-icon {
                line-height: initial;
                color: common.$color-red-4;
                position: absolute;
                bottom: -4px;
                right: 0;
                font-size: common.$size-font-3;

                svg {
                    cursor: pointer;
                }

                .tooltip-content {
                    font-weight: 400;
                }
            }
        }

        .carrier-name {
            margin-left: common.$size-spacing-3;
            margin-top: common.$size-spacing-4;

            .label {
                font-style: normal;
                font-weight: normal;
                font-size: common.$size-font-1;
                line-height: common.$size-spacing-3;
                color: common.$color-neutral-0;
            }

            .name {
                font-style: normal;
                font-weight: 600;
                font-size: common.$size-font-3;
                line-height: common.$size-spacing-4;
                color: common.$color-neutral-0;

                .profile-icon {
                    color: common.$color-red-6;
                    margin-right: common.$size-spacing-min;
                }
            }
        }

        .back-option {
            color: inherit;
            text-decoration: none;
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
            padding-top: common.$size-spacing-1;

            .back-icon {
                margin-right: common.$size-spacing-2;
            }
        }
    }
}

.profile-not-found {
    text-align: center;
    margin-top: 25%;
    margin-bottom: 25%;

    .heading {
        display: block;
    }
}
