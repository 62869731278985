@use 'src/style/common';

.survey-floating-button-container {
    position: fixed;
    z-index: 199;
    right: 56px;
    bottom: 73px;

    .floating-button {
        width: common.$size-font-7;
        height: common.$size-font-7;
        background-color: common.$color-neutral-0;
        border-color: common.$color-neutral-0;
        border-radius: 50%;
        box-shadow: 0px 6px 6px -4px rgba(33, 33, 33, 0.32);
        padding: 0;

        .icon {
            width: common.$size-font-4;
            height: common.$size-font-4;
            position: absolute;
            margin-left: 0 !important;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        
        &:hover {
            background-color: common.$color-neutral-0;
            border-color: common.$color-neutral-0;
        }   
    }

    .survey-reminder {
        width: 298px;
        padding: common.$size-spacing-4;
    }
}
