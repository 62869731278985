@use 'src/style/common';

.edit-lane-booknow-component {
    .booknow-button {
        color: common.$color-default-icon-state;
        font-size: 13px;
        margin-left: common.$size-spacing-1;
        cursor: pointer;

        &:hover {
            color: common.$color-hover-state;
        }
    }

    .edit-booknow-popup {
        .description {
            margin-top: common.$size-spacing-3;
        }

        .subheading {
            font-weight: 600;

            .total-price-label {
                color: common.$color-neutral-6;
                font-weight: 400;
                display: inline;
            }
        }

        .per-mile-price-label {
            color: common.$color-neutral-4;
            line-height: common.$size-spacing-3;
            margin-top: 4px;
        }

        .booknow-price {
            margin-top: common.$size-spacing-0;
        }
    }
}
