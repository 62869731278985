@use 'src/style/colors';
@use 'src/style/sizes';

@forward 'src/style/colors' as color-*;
@forward 'src/style/sizes' as size-*;

// Global Dark Mode switch.
// This should act as a global switch to disable dark-mode detection.
// It is NOT an override, and should only be used to direct components not to consider user color scheme preference.
$dark-mode-enabled: false;

@mixin component-background {
    color: colors.$dark-text-0;
    background-color: colors.$component-background-light;

    @if $dark-mode-enabled {
        @media (prefers-color-scheme: dark) {
            color: colors.$light-text-0;
            background-color: colors.$component-background-dark;
        }
    }
}

@mixin component-border {
    border-width: 0;
    border-style: solid;
    border-color: colors.$border-light;

    @if $dark-mode-enabled {
        @media (prefers-color-scheme: dark) {
            border-color: colors.$border-dark;
        }
    }
}

@mixin component-hover {
    cursor: pointer;
}

@mixin component-hover-shadow {
    @include component-hover;
    transition: box-shadow ease-in-out .2s;

    &:hover {
        @include component-shadow-0;
    }
}

@mixin component-shadow-0 {
    box-shadow:
            0 0.7px 2.2px rgba(0, 0, 0, 0.02),
            0 1.7px 5.3px rgba(0, 0, 0, 0.028),
            0 3.3px 10px rgba(0, 0, 0, 0.035),
            0 5.8px 17.9px rgba(0, 0, 0, 0.042),
            0 10.9px 33.4px rgba(0, 0, 0, 0.05),
            0 26px 80px rgba(0, 0, 0, 0.07);
}

@mixin rounded-corners-min {
    border-radius: sizes.$spacing-min;
}

@mixin rounded-corners-0 {
    border-radius: sizes.$spacing-0;
}

@mixin rounded-corners-1 {
    border-radius: sizes.$spacing-1;
}

@mixin page {
    padding: sizes.$page-padding;
    width: 100%;
}

@mixin page-background {
    color: colors.$dark-text-0;
    background-color: colors.$page-background-light;

    @if $dark-mode-enabled {
        @media (prefers-color-scheme: dark) {
            color: colors.$light-text-0;
            background-color: colors.$page-background-dark;
        }
    }
}

@mixin loading-shimmer {
    $bg-width: 1000px;

    @keyframes shimmer {
        0% {
            background-position: -$bg-width 0;
        }

        100% {
            background-position: $bg-width 0;
        }
    }

    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;

    background-color: colors.$neutral-9;
    background-image: linear-gradient(to right, colors.$neutral-9 8%, colors.$neutral-8 18%, colors.$neutral-9 33%);
    background-size: $bg-width 1px;

    @if $dark-mode-enabled {
        @media (prefers-color-scheme: dark) {
            background-color: colors.$neutral-0;
            background-image: linear-gradient(to right, colors.$neutral-0 8%, colors.$neutral-1 18%, colors.$neutral-0 33%);
        }
    }
}

@mixin dark-shadow-1 {
    box-shadow: 0px 8px 8px -6px rgba(colors.$neutral-0, 0.16);
}

@mixin dark-shadow-2 {
    box-shadow: 0px 12px 12px -8px rgba(colors.$neutral-0, 0.16);
}

@mixin emphasis-dark-grey {
    color: colors.$neutral-0;
    background-color: rgba(colors.$neutral-0, 0.08);
    border: 1px solid colors.$neutral-0;
}

@mixin emphasis-orange {
    color: colors.$orange-5;
    background-color: colors.$orange-9;
    border: 1px solid colors.$orange-5;
}

@mixin emphasis-cyan {
    color: colors.$cyan-3;
    background-color: colors.$cyan-9;
    border: 1px solid colors.$cyan-3;
}

@mixin emphasis-grey {
    color: colors.$neutral-4;
    background-color: colors.$neutral-9;
    border: 1px solid colors.$neutral-6;
}

@mixin emphasis-blue {
    color: colors.$primary-5;
    background-color: colors.$primary-9;
    border: 1px solid colors.$primary-5;
}

@mixin emphasis-red {
    color: colors.$red-4;
    background-color: colors.$red-9;
    border: 1px solid colors.$red-4;
}

@mixin pending-background {
    background: rgba(colors.$orange-4, 0.02);
    border: 1px solid colors.$orange-5;
}

@mixin ready-background {
    background: rgba(colors.$cyan-3, 0.02);
    border: 1px solid colors.$cyan-3;
}

@mixin falloff-background {
    background: rgba(colors.$neutral-6, 0.02);
    border: 1px solid colors.$neutral-6;
}

// When using this mixin, make sure to have a fixed max-width on the element
@mixin ellipsis-word-wrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin icon-hover {
    &:hover {
        color: colors.$hover-state;
        @include component-hover;
    }
}

@mixin tooltip-container {
    @include icon-hover;
    position: relative;
    color: colors.$neutral-6;
    margin-left: sizes.$spacing-0;
    font-size: 11px;
}
