@use 'src/style/common';

.ant-select-multiple {
    .ant-select-selector {
        .ant-select-selection-overflow {
            display: flex;
            flex-direction: row;

            .ant-select-selection-overflow-item {
                &:not(.ant-select-selection-overflow-item-suffix) {
                    max-width: 97%;
                }

                .multiple-selected,
                .one-selected {
                    @include common.ellipsis-word-wrap;
                    margin-right: common.$size-spacing-1;
                    margin-left: common.$size-spacing-2;   
                }

                &.ant-select-selection-overflow-item-rest {
                    .ant-select-selection-item {
                        background-color: common.$color-primary-7;
                        border-color: common.$color-primary-7;
                        border-radius: 100%;
                        height: common.$size-spacing-3;
                        width: fit-content;
                        display: flex;
                        justify-content: center;
                        padding: 0 4.5px;

                        .ant-select-selection-item-content {
                            color: common.$color-component-background-light;
                            font-size: common.$size-font-0;
                            line-height: 15px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .ant-select-dropdown {
        .ant-select-item-option-selected {
            background-color: common.$color-component-background-light;
            
            .ant-select-item-option-state {
                display: none;
            }
        }
    }
}
