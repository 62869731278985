@use 'src/style/common';

.routing-guide-lane-card {
    display: flex;
    flex-direction: row;
    width: 100%;

    &:hover {
        cursor: pointer;
    }

    .routing-guide-lane-card-column-container {
        &.active {
            color: common.$color-cyan-3;
        }

        &.pending {
            color: common.$color-orange-5;
        }

        &.rejected {
            color: common.$color-red-3;
        }

        &.expired,
        &.deleted {
            color: common.$color-neutral-4;
        }

        &.price,
        &.volume {
            min-width: 130px;
        }

        &.status {
            .status-tooltip-container {
                @include common.tooltip-container;
                display: inline;
                position: relative;

                .tooltip-icon {
                    font-size: 13px;
                }
            }
        }
    }

    .routing-guide-lane-card-actions-container {
        align-self: flex-end;
        margin-left: auto;
        margin-right: common.$size-spacing-1;
    }
}
