@use 'src/style/common';

.restart-auction-popup {
    height: auto;
    white-space: normal;
    font-weight: 400;

    .subheading {
        font-weight: 600;
        margin-left: 0;
        color: common.$color-neutral-0;
    }

    .custom-checkbox {
        margin-top: common.$size-spacing-3;
        margin-left: 0;
    }

    .deadline-picker {
        padding-top: common.$size-spacing-4;

        .label {
            font-weight: 600;
            margin-bottom: common.$size-spacing-min;

            .deadline-picker-description {
                color: common.$color-neutral-6;
                font-weight: 400;
                display: inline-block;
            }
        }
    }
}
