@use 'src/style/common';

.cancel-load-popup-container {
    .cancel-offer-popup {
        text-align: left;

        .subheading {
            font-weight: bold;
            margin-bottom: common.$size-spacing-0;
        }

        .select {
            margin-bottom: common.$size-spacing-5;
        }
    }
}
