@use 'src/style/common';

.sm-participants-content-row {
    .name {
        width: 372px;

        &.last-element {
            border-bottom-left-radius: common.$size-spacing-0;
        }

        .name-content {
            display: flex;
            align-items: center;
            gap: 3px;

            .carrier-warning {
                position: relative;
                font-weight: normal;
                font-size: common.$size-font-0;
                margin-right: common.$size-spacing-0;
                display: flex;
                align-items: flex-end;

                .icon {
                    margin-bottom: common.$size-spacing-min;
                    color: common.$color-red-4;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            a {
                color: inherit;

                &:hover {
                    text-decoration: underline;
                }
            }

            .name-link {
                .name-ellipsis {
                    @include common.ellipsis-word-wrap;
                    max-width: 345px;
                }
            }
        }
    }

    .origin {
        width: 300px
    }

    .email {
        width: 300px;

        .email-ellipsis {
            @include common.ellipsis-word-wrap;
            max-width: 275px;
        }
    }

    .phone {
        &.last-element {
            border-bottom-right-radius: common.$size-spacing-0;
        }

        width: 202px;
    }
}
