@use 'src/style/common';

.custom-select-container {
    .label {
        display: block;
        font-weight: 600;
        margin-bottom: common.$size-spacing-0;
        line-height: common.$size-spacing-3;
    }

    .tooltip-icon {
        position: relative;
        display: inline;
        color: common.$color-default-icon-state;
        margin-left: common.$size-spacing-0;
        font-weight: 400;

        &.active {
            color: common.$color-hover-state;
        }

        &:hover {
            color: common.$color-hover-state;
            cursor: pointer;
        }
    }

    .prefix-icon {
        position: relative;
        z-index: 1;
        align-items: center;
        justify-content: center;
        left: common.$size-spacing-2;
        top: 30px;
        color: common.$color-neutral-6;
        width: fit-content;
    }

    .ant-select {
        display: flex;
        align-items: center;
        border: common.$size-border solid common.$color-neutral-8;
        box-sizing: border-box;
        border-radius: common.$size-border-wide;
        width: 100%;
        min-height: 40px;
        cursor: pointer;
    
        &.small {
            min-height: common.$size-spacing-5;
            font-size: common.$size-font-1;

            .ant-select-item-option-content {
                font-size: common.$size-font-1;
            }
        }

        &.padding {
            .ant-select-selector {
                padding-left: calc(3rem - 8px);
            }
        }
    
        &:hover {
            box-shadow: none; 
            border: common.$size-border solid common.$color-neutral-7;
            box-sizing: border-box;
            border-radius: common.$size-border-wide;
        }
    }
    
    .ant-select-focused {
        border: common.$size-border solid common.$color-primary-5;
    }
    
    .ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-selector {
        background-color: unset;
        border: none;
        border-radius: 0;
        transition: none;
        height: inherit;
        align-items: center;
        width: 100%;
    }
    
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
    .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .ant-select-item-option-active,
    .ant-select-item-option-selected {
        background-color: common.$color-neutral-9;
        font-weight: 600;
    }
    
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: none;
    }
    
    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        cursor: pointer;
    }
    
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 100%;
    }
    
    .ant-select-single .ant-select-selector .ant-select-selection-item::after {
        content: none;
    }
    
    .ant-select-item-option-content {
        overflow: initial;
        white-space: initial;
        text-overflow: initial;
    }
    
    .ant-select-selection-item .select-option {
        @include common.ellipsis-word-wrap;
        width: 100%;
    } 

    .ant-select-disabled {
        background-color: common.$color-neutral-8;
        border-color: common.$color-neutral-7;
        color: common.$color-neutral-4;
    
        &:hover {
            border-color: common.$color-neutral-7;
        }
    }
}
