@use 'src/style/common';

.site-header {
    @include common.component-background;
    @include common.component-border;
    $height: 3.5rem;

    position: fixed;
    width: calc(100% - #{$height});

    z-index: 100;
    height: $height;
    border-bottom-width: common.$size-border;
    border-bottom-style: solid;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    .welcome-message {
        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    .search-filter-container {
        margin-right: auto;
    }

    padding: 0 $height;
}
