@use 'src/style/common';

.carrier-actions {
    margin-left: auto;
    margin-right: 0;
    display: flex;
    align-items: flex-end;

    .buttons-wrapper {
        display: flex;
        gap: common.$size-spacing-1;

        .action-button-container {
            position: relative;

            &.reject .tooltip .tooltip-content {
                margin-right: common.$size-spacing-2;
            }
        }

        .offer-popup {
            text-align: left;

            .popup-header {
                display: flex;
                margin-bottom: common.$size-spacing-5;

                .popup-header-section {
                    display: flex;
                    flex-direction: column;

                    .popup-header-section-title {
                        font-size: 12px;
                        font-weight: 600;

                        span {
                            font-weight: 400;
                        }
                    }

                    .popup-header-section-description {
                        font-size: 10px;
                        display: flex;
                        align-items: flex-end;
                        gap: 4px;
                        color: common.$color-neutral-4;
                    }

                    .popup-header-icon {
                        color: common.$color-neutral-6;
                        width: 12px;
                        height: 15px;

                        &.arrow {
                            margin: 0 4px;
                        }
                    }

                    &.region {
                        max-width: 130px;

                        div {
                            @include common.ellipsis-word-wrap;
                        }
                    }

                    &.price {
                        margin-left: auto;
                        align-items: flex-end;
                    }
                }

                .separator.vertical {
                    margin: 0 16px;
                    width: 1px;
                    height: 34px;
                }
            }

            &.reject-popup {
                .description .highlighted {
                    font-weight: 600;
                }

                .checkbox-label {
                    word-break: break-word;
                }

                .end-date-picker {
                    margin-top: common.$size-spacing-2;
                    
                    .label {
                        font-weight: 600;
                        margin-bottom: common.$size-spacing-0;
                    }
                }
            }

            &.accept-popup {
                .heading {
                    margin-top: common.$size-spacing-5;
                }

                .separator {
                    margin-left: - common.$size-spacing-5;
                    margin-right: - common.$size-spacing-5;
                    width: unset;
                }

                .accept-popup-actions {
                    display: flex;
                    justify-content: space-between;

                    .custom-checkbox {
                        margin-top: common.$size-spacing-5;
                        line-height: 40px;
                    }

                    .buttons-wrapper button {
                        width: 125px;
                    }
                }
            }
        }

        .popup-bidding-form {
            > .separator.horizontal {
                margin-left: - common.$size-spacing-5;
                margin-right: - common.$size-spacing-5;
                margin-bottom: common.$size-spacing-5;
                width: unset;
            }

            .popup-header .separator.vertical {
                margin: 0 8px;
            }

            .current-bid {
                padding: 0;
                border: none;
                text-align: left;
                margin-bottom: 0;
            }
        }
    }
}
