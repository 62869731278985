@use 'src/style/common';

.carrier-activity-log {
    height: auto;

    .heading {
        display: flex;

        .activity-log-information {
            .title {
                .icon {
                    margin-right: 6px;
                }
            }
    
            .description {
                color: common.$color-neutral-4;
            }
        }

        .timestamp {
            width: 300px;
            margin-left: auto;
            margin-top: - common.$size-font-4;
        }
    }

    .filter-actions {
        display: flex;
        flex-direction: column;
        .actions-container {
            display: flex;
            gap: common.$size-spacing-4;
            margin-top: common.$size-spacing-4;
            margin-bottom: common.$size-spacing-4;
    
            .action-field {
                &.search {
                    .search-filter-container {
                        .text-input {
                            width: 407px;
                        }
                    }
                }
    
                &.activity-type {
                    width: 192px;
                }
    
                &.member {
                    width: 192px;
                    height: 40px;

                    .ant-select-item-option {

                        .checkbox-label {
                            word-wrap: break-word;
                            width: 150px;
                            display: block;
                        }
                    }
                }
    
                &.routing-guide {
                    width: 192px;
                }
    
                &.lane {
                    width: 192px;
                }
            }
        }

        .clear-all-container {
            align-self: flex-end;
            margin-bottom: common.$size-spacing-2;
        }
    }

    .carrier-activity-infinite-scroll {
        height: 45vh;
        padding-bottom: 59px;
    }
}
