@use 'src/style/common';

.input-field {
    display: flex;
    flex-direction: column;

    .input-label {
        display: block;
        text-align: left;
        font-weight: 600;
        line-height: common.$size-spacing-3;
        margin-bottom: common.$size-spacing-0;

        .required-indicator {
            color: common.$color-red-4;
        }

        .icon-div {
            @include common.tooltip-container;
            display: inline;
            font-weight: initial;

            .tooltip-content {
                width: 272px;
            }

            .icon {
                cursor: pointer;
            }
        }
    }

    .text-input,
    .textarea-input {
        display: flex;
        align-items: center;
        background: common.$color-component-background-light;
        border: common.$size-border solid common.$color-neutral-8;
        border-radius: common.$size-border-wide;
        padding: common.$size-spacing-1 common.$size-spacing-2;
        font-size: common.$size-font-2;
        line-height: 20px;
        color: common.$color-neutral-0;
        resize: none;

        textarea,
        input {
            border: none;
            background-image: none;
            background-color: transparent;
            box-shadow: none;
            text-shadow: none;
            width: 100%;

            &:focus {
                outline: none;
            }
        }

        textarea {
            resize: none;
        }

        .icon {
            color: common.$color-neutral-6;
            margin-right: common.$size-spacing-2;
            cursor: pointer;
        }

        &:hover {
            border-color: common.$color-neutral-7;
        }

        &:focus-within {
            border-color: common.$color-primary-5;

            .icon {
                color: common.$color-neutral-0;
            }
        }

        &.invalid {
            border-color: common.$color-red-4;
        }
    }

    .textarea-information {
        .textarea-character-count {
            color: common.$color-neutral-0;
            font-size: common.$size-font-1;
            margin-top: common.$size-spacing-0;
        }
    }

    &.disabled .text-input {
        background-color: common.$color-neutral-8;
        border-color: common.$color-neutral-7;
        color: common.$color-neutral-4;

        &:hover {
            border-color: common.$color-neutral-7;
        }
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: common.$color-neutral-6;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: common.$color-neutral-6;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: common.$color-neutral-6;
    }
}
