@use 'src/style/common';

.rfp-lane-card-container {

    .rfp-lane-card {
        position: relative;
        margin-top: common.$size-spacing-4;
        padding: common.$size-spacing-4 common.$size-spacing-3;
        display: flex;

        .remove-rfp-lane-popup {
            width: 250px;
        }

        .rfp-lane-cargo-type {
            @include common.component-border;
            @include common.rounded-corners-0;
            border-width: common.$size-border;

            position: absolute;
            left: common.$size-spacing-3;
            top: - common.$size-spacing-2;
            background-color: common.$color-neutral-9;
            padding: common.$size-spacing-min common.$size-spacing-1;

            .rfp-lane-cargo-type-icon {
                margin-right: common.$size-spacing-1;
            }
        }

        .rfp-lane-card-visualization-container,
        .routing-guide-lane-card-visualization-container {
            padding-right: 15px;
            
            .lane-illustration {
                color: common.$color-neutral-0;
            }

            .lost,
            .rejected {
                .lane-illustration {
                    color: common.$color-neutral-6;
                }
            }
        }

        .rfp-lane-card-column-container,
        .routing-guide-lane-card-column-container {
            padding: 0 15px;

            &.price-per-mile,
            &.price-per-load {
                min-width: 129px;

                &.winning {
                    color: common.$color-cyan-3;
                }

                &.uncertain {
                    color: common.$color-yellow-4;
                }

                &.losing {
                    color: common.$color-red-4;
                }
            }

            &.price-per-mile {
                min-width: 95px;
            }

            &.participation {
                min-width: 124px;
            }

            &.bid-vol {
                min-width: 126px;
            }

            &.lane-vol {
                min-width: 126px;
            }

            &.awarded-vol {
                min-width: 126px;
            }

            &.bid-status {
                white-space: nowrap;
            }

            &.rfp-lane-card-dedicated-carriers-count,
            &.rfp-lane-card-spot-market-carriers-count,
            &.rfp-lane-card-book-now-price {
                padding: 0 common.$size-spacing-5;
            }

            &.actions {
                margin-left: auto;
                margin-top: auto;
                padding: 0;

                > * {
                    margin-right: common.$size-spacing-1;
                }
            }

            .light {
                color: common.$color-neutral-4;
            }
        }
    }
}
