@use 'src/style/common';

.dispatch-details {
    .stop-details:last-child {
        margin-bottom: 0;
    }

    .dispatch-carrier-action {
        margin-left: common.$size-spacing-4;
    }

    #dispatch-carrier-popup .banner {
        align-items: flex-start;

        .banner-content {
            line-height: 16px;
        }
    }

}
