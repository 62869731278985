@use 'src/style/common';

.content-card {
    .header {
        height: 64px;
        margin-top: -(common.$size-spacing-2);
        display: flex;
        align-items: center;
        padding: 0 common.$size-spacing-1;

        &.expandable {
            cursor: pointer;
        }

        .heading {
            font-weight: bold;
            letter-spacing: common.$size-letter-spacing-0;
        }

        .content-card-subheading {
            position: relative;
            margin-top: common.$size-spacing-min;
            margin-left: common.$size-spacing-0;
            font-size: common.$size-font-2;
            line-height: common.$size-spacing-4;
            color: common.$color-neutral-4;
            font-weight: normal;
            letter-spacing: common.$size-letter-spacing-1;

            .icon {
                line-height: normal;
                margin-left: common.$size-spacing-0;
                color: common.$color-default-icon-state;
                cursor: pointer;

                &:hover {
                    color: common.$color-hover-state;
                }
            }
        }

        .info-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: auto;

            .expansion-view {
                display: flex;
                flex-direction: row;
                align-items: center;

                .info-label {
                    font-weight: 600;
                    margin-left: common.$size-spacing-4;
                    margin-right: common.$size-spacing-1;
                }

                .expand-icon {
                    height: 32px;
                    cursor: pointer;
                }
            }

            .actions {
                margin-left: common.$size-spacing-4;
            }
        }
    }

    .separator {
        width: calc(100% + 32px);
        margin-left: -(common.$size-spacing-3);
        margin-right: -(common.$size-spacing-3);
    }

    .content-card-content {
        padding: common.$size-spacing-4 common.$size-spacing-1 common.$size-spacing-2;
    }

    &.not-expanded {
        padding-bottom: 0;
    }
}
