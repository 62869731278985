@use 'src/style/common';

.bid-form {
    .inputs {
        width: 100%;
        margin-bottom: common.$size-spacing-0;

        .label {
            font-weight: 600;
            line-height: common.$size-spacing-3;
            margin-bottom: common.$size-spacing-0;
        }
    }

    .error-description {
        line-height: common.$size-spacing-3;
        margin-top: common.$size-spacing-0;
        color: common.$color-red-4;
    }

    .per-mile-description {
        line-height: common.$size-spacing-3;
        margin-top: common.$size-spacing-0;
        color: common.$color-neutral-4;
        font-weight: 600;
    }

    .full-width-button {
        width: 100%;
        margin-top: common.$size-spacing-2;
        margin-left: 0;
        text-align: center;
        word-wrap: break-word;
        white-space: normal;
        height: fit-content;
        padding: common.$size-spacing-1;
    }

    .book-now-popup {
        &.accept-popup {
            .separator {
                margin-left: - common.$size-spacing-5;
                margin-right: - common.$size-spacing-5;
                width: unset;
            }
    
            .accept-popup-actions {
                display: flex;
                justify-content: space-between;
    
                .custom-checkbox {
                    margin-top: common.$size-spacing-5;
                    line-height: 40px;
                }
    
                .buttons-wrapper button {
                    width: 125px;
                }
            }
        }
    }

    .italic-text {
        font-style: italic;
        color: common.$color-neutral-4;
        margin-top: common.$size-spacing-1;
    }
}
