@use 'src/style/common';

.page-content:has(.page.help-center-page) {
    padding: 0;
}

.page.help-center-page {
    position: absolute;
    left: 3.5rem;
    
    min-width: calc(100% - 3.5rem);
    min-height: 100%;
    
    background-color: common.$color-component-background-light;

    .help-center-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: common.$size-spacing-7;

        .help-center-description {
            width: 516px;
            text-align: center;
            margin-top: common.$size-spacing-4;
            margin-bottom: common.$size-spacing-7;
        }

        .pdf-files {
            display: flex;
            flex-direction: column;
            gap: common.$size-spacing-4;
            margin-bottom: common.$size-spacing-7;
        }

        .support-email {
            font-weight: 600;
            color: common.$color-cyan-3;
            margin-top: common.$size-spacing-0;
        }
    }
}
