@use 'src/style/common';

.tab-container {
    &.absolute {
        position: absolute;
        min-width: calc(100% - 3.5rem);
        left: 3.5rem;
        height: 80%;
    }

    .tab-navigation-container {
        display: flex;

        .tab-placeholder {
            flex-grow: 1;
            border-bottom: common.$size-border solid common.$color-border-light;

            &.left {
                max-width: calc((100% - 79.5rem) / 2);
            }
        }

        .tab-link {
            display: block;
            border-top-left-radius: common.$size-spacing-0;
            border-top-right-radius: common.$size-spacing-0;

            border-width: common.$size-border;
            border-style: solid;
            border-color: transparent;
            border-bottom-color: common.$color-border-light;

            font-size: common.$size-font-1;
            padding: common.$size-spacing-1 common.$size-spacing-2;

            text-decoration: none;
            color: common.$color-neutral-4;
            cursor: pointer;

            &.medium {
                background-color: common.$color-neutral-9;
            }

            &.light {
                &.active {
                    background-color: common.$color-component-background-light;
                }
            }

            svg {
                margin-right: common.$size-spacing-1;
            }

            &:hover {
                color: common.$color-neutral-0;
            }

            &.active {
                border-color: common.$color-border-light;
                border-bottom-color: transparent;

                color: common.$color-neutral-0;
                font-weight: 600;
            }

            .tab-title {
                .number {
                    color: common.$color-neutral-6;
                }
            }
        }
    }

    .tab-content-container {
        &.absolute {
            padding-top: common.$size-spacing-4;
            padding-left: 55px;
            padding-right: 55px;
            height: 100%;
            overflow: auto;

            .tab-content {
                width: 79.5rem;
                margin: auto;
            }
        }

        &.medium {
            background-color: common.$color-neutral-9;
        }

        &.light {
            background-color: common.$color-component-background-light;
        }
    }
}
