@use 'src/style/common';

.declare-winner-popup-container {
    .carrier-name {
        font-weight: 600;
    }
    
    .dropdown-item .action-name {
        display: inline-block;
        width: 116px;
    }

    #declare-winner-popup-heading {
        color: common.$color-neutral-0;
    }
}
