@use 'src/style/common';

.member-content-row {
    &.active {
        .member-status {
            color: common.$color-cyan-3;
        }
    }
    &.pending {
        .member-status {
            color: common.$color-orange-4;
        }
    }

    &.removed, &.terminated {
        color: common.$color-neutral-4;
    }

    .name {
        width: 257px;

        .name-link {
            color: inherit;

            .name-ellipsis {
                @include common.ellipsis-word-wrap;
                display: inline-block;
                max-width: 257px;
                margin-top: 3px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .email {
        width: 313px;

        .email-ellipsis {
            @include common.ellipsis-word-wrap;
            max-width: 313px;
        }
    }

    .phone {
        width: 269px;
    }

    .member-status {
        width: 145px;
    }

    .date-added {
        width: 194px;
    }
}
