@use 'src/style/common';

.rfp-lane-status {
    white-space: nowrap;

    .pending,
    .waiting-for-bid,
    .review {
        color: common.$color-orange-5;
    }

    .offered,
    .accepted {
        color: common.$color-cyan-3;
    }

    .in-progress {
        color: common.$color-primary-5;

        &.confirmation {
            color: common.$color-orange-5;
        }
    }

    .no-bid,
    .expired,
    .rejected,
    .lost {
        color: common.$color-neutral-4;
    }

    .review {
        &.awarded {
            color: common.$color-cyan-3;
        }

        &.not-awarded {
            color: common.$color-red-4;
        }
    }

    .rejected {
        .reject-reason-tooltip-container {
            @include common.tooltip-container;
            font-weight: initial;

            .tooltip-icon {
                font-size: common.$size-font-1;
            }
        }
    }

    .submitted {

        sup {
            top: 0;
            margin-left: common.$size-spacing-min;
            color: common.$color-neutral-4;
        }
    }

    .horizontal-card-component-title.small {
        text-transform: uppercase;
    }
}
