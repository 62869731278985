@use 'src/style/common';

.header-cell {
    padding: common.$size-spacing-2;

    text-transform: uppercase;
    color: common.$color-neutral-4;
    text-align: left;
    border-bottom: common.$size-border solid common.$color-neutral-8;

    &:first-of-type {
        border-top-left-radius: common.$size-spacing-0;
        padding-left: common.$size-spacing-4;
    }

    &:last-of-type {
        border-top-right-radius: common.$size-spacing-0;
        padding-right: common.$size-spacing-4;
    }

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }

    &.center {
        text-align: center;
    }

    .header-cell-content {
        width: fit-content;
        display: inline;
        letter-spacing: common.$size-letter-spacing-2;

        &.active {
            background-color: common.$color-neutral-8;
            border-radius: common.$size-spacing-0;
            color: common.$color-neutral-0;
            padding: common.$size-spacing-0;
            margin: -1 * common.$size-spacing-0;
        }
    }

    .sort-dropdown-trigger {
        display: inline;
        position: relative;

        .sort-icon {
            margin-left: common.$size-spacing-0;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .action {
        .dropdown-item {
            font-weight: initial;
            text-transform: initial;
            text-align: left;

            .action-icon {
                margin-right: common.$size-spacing-0;
            }

            &.active {
                .action-name {
                    font-weight: 600;
                }
            }
        }

        &:hover {
            .action-name {
                font-weight: 600;
            }
        }
    }
}
