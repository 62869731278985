@use 'src/style/common';

.ant-drawer-content {
    .ant-drawer-header {
        .ant-drawer-title {
            margin-top: 30px;

            .activity-log-title {
                .activity-log-heading {
                    margin-bottom: common.$size-spacing-1;
            
                    .icon {
                        margin-right: common.$size-spacing-1;
                        padding: common.$size-spacing-0;
                    }
                }

                .activity-log-description {
                    color: common.$color-neutral-4;
                }
            }
        }
    }
    
    .ant-drawer-body {
        .activity-drawer-infinite-scroll {
            height: 80vh;

            .activity-row:first-child {
                margin-top: 0;
            }

            .activity-row {
                .activity {
                    max-width: 480px;
                }
            }
        }
    }
}
