@use 'src/style/common';

.edit-book-now-price-popup-container {
    position: relative;

    a {
        color: inherit;
    }

    #popup-edit-book-now {
        .edit-book-now-popup {
            .heading {
                color: common.$color-neutral-0;
                margin-bottom: common.$size-spacing-3;
            }

            .prompt {
                font-weight: 600;
            }

            .total-price-label {
                color: common.$color-neutral-6;
                font-weight: 400;
                display: inline;
            }

            .per-mile-price-label {
                color: common.$color-neutral-4;
                line-height: common.$size-spacing-3;
                margin-top: 4px;
            }

            .book-now-price {
                margin-top: common.$size-spacing-0;
            }

            .ant-checkbox-wrapper {
                margin-top: common.$size-spacing-1;
                display: flex;
            }
        }
    }
}
