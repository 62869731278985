.remove-member, 
.reactivate-member {
    display: inline-block;
    
    .action {
        padding: 0;
        height: 24px;
        width: 24px;
    }

    .remove-member-popup, 
    .reactivate-member-popup {
        text-align: left;
    }
}
