@use 'src/style/common';

.coordinator-overview-card {
    margin-top: common.$size-spacing-4;

    &:first-of-type {
        margin-top: 0;
    }

    .coordinator-overview {
        display: flex;
        margin-left: 0;
        margin-right: 0;

        .field {
            margin-right: common.$size-spacing-2;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title {
                margin-bottom: common.$size-spacing-1;
            }

            .content {
                @include common.ellipsis-word-wrap;
                width: 100%;
                margin-left: 0;
                font-weight: 600;
            }

            &.name,
            &.phone {
                width: 210px;
            }

            &.email {
                width: 230px;
            }

            &.submitted-by {
                width: 160px;
            }

            &.submitted-at {
                width: 190px;
            }

            &.actions {
                width: 32px;
                margin-left: auto;
                margin-right: 0;
                padding: 0;

                .top-button {
                    margin-bottom: common.$size-spacing-1;
                }
            }
        }
    }

    .coordinator-input-form {
        border: common.$size-border solid common.$color-neutral-8;
        box-sizing: border-box;
        border-radius: common.$size-border-wide;
        padding: common.$size-spacing-4;

        .form-title {
            font-weight: 600;
        }

        .input-fields {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: common.$size-spacing-2;
            padding-top: common.$size-spacing-3;
            padding-bottom: common.$size-spacing-2;
        }

        .input-field {
            &.actions {
                margin-top: common.$size-spacing-1;
                margin-bottom: common.$size-spacing-2;
                justify-content: space-between;

                .buttons-wrapper {
                    display: flex;
                    gap: common.$size-spacing-1;
                    justify-content: flex-end;
                    margin-left: auto;
                }
            }
        }
    }
}
