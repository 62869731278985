@use 'src/style/common';

.form-status-div {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: common.$size-spacing-1;
    align-items: center;
    text-align: center;

    .status-icon {
        margin-bottom: common.$size-spacing-2;
        line-height: common.$size-spacing-4;
    }

    h6 {
        font-weight: 600;
        margin-bottom: common.$size-spacing-0;
    }

    .continue-button {
        margin-top: common.$size-spacing-2;
    }

    .form-status-content {
        white-space: normal;
        word-break: break-word;
        line-height: common.$size-spacing-4;
    }

    &.loading {
        height: 174px;
    }

    &.success {
        .status-icon {
            color: common.$color-cyan-3;
            font-size: common.$size-font-6;
        }
    }

    &.info {
        .status-icon {
            color: common.$color-primary-5;
            font-size: common.$size-font-6;
        }
    }

    &.error {
        .status-icon {
            color: common.$color-red-4;
            font-size: common.$size-font-6;
        }
    }
}
