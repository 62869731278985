@use 'src/style/common';

.activity-type-icon {
    height: common.$size-spacing-6;
    width: common.$size-spacing-6;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.submit {
        background-color: common.$color-primary-8;

        .icon {
            color: common.$color-primary-4;
        }
    }

    &.accept {
        background-color: common.$color-cyan-8;

        .icon {
            color: common.$color-cyan-4;
        }
    }

    &.reject {
        background-color: common.$color-red-8;

        .icon {
            color: common.$color-red-4;
        }
    }

    &.update {
        background-color: common.$color-yellow-8;

        .icon {
            color: common.$color-yellow-4;
        }
    }

    .icon {
        width: 15px;
        height: 19px;
    }
}    
