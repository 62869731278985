@use 'src/style/common';

.download-pdf-card {
    border: common.$size-border solid common.$color-neutral-7;
    border-radius: 4px;
    width: 410px;
    height: 96px;
    padding: common.$size-spacing-5 common.$size-spacing-4;
    display: flex;
    gap: common.$size-spacing-3;

    .pdf-file-icon {
        width: common.$size-spacing-4;
        height: common.$size-spacing-5;
        color: common.$color-neutral-6;
    }

    .download-pdf-info {
        display: flex;
        flex-direction: column;
        font-size: common.$size-font-0;
        font-weight: 600;
        line-height: common.$size-spacing-3;
        color: common.$color-neutral-4;
        
        .download-pdf-title {
            color: common.$color-neutral-0;
        }
    }

    .download-pdf-option {
        margin-left: auto;
        margin-top: common.$size-spacing-1;
        color: common.$color-neutral-0;

        .download-pdf-icon {
            width: common.$size-spacing-3;
            height: common.$size-spacing-3;
            margin-left: common.$size-spacing-1;
        }
    }

    &:hover {
        box-shadow: 0 common.$size-spacing-1 common.$size-spacing-1 -6px common.$color-neutral-7;
    }   
}

.downloading-manual-popup {
    height: auto;
    white-space: normal;
    font-weight: 400;
    text-align: center;

    .heading {
        margin-top: common.$size-spacing-3;   
    }

    .description {
        margin-top: common.$size-spacing-3;
        margin-bottom: common.$size-spacing-4;
    }
}
