@use 'src/style/common';

.dedicated-carriers-card {
    .tab-placeholder {
        min-width: common.$size-spacing-4;
    }

    .tab-content-container {
        padding: common.$size-spacing-4 common.$size-spacing-4 0;
    }

    .content-card-content {
        min-width: calc(100% + 48px);
        margin-left: -(common.$size-spacing-4);
        margin-right: -(common.$size-spacing-4);
        padding-top: 0;
    }

    .dedicated-carriers-table {
        width: 100%;

        &.empty {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: common.$size-spacing-6;
            padding-bottom: common.$size-spacing-6;
            row-gap: initial;

            .icon {
                font-size: 40px;
                color: common.$color-neutral-6;
            }
        }

        .custom-table {
            margin-bottom: common.$size-spacing-4;
            width: 1223px;

            .table-cell {
                line-height: 18px;
            }

            .header-row,
            .content-row {
                &.active {
                    .name {
                        width: 288px;
                        min-width: 288px;

                        .name-ellipsis {
                            max-width: 245px;
                        }
                    }

                    .carrier-status {
                        width: 165px;
                        max-width: 165px;
                    }

                    &.without-actions {
                        .carrier-status {
                            width: 408px;
                            max-width: 408px;
                        }
                    }

                    .carrier-status.right .text-emphasis-box {
                        margin-left: auto;
                    }
                }
            
                &.history {
                    .name {
                        width: 358px;
                        min-width: 358px;

                        .name-ellipsis {
                            max-width: 300px;
                        }
                    }

                    .carrier-status {
                        width: 285px;
                        min-width: 285px;
                    }
                }

                .table-cell,
                .header-cell {
                    &.rank {
                        width: 70px;
                        min-width: 70px;
                    }

                    &.name {
                        .name-content {
                            display: flex;
                            column-gap: 3px;

                            .carrier-warning {
                                position: relative;
                                font-weight: normal;
                                font-size: common.$size-font-0;
                                margin-right: common.$size-spacing-0;
                                display: flex;
                                align-items: flex-end;
                        
                                .icon {
                                    margin-bottom: 5px;
                                    color: common.$color-red-4;
                        
                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }
                        
                            a {
                                color: inherit;
                        
                                &:hover {
                                    text-decoration: underline;
                                }
                            }

                            .name-link {
                                display: flex;
                                flex-direction: row;
                                column-gap: 3px;

                                .name-ellipsis {
                                    @include common.ellipsis-word-wrap;
                                    display: inline-block;
                                }
                            }
                        }
                    }

                    &.price {
                        width: 130px;
                        min-width: 130px;
                    }

                    &.weekly-capacity {
                        width: 140px;
                        min-width: 140px;

                        &.exceeded {
                            color: common.$color-red-4;
                        }
                    }

                    &.date-added {
                        width: 180px;
                        min-width: 180px;
                    }

                    &.carrier-status {
                        margin-left: common.$size-spacing-1;
                        line-height: initial;

                        .status-box {
                            width: fit-content;
                            font-size: common.$size-font-2;
                            padding-top: common.$size-spacing-min;
                            padding-bottom: common.$size-spacing-min;
                        }
                    }

                    &.actions {
                        width: 120px;
                        min-width: 120px;

                        .action {
                            display: inline;
                            height: common.$size-spacing-4;
                            width: common.$size-spacing-4;
                            padding: 0 1px 0 1px;

                            .icon {
                                font-size: common.$size-font-1;
                            }
                        }

                        .edit-dedicated-carrier,
                        .remove-dedicated-carrier {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}
