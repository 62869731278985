@use 'src/style/common';

.progress-bar-container {
    background-color: common.$color-neutral-8;
    height: common.$size-spacing-0;
    border-radius: 100px;

    .progress-bar {
        height: common.$size-spacing-0;
        border-radius: 100px;
    
        &.static {
            background-color: common.$color-cyan-3;
        }
        
        &.high {
            background-color: common.$color-red-5;
        }

        &.medium {
            background-color: common.$color-orange-5;
        }

        &.low {
            background-color: common.$color-primary-5;
        }
    }
}
