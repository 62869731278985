@use 'src/style/common';

.filter-count-icon { 
    display: inline-block;
    background: common.$color-neutral-8;
    line-height: 14px;
    border-radius: common.$size-spacing-0;
    padding: 0 common.$size-spacing-0;
    margin-left: -(common.$size-spacing-0);
    margin-right: common.$size-spacing-1;

    sup {
        line-height: 14px;
        top: 0;
    }
}
