@use 'src/style/common';

.rfp-offer-popups {
    display: flex;
    gap: common.$size-spacing-1;
    justify-content: center;
    align-items: center;
    
    .rfp-offer-popup {
        white-space: initial;

        .rfp-warning-message .banner {
            width: 100%;
            margin-bottom: common.$size-spacing-3;

            .text {
                margin-left: 4px;
            }
        }

        .offer-details {
            padding-left: common.$size-spacing-5;
        }

        .accept-popup-terms-and-conditions {
            margin-top: common.$size-spacing-2;
            height: 273px;
            overflow: scroll;

            .subheading-title {
                display: flex;
                justify-content: space-between;
                margin-bottom: common.$size-spacing-3;
            }

            > ul {
                margin-left: -25px;
            }
        }

        .separator {
            margin-left: -(common.$size-spacing-5);
            margin-right: -(common.$size-spacing-5);
            width: unset;
        }

        .accept-popup-actions {
            display: flex;
            justify-content: space-between;

            .custom-checkbox {
                margin-top: common.$size-spacing-5;
                line-height: 40px;
            }
        }
    }

    .rfp-reject-button,
    .rfp-accept-button {
        min-width: 75px;
    }
}
