@use 'src/style/common';

.rfp-round-overview-container {
    margin-bottom: common.$size-spacing-3;

    .rfp-round-overview-card {
        margin-bottom: - common.$size-spacing-0;
        padding-bottom: common.$size-spacing-4;
        padding-left: common.$size-spacing-4;
        
        .rfp-round-overview-information {
            display: flex;
            width: fit-content;
            justify-content: space-between;
            padding-top: 23px;

            sup {
                font-weight: 700;
            }

            .rfp-round-overview-information-field {
                display: flex;
                gap: 6px;
                flex-direction: column;
                justify-content: space-between;
                margin-right: common.$size-spacing-4;
            }   
        }
    }
}
