// Primary Colors ///////////////////////////////
$primary-0: #002159;
$primary-1: #01337D;
$primary-2: #03449E;
$primary-3: #0552B5;
$primary-4: #0967D2;
$primary-5: #2186EB;
$primary-6: #47A3F3;
$primary-7: #7CC4FA;
$primary-8: #BAE3FF;
$primary-9: #E6F6FF;

// Neutral Colors ///////////////////////////////
$neutral-0: #1F2933;
$neutral-1: #323F4B;
$neutral-2: #3E4C59;
$neutral-3: #52606D;
$neutral-4: #616E7C;
$neutral-5: #7B8794;
$neutral-6: #9AA5B1;
$neutral-7: #CBD2D9;
$neutral-8: #E4E7EB;
$neutral-9: #F5F7FA;

// Supporting Colors ////////////////////////////

// Cyan Variants
$cyan-0: #05606E;
$cyan-1: #07818F;
$cyan-2: #099AA4;
$cyan-3: #0FB5BA;
$cyan-4: #1CD4D4;
$cyan-5: #3AE7E1;
$cyan-6: #62F4EB;
$cyan-7: #92FDF2;
$cyan-8: #C1FEF6;
$cyan-9: #E1FCF8;

// Orange Variants
$orange-0: #841003;
$orange-1: #AD1D07;
$orange-2: #C52707;
$orange-3: #DE3A11;
$orange-4: #F35627;
$orange-5: #F9703E;
$orange-6: #FF9466;
$orange-7: #FFB088;
$orange-8: #FFD0B5;
$orange-9: #FFE8D9;

// Red Variants
$red-0: #610316;
$red-1: #8A041A;
$red-2: #AB091E;
$red-3: #CF1124;
$red-4: #E12D39;
$red-5: #EF4E4E;
$red-6: #F86A6A;
$red-7: #FF9B9B;
$red-8: #FFBDBD;
$red-9: #FFE3E3;

// Yellow Variants
$yellow-0: #8D2B0B;
$yellow-1: #B44D12;
$yellow-2: #CB6E17;
$yellow-3: #DE911D;
$yellow-4: #F0B429;
$yellow-5: #F7C948;
$yellow-6: #FADB5F;
$yellow-7: #FCE588;
$yellow-8: #FFF3C4;
$yellow-9: #FFFBEA;

// General Colors //////////////////////////////

// Light Theme
$page-background-light: $neutral-9;
$component-background-light: #FFFFFF;

$border-light: $neutral-8;

$dark-text-0: $neutral-0;
$dark-text-1: $neutral-4;
$dark-text-2: $neutral-6;

// Buttons and icons
$default-icon-state: $neutral-6;
$hover-state: $neutral-0;
$default-button-state: $neutral-7;

// Dark Theme
$page-background-dark: $neutral-0;
$component-background-dark: #18222C;

$border-dark: #0F1820;

$light-text-0: $neutral-9;
$light-text-1: $neutral-7;
$light-text-2: $neutral-6;

// Highlight Colors /////////////////////////////
$spot-market-carrier: $primary-5;
$dedicated-carrier: $red-6;
$failed-auction: $neutral-6;

// Brand Colors ////////////////////////////

// Black Variants
$brand-black-0: #081013;
$brand-black-1: #0F2025;
$brand-black-2: #1B3942;
$brand-black-3: #244C58;

// Red Variants
$brand-red-0: #752334;
$brand-red-1: #982D44;
$brand-red-2: #CA4C67;
$brand-red-3: #D87C90;

// Yellow Variants
$brand-yellow-0: #F19D05;
$brand-yellow-1: #FAA916;
$brand-yellow-2: #FBB83E;
$brand-yellow-3: #FCC35C;
