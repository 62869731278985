@use 'src/style/common';

.input-container {
    position: relative;
    border-radius: common.$size-border-wide;

    .label {
        display: block;
        font-weight: 600;
        margin-bottom: common.$size-spacing-0;

        .icon-div {
            @include common.tooltip-container;
            display: inline;
            font-weight: initial;
        }

        .input-label-description {
            color: common.$color-neutral-6;
        }
    }

    .ant-input-affix-wrapper {
        height: 42px;
        border: common.$size-border solid common.$color-neutral-8;
        border-radius: common.$size-border-wide;
    }

    .ant-input-affix-wrapper .ant-input,
    .ant-input {
        width: 100%;
        padding: common.$size-spacing-1 0;
        font-size: common.$size-font-2;
        line-height: common.$size-spacing-4;
        color: common.$color-neutral-0;
        border-radius: common.$size-border-wide;

        &:focus {
            outline: transparent;
            box-shadow: none;
            border-color: common.$color-primary-5;
        }

        &.invalid-input {
            border-color: common.$color-red-4;
        }
    }

    .ant-input {
        padding-left: common.$size-spacing-2;
    }

    .ant-input-affix-wrapper-focused {
        box-shadow: none;
    }

    .ant-input-suffix,
    .ant-input-prefix {
        font-weight: 600;
    }
}
