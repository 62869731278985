@use 'src/style/common';

.accepted-load {
    display: flex;
    flex-direction: column;
    width: 100%;

    .heading {
        margin: common.$size-spacing-0 0 common.$size-spacing-3 common.$size-spacing-1;
        font-weight: 700;
        line-height: common.$size-spacing-5;
        letter-spacing: common.$size-letter-spacing-0;
        display: inline-flex;
        padding-right: common.$size-spacing-1;

        .broker-actions {
            margin-left: auto;
            font-weight: 400;

            .action {
                padding: unset;
            }

            .broker-menu {
                color: common.$color-neutral-0;
            }

            .icon {
                font-size: common.$size-font-5;
                color: common.$color-neutral-0;
                position: relative;
            }

            .dropdown {
                white-space: unset;
            }

            .broker-actions-popup {
                height: auto;

                p {
                    line-height: 20px;
                    letter-spacing: 0.25px;
                }

                .description {
                    margin-bottom: common.$size-spacing-4;
                }

                .subheading {
                    font-weight: 600;
                    margin-left: 0;
                    color: common.$color-neutral-0;
                }

                .select {
                    height: 40px;
                    margin-bottom: common.$size-spacing-5;
                }

                textarea {
                    margin-bottom: common.$size-spacing-5;
                }

                .buttons-wrapper {
                    text-align: end;
                }
            }
        }
    }

    .details {
        margin-left: common.$size-spacing-1;
        margin-right: common.$size-spacing-1;
        display: inline-flex;
        justify-content: space-between;

        .bid-information-details,
        .bid-information-actions {
            display: flex;
            gap: common.$size-spacing-2;
        }

        .tooltip-content {
            line-height: common.$size-spacing-3;
        }

        .field {
            margin-right: common.$size-spacing-4;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .falloff,
            .cancel {
                color: common.$color-neutral-6;
            }

            .field-title {
                display: inline-block;
                margin-bottom: common.$size-spacing-1;
            }

            &.carrier {
                width: 225px;
                position: relative;

                .name {
                    @include common.ellipsis-word-wrap;
                    font-weight: 700;

                    a {
                        color: inherit;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    &.red {
                        color: common.$color-red-4;
                    }

                    &.grey {
                        color: common.$color-neutral-6;
                    }

                    .tooltip-content {
                        line-height: initial;
                        font-weight: initial;
                    }
                }
            }

            &:first-child {
                &.total {
                    width: 249px;

                    .text-emphasis-box {
                        width: fit-content;
                    }
                }
            }

            &.deadline {
                .time {
                    font-weight: 600;
                    line-height: common.$size-spacing-4;
                }

                .expired {
                    color: common.$color-orange-5;
                }
            }

            &.status {
                .description {
                    display: block;
                    font-weight: 600;
                    line-height: common.$size-spacing-4;
                }

                .icon-div {
                    position: relative;
                    display: inline;
                    color: common.$color-default-icon-state;
                    margin-left: common.$size-spacing-0;

                    .icon {
                        font-size: common.$size-font-1;
                    }

                    &:hover {
                        color: common.$color-hover-state;
                        cursor: pointer;
                    }
                }

                .ready {
                    color: common.$color-cyan-3;
                }

                .pending,
                .warning {
                    color: common.$color-orange-5;
                }

                .falloff {
                    white-space: nowrap;
                }

                .cancel,
                .not-sent {
                    color: common.$color-red-4;
                    white-space: nowrap;
                }
            }

            &.expires-in {
                .title {
                    display: flex;
                    align-items: flex-end;
                }

                .amount {
                    &.warning {
                        color: common.$color-orange-5;
                    }
                }

                .unit {
                    color: common.$color-neutral-4;
                    margin-left: common.$size-spacing-0;
                }

                .icon-div {
                    position: relative;
                    display: inline;
                    color: common.$color-default-icon-state;
                    margin-left: common.$size-spacing-0;
                    cursor: pointer;

                    .icon {
                        font-size: common.$size-font-2;
                    }

                    &:hover {
                        color: common.$color-hover-state;
                    }
                }
            }

            &.carrier-actions {
                display: flex;
                flex-direction: row;
                gap: common.$size-spacing-2;
                margin-right: 0;
            }
        }
    }

    #popup-download-rate-configuration .popup {
        min-height: 240px;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .banner-wrapper {
        margin: common.$size-spacing-0 0 common.$size-spacing-1 common.$size-spacing-1;
    }
}
