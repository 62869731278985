@use 'src/style/common';

.text-emphasis-box {
    @include common.rounded-corners-0;
    font-weight: 600;
    padding: 0 common.$size-spacing-0;
    min-width: fit-content;

    &.large {
        font-size: common.$size-font-5;
    }

    &.medium {
        font-size: common.$size-font-4;
    }

    &.small {
        font-size: common.$size-font-2;
    }

    &.orange {
        @include common.emphasis-orange;
    }

    &.cyan {
        @include common.emphasis-cyan;
    }

    &.grey {
        @include common.emphasis-grey;
    }

    &.blue {
        @include common.emphasis-blue;
    }

    &.dark-grey {
        @include common.emphasis-dark-grey;
    }

    &.red {
        @include common.emphasis-red;
    }

    &.wide {
        padding-left: common.$size-spacing-1;
        padding-right: common.$size-spacing-1;
    }
}
