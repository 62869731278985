@use 'src/style/common';

.coordinators {
    .component {
        margin-bottom: 0;
    }

    .empty-card {
        display: flex;
        gap: common.$size-spacing-4;

        .content-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: common.$size-spacing-3;
            border: common.$size-border solid common.$color-neutral-8;
            border-radius: common.$size-border-wide;

            .description {
                margin-top: common.$size-spacing-2;
                color: common.$color-neutral-4;
            }
        }
    }

    .add-existing {
        height: 144px;
        border: common.$size-border solid common.$color-neutral-8;
        box-sizing: border-box;
        border-radius: common.$size-border-wide;
        padding: common.$size-spacing-4;

        &:not(:first-child) {
            margin-top: common.$size-spacing-4;
        }

        .title {
            font-weight: 600;
            line-height: common.$size-spacing-4;
            margin-bottom: common.$size-spacing-2;
        }

        .content {
            display: flex;
            gap: common.$size-spacing-1;
            align-items: flex-end;
            margin-top: common.$size-spacing-2;

            .select-label {
                font-weight: 600;
                line-height: common.$size-spacing-3;
            }

            .existing-select {
                width: 360px;
                margin-right: common.$size-spacing-3;
            }

            .select-option {
                .select-option-email {
                    color: common.$color-neutral-4;
                }
            }

            .add-existing-error-message {
                margin-left: common.$size-spacing-3;
            }
        }
    }

    .add-coordinator-buttons {
        display: flex;
        gap: common.$size-spacing-1;
        justify-content: flex-end;
        margin-top: common.$size-spacing-2;
    }
}
