@use 'src/style/common';

.upload-container {
    .upload-region {
        position: relative;
        height: 145px;
        padding: common.$size-spacing-5;
        margin-top: common.$size-spacing-0;
        margin-bottom: common.$size-spacing-1;
        border: 1px dashed common.$color-neutral-8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .upload-icon {
            color: common.$color-neutral-6;
            font-size: common.$size-font-5;
        }

        .upload-text {
            text-align: center;
        }

        .file-input {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    &.uploaded {
        .upload-icon {
            color: common.$color-primary-5;
        }
    }
}
