@use 'src/style/common';

.user-profile-page {
    .heading-card {
        height: 80px;
        padding-top: 0;
        padding-left: common.$size-spacing-5;
        margin-bottom: 40px;
        display: flex;

        .user-name-icon {
            position: relative;
            background-color: common.$color-neutral-8;
            width: common.$size-spacing-6;
            height: common.$size-spacing-6;
            margin-top: common.$size-spacing-3;
            border-radius: common.$size-spacing-4;

            &.invalid {
                border: common.$size-border solid common.$color-red-4;
            }

            text-align: center;
            line-height: common.$size-spacing-6;
            font-weight: 600;
            font-size: common.$size-font-3;

            .initials {
                max-width: common.$size-spacing-6;
                @include common.ellipsis-word-wrap;
            }

            .account-warning-icon {
                line-height: initial;
                color: common.$color-red-4;
                position: absolute;
                bottom: -4px;
                right: 0;
                font-size: common.$size-font-3;

                svg {
                    cursor: pointer;
                }

                .tooltip-content {
                    font-weight: 400;
                }
            }
        }

        .user-name {
            margin-left: common.$size-spacing-3;
            margin-top: common.$size-spacing-4;
        }
    }
}

.profile-not-found {
    text-align: center;
    margin-top: 25%;
    margin-bottom: 25%;

    .heading {
        display: block;
    }
}
