@use 'src/style/common';

.multiple-input-field-container {
    line-height: common.$size-spacing-3;

    .input-label {
        display: block;
        text-align: left;
        font-weight: 600;
        margin-bottom: common.$size-spacing-0;

        .icon-div {
            @include common.tooltip-container;
            display: inline;
            font-weight: initial;
        }
    }
   
    .multiple-input-field {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        row-gap: common.$size-spacing-1;
        overflow-x: auto;

        background: common.$color-component-background-light;
        border: common.$size-border solid common.$color-neutral-8;
        border-radius: common.$size-border-wide;
        padding: common.$size-spacing-1 common.$size-spacing-2;
        font-size: common.$size-font-2;
        line-height: common.$size-spacing-4;
        color: common.$color-neutral-0;

        .tag-wrapper {
            margin-right: common.$size-spacing-1;

            .tag {
                margin-bottom: unset;
            }
        }

        input {
            flex: 1;
            min-width: 60px;

            border: none;
            background-image: none;
            background-color: transparent;
            box-shadow: none;
            text-shadow: none;
        
            &:focus {
                outline: none;
            }
        }
    }

    .multiple-text-input-note {
        color: common.$color-neutral-6;
        margin-top: common.$size-spacing-0;
    }
}
