@use 'src/style/common';

.rfp-header-card-container {

    .rfp-header-card {
        padding: common.$size-spacing-4;

        sup {
            line-height: common.$size-spacing-3;
            font-weight: 700;
            color: common.$color-neutral-4;
        }

        .rfp-header-card-exit-view-only-mode-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: common.$size-spacing-3;

            a {
                color: inherit;

                .exit-icon {
                    margin-left: common.$size-spacing-0;
                    color: common.$color-hover-state;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .rfp-header-card-title-row {
            display: flex;
            justify-content: space-between;

            .rfp-header-card-title-row-column {
                sup {
                    letter-spacing: 0.094rem;
                }

                .rfp-header-card-title {
                    display: flex;
                    align-items: flex-end;
                    line-height: 26px;
                    max-width: 988px;
                    word-break: break-word;
                    position: relative;
                    
                    #rfp-header-name {
                       width: 900px;
                       @include common.ellipsis-word-wrap;
                    }

                    .eye-icon-container {
                        position: relative;

                        .eye-icon {
                            width: common.$size-spacing-4;
                            height: common.$size-spacing-4;
                            margin-right: common.$size-spacing-1;
                            background-color: common.$color-neutral-9;
                            border: common.$size-border solid common.$color-neutral-0;
                            border-radius: common.$size-spacing-0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .tooltip-content {
                            line-height: common.$size-spacing-3;
                        }
                    }

                    .rfp-header-card-description {
                        margin-left: common.$size-spacing-1;
                    }
                }

                &.actions {
                    display: flex;
                    gap: common.$size-spacing-2;

                    .carrier-rfp-actions {
                        display: flex;
                        gap: common.$size-spacing-2;
                    }

                    .fa-download {
                        margin-right: common.$size-spacing-0;
                    }
                }
            }
        }

        .rfp-header-card-statistics-row {
            display: flex;
            justify-content: space-between;
            margin-top: common.$size-spacing-5;

            .rfp-header-card-statistics-row-column {
                display: flex;
                gap: 24px;

                .rfp-header-card-statistics-row-column-field {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    &.notifications .horizontal-card-component-body, 
                    &.dynamic-lanes .horizontal-card-component-body {
                        display: inline;

                        .not-subscribed,
                        .not-dynamic-lanes {
                            color: common.$color-neutral-4;
                        }

                        .icon-div {
                            position: relative;
                            display: inline;
                            cursor: pointer;
                            color: common.$color-default-icon-state;
                            margin-left: common.$size-spacing-0;

                            .icon {
                                font-size: common.$size-font-1;
                            }
            
                            &:hover {
                                color: common.$color-hover-state;
                            }
                        }
                    }

                    &.notifications {
                        .tooltip-content {
                            width: 257px;
                        }
                    }

                    .warning {
                        color: common.$color-orange-5;
                    }

                    .dedicated-icon {
                        color: common.$color-red-6;
                        margin-left: common.$size-spacing-1;
                    }

                    .sm-icon {
                        color: common.$color-primary-5;
                        margin-left: common.$size-spacing-1;
                    }
                }
            }
        }

        .rfp-header-card-warning-row {
            margin-top: common.$size-spacing-2;
        }
    }
}
