@use 'src/style/common';

.user-activity-log {
    height: auto;

    .heading {
        display: flex;

        .activity-log-information {
            .title {
                .icon {
                    margin-right: 6px;
                }
            }
    
            .description {
                color: common.$color-neutral-4;
            }
        }

        .timestamp {
            width: 300px;
            margin-left: auto;
            margin-top: - common.$size-font-4;
        }
    }

    .actions-container {
        display: flex;
        gap: common.$size-spacing-4;
        margin-top: common.$size-spacing-4;
        margin-bottom: common.$size-spacing-4;

        .action-field {
            &.search {
                .search-filter-container {
                    .text-input {
                        width: 1055px;
                    }
                }
            }

            &.activity-type {
                width: 192px;
            }
        }
    }

    .user-activity-infinite-scroll {
        height: 45vh;
        padding-bottom: 59px;
    }
}
