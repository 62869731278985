@use 'src/style/common';

.button {
    @include common.component-background;
    @include common.component-border;
    @include common.rounded-corners-0;

    display: inline-block;
    position: relative;
    border-width: common.$size-border;
    margin: 0;
    cursor: pointer;
    font-weight: 700;
    text-decoration: none;
    color: common.$color-neutral-6;
    white-space: nowrap;

    // Size Variants ///////////////////////////////////////////////////////////////////////////////////////////////////

    &.regular {
        padding: common.$size-spacing-1 common.$size-spacing-4;
        font-size: common.$size-font-2;
        line-height: common.$size-font-5;
        height: (common.$size-spacing-1 * 2) + common.$size-font-5;

        .icon {
            &.left {
                margin-left: -(common.$size-spacing-0);
                margin-right: common.$size-spacing-1;

                &.no-content {
                    margin-right: -(common.$size-spacing-2);
                }
            }

            &.right {
                margin-left: common.$size-spacing-3;
                margin-right: -(common.$size-spacing-2);

                &.no-content {
                    margin-left: -(common.$size-spacing-2);
                }
            }
        }
    }

    &.small {
        padding: common.$size-spacing-1 common.$size-spacing-3;
        font-size: common.$size-font-1;
        line-height: common.$size-font-3;
        height: (common.$size-spacing-1 * 2) + common.$size-font-3;

        .icon {
            &.left {
                margin-left: -(common.$size-spacing-0);
                margin-right: common.$size-spacing-1;

                &.no-content {
                    margin-right: -(common.$size-spacing-0);
                }
            }

            &.right {
                margin-left: common.$size-spacing-2;
                margin-right: -(common.$size-spacing-1);

                &.no-content {
                    margin-left: -(common.$size-spacing-1);
                }
            }
        }
    }

    // Type Variants ///////////////////////////////////////////////////////////////////////////////////////////////////

    &.primary {
        background-color: common.$color-cyan-3;
        border-color: common.$color-cyan-3;
        color: white;

        &:hover {
            background-color: common.$color-cyan-2;
            border-color: common.$color-cyan-2;
        }

        &:active {
            background-color: common.$color-cyan-1;
            border-color: common.$color-cyan-1;
        }

        &[disabled] {
            cursor: not-allowed;
            background-color: common.$color-neutral-8;
            border-color: common.$color-neutral-8;
            color: common.$color-neutral-6;
        }
    }

    &.secondary {
        background-color: common.$color-primary-5;
        border-color: common.$color-primary-5;
        color: white;

        &:hover {
            background-color: common.$color-primary-4;
            border-color: common.$color-primary-4;
        }

        &:active {
            background-color: common.$color-primary-3;
            border-color: common.$color-primary-3;
        }

        &[disabled] {
            cursor: not-allowed;
            background-color: common.$color-neutral-8;
            border-color: common.$color-neutral-8;
            color: common.$color-neutral-6;
        }
    }

    &.secondary-alt {
        border-color: common.$color-primary-5;
        color: common.$color-primary-5;

        &:hover {
            border-color: common.$color-primary-4;
            color: common.$color-primary-4;
        }

        &:active {
            border-color: common.$color-primary-3;
            color: common.$color-primary-3;
        }

        &[disabled] {
            cursor: not-allowed;
            border-color: common.$color-neutral-8;
            color: common.$color-neutral-6;
        }
    }

    &.tertiary {
        color: common.$color-neutral-0;

        &:hover {
            border-color: common.$color-neutral-6;
        }

        &:active {
            border-color: common.$color-neutral-0;
        }

        &[disabled] {
            cursor: not-allowed;
            background-color: transparent;
            border-color: common.$color-neutral-8;
            color: common.$color-neutral-7;
        }
    }

    &.tertiary-alt {
        color: common.$color-neutral-6;
        background-color: transparent;
        border: none;

        &:hover {
            color: common.$color-neutral-0;
        }

        &[disabled] {
            cursor: not-allowed;
            background-color: transparent;
            color: common.$color-neutral-7;
        }
    }

    &.danger {
        background-color: common.$color-red-4;
        border-color: common.$color-red-4;
        color: white;

        &:hover {
            background-color: common.$color-red-3;
            border-color: common.$color-red-3;
        }

        &:active {
            background-color: common.$color-red-2;
            border-color: common.$color-red-2;
        }

        &[disabled] {
            cursor: not-allowed;
            background-color: common.$color-neutral-8;
            border-color: common.$color-neutral-8;
            color: common.$color-neutral-6;
        }
    }

    &.brand {
        background-color: common.$color-brand-red-1;
        border-color: common.$color-brand-red-1;
        color: white;

        &:hover {
            background-color: common.$color-brand-red-2;
            border-color: common.$color-brand-red-2;
        }

        &:active {
            background-color: common.$color-brand-red-0;
            border-color: common.$color-brand-red-0;
        }

        &[disabled] {
            cursor: not-allowed;
            background-color: common.$color-neutral-8;
            border-color: common.$color-neutral-8;
            color: common.$color-neutral-6;
        }
    }
}
