@use 'src/style/common';

.sm-carriers-card {
    .tab-placeholder {
        min-width: common.$size-spacing-4;
    }

    .spot-market-candidates-card {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        .header {
            padding-left: common.$size-spacing-4;
            padding-right: common.$size-spacing-4;
        }

        .content-card-content {
            padding: common.$size-spacing-4;
        }
    }

    .sm-carriers-empty {
        text-align: center;
        padding: 70px 0;

        .icon {
            width: 50px;
            height: 40px;
            color: common.$color-neutral-6;
        }
    }

    .pagination-container {
        width: 100%;
        margin-top: common.$size-spacing-4;
    }
    
    .banner {
        margin-top: common.$size-spacing-2;
    }

    .sm-quick-actions {
        display: flex;        
        gap: common.$size-spacing-2;
    }

    .sm-actions {
        .button {
            &.tertiary,
            &.small {
                padding-right: common.$size-spacing-4;
            }
        }

        .popup {
            .action-name {
                color: common.$color-cyan-3;
            }
            white-space: initial;
        }

        .dropdown {
            min-width: 194px;

            .action-icon {
                width: common.$size-font-3;
                height: common.$size-spacing-2;
                margin-right: common.$size-spacing-0;
                color: common.$color-neutral-0;
            }
        }
    }

    .sm-carriers-table {
        width: 100%;

        .custom-table {
            width: 1223px;

            .header-cell {
                border-bottom: 1px solid common.$color-neutral-8;
    
                &.name {
                    width: 390px;
                }
    
                &.negotiated-rate {
                    width: 143px;
                    padding-left: 0;
                }
    
                &.email {
                    width: 300px;
                }
    
                &.date {
                    width: 280px;
                }
            }

            .table-cell {
                line-height: 18px;

                &:last-of-type {
                    padding-right: common.$size-spacing-3;
                }
            }
        }
    }

    .sm-carrier-loader {
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
