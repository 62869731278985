@use 'src/style/common';
@use 'sass:math';

.users-list-page {

    .users-list {
        $item-gap: common.$size-spacing-4;

        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        column-gap: $item-gap;
        row-gap: $item-gap;
        margin-top: common.$size-spacing-2;
        margin-bottom: common.$size-spacing-4;

        &.empty-list {
            padding-top: common.$size-spacing-10;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: initial;

            .users-icon {
                font-size: common.$size-font-8;
                margin-bottom: common.$size-spacing-2;
            }
        }
    }

    .users-tabs {
        margin-top: common.$size-spacing-4;

        .tab-content-container {
            padding-bottom: common.$size-spacing-7;

            .actions-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                column-gap: common.$size-spacing-2;

                .action-field {
                    height: 65px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    margin-bottom: common.$size-spacing-3;

                    &.search {
                        flex-grow: 1;
                        .search-filter-container {
                            .text-input {
                                width: 100%;
                            }
                        }
                    }

                    &.status {
                        width: 164px;
                    }
                }
            }

            .users-table {
                margin-bottom: common.$size-spacing-6;
                width: 100%;
                overflow: visible;

                .tag-wrapper {
                    margin-right: common.$size-spacing-0;

                    .user-role-label {
                        background-color: rgba(97, 110, 124, 0.08);
                        margin-bottom: 0;
                        cursor: initial;

                        .left-icon {
                            color: common.$color-neutral-4;
                            margin-right: 6px;
                        }

                        &.additional-roles {
                            cursor: pointer;
                        }

                        &.main-role {
                            &:hover {
                                border: common.$size-border solid transparent;
                            }
                        }
                    }
                }

                .table-cell {
                    position: relative;

                    .user-roles-popover {
                        display: inline;

                        .user-roles-popover-content {
                            padding: common.$size-spacing-0;
                            display: flex;
                            flex-direction: column;
                            gap: common.$size-spacing-0;
                        }
                    }

                    &.user-name-content,
                    &.user-email-content,
                    &.user-phone-content {
                        width: 246px;
                        max-width: 246px;
                        @include common.ellipsis-word-wrap;

                        a {
                            color: initial;
                        }
                    }
                }

                .active-user .user-status {
                    color: common.$color-cyan-3;
                }

                .pending-user .user-status {
                    color: common.$color-orange-4;
                }

                .terminated-user .table-cell {
                    color: common.$color-neutral-5;
                }
            }

            .user-loader {
                display: flex;
                flex-direction: column;
                margin-top: common.$size-spacing-10;

                p {
                    text-align: center;
                    margin-top: common.$size-spacing-2;
                }
            }
        }
    }
}
