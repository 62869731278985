@use 'src/style/common';

.activity-row {
    display: flex;
    gap: common.$size-spacing-2;
    margin-top: common.$size-spacing-3;
    margin-bottom: common.$size-spacing-3;
    
    .activity {
        .activity-information {
            display: flex;
            gap: common.$size-spacing-1;
            margin-bottom: common.$size-spacing-0;

            .activity-submitter {
                @include common.ellipsis-word-wrap;
                max-width: 200px;
            }

            .activity-created {
                color: common.$color-neutral-5;
                margin-top: common.$size-spacing-min;
            }
        }
    }
}
