@use 'src/style/common';

.pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: common.$color-dark-text-1;

    &.medium {
        background-color: common.$color-neutral-9;
    }

    &.light {
        background-color: common.$color-component-background-light;
    }

    &.fixed {
        position: fixed;
        padding: common.$size-page-left-right-padding;
        width: calc(100% - 3.5rem);
        height: 56px;
        z-index: 98;
        left: 3.5rem;
        bottom: 0;
        @include common.component-border;
        border-top-width: common.$size-border;
    }

    b {
        color: common.$color-dark-text-0;
    }

    .pagination-quantity-display-container {
        color: common.$color-neutral-0;
        flex-grow: 1;
        flex-basis: 0;
    }

    .pagination-control-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 common.$size-spacing-1;

        .pagination-control {
            padding: 0 common.$size-spacing-1;
            margin: 0 common.$size-spacing-0;
            cursor: pointer;

            &.disabled {
                cursor: default;
                color: common.$color-dark-text-2;
            }
        }

        .pagination-display-container {
            padding: 0 common.$size-spacing-0;
            color: common.$color-neutral-0;

            .pagination-input {
                @include common.component-border;
                @include common.rounded-corners-0;

                width: 40px;
                height: 32px;
                outline: none;
                border-width: common.$size-border;
                text-align: center;
                margin: 0 common.$size-spacing-1;
                font-weight: bold;
                color: common.$color-neutral-0;
            }
        }
    }

    .pagination-quantity-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
        flex-basis: 0;
        color: common.$color-neutral-0;

        .select {
            background-color: common.$color-component-background-light;
            width: common.$size-spacing-7;
            margin: 0 common.$size-spacing-1;
        }
    }
}
