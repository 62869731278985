@use 'src/style/common';

.custom-radio-group {
    font-weight: initial;

    .ant-radio-checked .ant-radio-inner {
        border: common.$size-border-wide solid common.$color-neutral-0;

        :focus {
            border-color: common.$color-neutral-0;
        }
    }

    .ant-radio-inner::after {
        background-color: transparent;
    }

    .ant-radio:hover .ant-radio-inner {
        border-color: common.$color-neutral-0;
    }

    .ant-radio-button-wrapper-checked {
        color: common.$color-neutral-0;
    }

    .ant-radio-wrapper-disabled span {
        color: common.$color-neutral-4;
    }

    .ant-radio-checked::after {
        border: common.$size-border-wide solid common.$color-neutral-0;
    }
}
