@use 'src/style/common';

.add-on-demand-carrier-popup {
    height: auto;
    white-space: normal;

    .carrier-name {
        font-weight: 600;
    }

    .per-mile-price {
        font-weight: 600;
        color: common.$color-neutral-4;
    }
}
