@use 'src/style/common';

.filter-popup {
    .heading {
        font-weight: 700;
        line-height: 32px;
        margin-bottom: common.$size-spacing-3;

        .filter-icon {
            margin-right: common.$size-spacing-1;
        }
    }

    .filter-fields {
        margin-top: common.$size-spacing-2;
        margin-bottom: common.$size-spacing-5;

        .field {
            margin-bottom: common.$size-spacing-3;
        }
    }

    .buttons-wrapper {
        text-align: end;
        margin-top: common.$size-spacing-2;
    }
}
