@use 'src/style/common';

.custom-date-picker {
    width: 100%;
    display: flex;
    height: 40px;
    align-items: center;
    border: common.$size-border solid common.$color-neutral-8;
    box-sizing: border-box;
    border-radius: common.$size-border-wide;

    &:hover {
        border-color: common.$color-neutral-7;   
    }

    &:focus {
        border-color: common.$color-primary-5;
    }

    .date-picker-icon {
        margin-left: 18px;
        margin-right: 18px;
        font-weight: 600;
    }

    .ant-picker {
        padding-left: 0;
    }

    .ant-picker .ant-picker-active-bar,
    .ant-picker-cell-in-view .ant-picker-cell-selected .ant-picker-cell-inner {
        background: common.$color-primary-5;
        cursor: pointer;
    }

    .ant-picker-input > input {
        cursor: pointer;
        text-align: left;
        font-weight: 600;
    }
}
