@use 'src/style/common';

.time-display {
    .number {
        font-weight: 600;
        font-size: common.$size-font-6;
        line-height: common.$size-font-6;
        margin-right: common.$size-spacing-min;

        &:not(:first-child) {
            margin-left: common.$size-spacing-0;
        }

        &.default:not(:first-child) {
            margin-left: common.$size-spacing-1;
        }

        &.big {
            font-size: common.$size-font-5;
        }

        &.normal {
            font-size: common.$size-font-4;
        }

        &.small {
            font-size: common.$size-font-3;
            line-height: common.$size-spacing-4;
        }

        &.warning {
            color: common.$color-orange-5;
        }
    }

    .description {
        font-size: common.$size-font-0;
        font-weight: 400;
        color: common.$color-neutral-4;

        &.normal:first-child {
            width: 28px;
            margin-right: common.$size-spacing-1;
        }
    }

    .emphasized {
        font-weight: 400;
        font-size: common.$size-font-1;
        color: common.$color-neutral-0;
    }
}
