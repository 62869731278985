@use 'src/style/common';

.switch-component {
    line-height: common.$size-spacing-3;

    .switch-wrapper {
        margin-bottom: common.$size-spacing-0;

        .custom-switch {
            &.checked {
                background-color: common.$color-cyan-3;
            }
        }

        .switch-label {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: common.$color-neutral-0;
            margin-left: common.$size-spacing-2;
        }

        .disabled-label {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .info-message {
        color: common.$color-neutral-5;
    }
}
