@use 'src/style/common';

.members-view {
    .actions-container {
        padding-top: common.$size-spacing-0;
        display: flex;
        gap: common.$size-spacing-2;

        .action-field {
            height: 65px;
            margin-bottom: common.$size-spacing-3;

            &.search {
                flex-grow: 1;
                
                .search-filter-container {
                    .text-input {
                       width: auto;
                    }
                }
            }

            &.status {
                width: 195px;
            }

            &.add {
                margin-top: 20px;
            }
        }
    }

    .members-table {
        width: 1272px;

        .members-header-row {
            border-bottom: 1px solid common.$color-neutral-8;

            .header-cell {
                &.name {
                    width: 257px;
                }

                &.email {
                    width: 313px;
                }

                &.phone {
                    width: 269px;
                }

                &.member-status {
                    width: 145px;
                }

                &.date-added {
                    width: 194px;
                }
            }
        }
    }

    .pagination-container {
        margin-top: common.$size-spacing-4;
    }

    .members-empty-state {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50vh;

        .empty-state-icon {
            display: flex;
            width: 56px;
            height: 56px;
            background-color: common.$color-orange-9;
            border-radius: 100%;
            justify-content: center;
            align-items: center;
            margin-bottom: common.$size-spacing-2;

            .icon {
                width: 20px;
                height: 20px;
                color: common.$color-orange-3;
            }
        }

        .empty-state-title {
            font-weight: 600;
            margin-bottom: common.$size-spacing-0;
        }
    }
}
