@use 'src/style/common';

.auction-table-cell.equipment-type {
    width: 55px;
    display: flex;
    gap: common.$size-spacing-0;

    .icon-container {
        width: common.$size-spacing-4;
        height: common.$size-spacing-4;
        border-radius: common.$size-spacing-0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &:hover {
            cursor: pointer;
        }

        &.dry {
            background-color: common.$color-neutral-8;
            color: common.$color-neutral-4;

            &:hover {
                background-color: common.$color-neutral-7;
                color: common.$color-neutral-2;
            }
        }

        &.reefer {
            background-color: common.$color-primary-8;
            color: common.$color-primary-5;

            &:hover {
                background-color: common.$color-primary-7;
                color: common.$color-primary-3;
            }
        }

        &.flatbed {
            background-color: common.$color-orange-8;
            color: common.$color-orange-4;

            &:hover {
                background-color: common.$color-orange-7;
                color: common.$color-orange-2;
            }
        }
    }
}
