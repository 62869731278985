@use 'src/style/common';

.load-details-page {

    .accept-tos-banner {
        width: 100%;
        border: common.$size-border solid common.$color-orange-5;
        margin-bottom: common.$size-spacing-4;

        .accept-tos-trigger {
            color: common.$color-orange-5;
        }

        .separator {
            margin-left: - common.$size-spacing-5;
            margin-right: - common.$size-spacing-5;
            width: unset;
        }

        .accept-popup-actions {
            display: flex;
            justify-content: space-between;

            .custom-checkbox {
                margin-top: common.$size-spacing-5;
                line-height: 40px;
            }

            .buttons-wrapper button {
                width: 125px;
            }
        }
    }

    .offer-popup {
        .popup-header {
            display: flex;
            margin-bottom: common.$size-spacing-5;

            .popup-header-section {
                display: flex;
                flex-direction: column;

                .popup-header-section-title {
                    font-size: 12px;
                    font-weight: 600;

                    span {
                        font-weight: 400;
                    }
                }

                .popup-header-section-description {
                    font-size: 10px;
                    display: flex;
                    align-items: flex-end;
                    gap: 4px;
                    color: common.$color-neutral-4;
                }

                .popup-header-icon {
                    color: common.$color-neutral-6;
                    width: 12px;
                    height: 15px;

                    &.arrow {
                        margin: 0 4px;
                    }
                }

                &.region {
                    max-width: 130px;

                    div {
                        @include common.ellipsis-word-wrap;
                    }
                }

                &.price {
                    margin-left: auto;
                    align-items: flex-end;
                }
            }

            .separator.vertical {
                margin: 0 16px;
                width: 1px;
                height: 34px;
            }
        }

        &.accept-popup {
            .heading {
                margin-top: common.$size-spacing-5;
            }
        }
    }
    
    .component {
        margin-bottom: common.$size-spacing-4;
    }

    .sm-main-content {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: common.$size-spacing-4;

        .bid-history-div {
            grid-column: 1/9;
            padding: 0;
            margin-bottom: common.$size-spacing-4;
            display: flex;
            flex-direction: column;

            .title {
                font-weight: 600;
                line-height: common.$size-spacing-5;
                letter-spacing: 0.15px;
                padding: common.$size-spacing-4;
                display: flex;
                flex-direction: row;

                .banner {
                    margin-left: auto;
                }
            }

            .content {
                padding-left: common.$size-spacing-4;
                padding-right: common.$size-spacing-4;
                padding-top: common.$size-spacing-4;
            }

            .pagination-container {
                padding-bottom: common.$size-spacing-3;
                padding-left: common.$size-spacing-4;
                padding-right: common.$size-spacing-4;
                margin-top: auto;
            }
        }

        .current-bid-div {
            grid-column: 9/13;
        }
    }
}
