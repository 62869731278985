@use 'src/style/common';

.sm-bids-content-row {
    .name {
        min-width: 343px;

        &.last-element {
            border-bottom-left-radius: common.$size-spacing-0;
        }

        .name-content {
            display: flex;
            align-items: center;
            gap: 3px;

            .carrier-warning {
                position: relative;
                font-weight: normal;
                font-size: common.$size-font-0;
                margin-right: common.$size-spacing-0;
                display: flex;

                .icon {
                    margin-bottom: common.$size-spacing-min;
                    color: common.$color-red-4;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            a {
                color: inherit;

                &:hover {
                    text-decoration: underline;
                }
            }

            .name-link {
                .name-ellipsis {
                    @include common.ellipsis-word-wrap;
                    max-width: 313px;
                }
            }
        }
    }

    .bid{
        min-width: 175px;
    }

    .per-mile{
        width: 120px;
    }

    .date-of-bid {
        min-width: 274px;
    }

    .actions {
        min-width: 274px;

        &.last-element {
            border-bottom-right-radius: common.$size-spacing-0;
        }

        .broker-bid-actions {
            position: relative;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
    
            .menu-icon-container {
                display: flex;
                padding-right: 20px;

                &.disabled {
                    .icon {
                        color: common.$color-neutral-7;
                    }
                }
    
                .icon {
                    font-size: common.$size-font-3;
                    color: common.$color-neutral-6;
                    position: relative;
                }
            }
    
            .dropdown {
                white-space: unset;
            }
        }
    }
}
