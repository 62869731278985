@use 'src/style/common';

td.table-cell {

    &.side-padding {
        padding-left: common.$size-spacing-2;
        padding-right: common.$size-spacing-2;
    }

    &:first-of-type {
        padding-left: common.$size-spacing-4;
    }
    
    &:last-of-type {
        padding-right: common.$size-spacing-4;
    }

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }

    &.center {
        text-align: center;
    }

    &.small {
        height: 40px;
    }

    &.regular {
        padding-top: common.$size-spacing-2;
        padding-bottom: common.$size-spacing-2;
    }

    &.large {
        padding-top: common.$size-spacing-3;
        padding-bottom: common.$size-spacing-3;
    }
}
