@use 'src/style/common';

.empty-table {
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: common.$size-border solid common.$color-neutral-9;
    border-radius: common.$size-spacing-0;
    padding-top: 40px;

    &.with-background {
        background-image: url('../../file/svg/full-empty-table-state.svg');
    }

    .empty-table-icon-container {
        margin-bottom: common.$size-spacing-2;
        width: 56px;
        height: 56px;
        display: grid;
        place-items: center;
        border-radius: common.$size-font-7;
        border: common.$size-border solid common.$color-neutral-9;
        background: common.$color-component-background-light;
        box-shadow: 0px 8px 8px -6px rgba(203, 210, 217, 0.64);

        .empty-table-icon {
            color: common.$color-cyan-3;
            width: 25px;
            height: 19px;
        }
    }

    .empty-table-description {
        color: common.$color-neutral-4;
        margin-top: 2px;
        margin-bottom: 12px;
        max-width: 442px;
        text-align: center;
    }

    .empty-table-actions {
        display: flex;
        gap: common.$size-spacing-2;
    }
}
