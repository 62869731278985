@use 'src/style/common';

.tag-wrapper { 
    cursor: pointer;
    position: relative;
    display: inline;
    margin-right: common.$size-spacing-2;

    .tag { 
        margin-bottom: common.$size-spacing-1;  
        padding-left: common.$size-spacing-0;
        background: common.$color-neutral-8;
        border: common.$size-border solid transparent;
        border-radius: common.$size-spacing-0;
        line-height: common.$size-spacing-4;
        width: fit-content;
        display: inline-flex;
    
        &.error {
            background: common.$color-red-9;
        }
    
        &:hover {
            border: common.$size-border solid common.$color-neutral-7;
            box-sizing: border-box;
            border-radius: common.$size-spacing-0;
    
            .close-icon {
                color: common.$color-red-4;
                cursor: pointer;
            }
    
            &.error {
                border-color: common.$color-red-4;
            }
        }
    
        .title {
            font-weight: 600;
            margin-right: common.$size-spacing-min;
        }
    
        .text {
            word-break: break-word;
            color: common.$color-neutral-0;
            margin-right: common.$size-spacing-0;
        }
    
        .left-icon {
            color: common.$color-red-4;
            margin-right: common.$size-spacing-0;
        }
    
        .close-icon {
            color: common.$color-neutral-6;
            margin-right: common.$size-spacing-0;
        }
    }

    .tooltip {
        margin-top: common.$size-spacing-0; 
    }
}
