@use 'src/style/common';

.application-details-page {

    .loading-line {
        @include common.loading-shimmer;
        width: 500px;
        height: 22px;
        margin: common.$size-spacing-2 0;
    }

    .content {
        margin-top: common.$size-spacing-2;

        p {
            display: block;
            margin: common.$size-spacing-3 0;
        }
    }
}
