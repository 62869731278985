@use 'src/style/common';

.carrier-payment-details {

    .carrier-payment-details-action {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: common.$size-spacing-2;

        .carrier-payment-details-search {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-bottom: common.$size-spacing-3;

            .multi-search-container {
                .multiple-input-field {
                    width: 1052px;

                    input {
                        height: 26px;
                    }
                }
            }
        }
        
        .payment-details-download {
            height: 44px;
            margin-top: common.$size-spacing-0;
        }
    }

    &.empty-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: common.$size-spacing-10;
        row-gap: initial;

        .icon {
            font-size: common.$size-font-8;
            color: common.$color-brand-red-1;
            margin-bottom: common.$size-spacing-2;
        }
    }

    .payments-list {
        font-size: common.$size-font-1;

        .header-cell {
            background-color: common.$color-neutral-8;
        }

        .table-cell {
            background-color: common.$color-component-background-light;
        }

        .load-id {
            width: 200px;
            
            .load-link {
                color: common.$color-primary-5;
                text-decoration: underline;

                .load-link-icon {
                    font-size: 10px;
                    color: common.$color-primary-5;
                    margin-left: common.$size-spacing-0;
                    text-decoration: none;
                }
            }
        }

        .type {
            width: 174px;
        }

        .description {
            @include common.ellipsis-word-wrap;
            width: 497px;
            max-width: 497px;
        }

        .amount {
            width: 150px;
        }

        .pay-date {
            width: 250px;
        }

        .no-results {
            padding-top: common.$size-spacing-3;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
