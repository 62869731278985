@use 'src/style/common';

.page-with-navigation {
    $navigation-size: 3.5rem;

    padding-left: $navigation-size;

    .page-content {
        box-sizing: content-box;
        width: common.$size-page-width;
        padding: $navigation-size 50px common.$size-spacing-5 50px;
        margin: auto;
    }
}
