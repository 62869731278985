@use 'src/style/common';

.tender-candidate-offer-actions {
    display: flex;
    gap: common.$size-spacing-1;
    justify-content: center;
    align-items: center;

    .tender-candidate-offer-popup {
        white-space: initial;

        .offer-details {
            padding-left: common.$size-spacing-5;
        }
    }
}
