@use 'src/style/common';

.header-connection-monitor {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .connection-monitor {
        position: relative;
        font-size: common.$size-font-3;
        margin: 0 common.$size-spacing-0;
        display: flex;
        align-items: center;
        justify-content: center;

        .connection-monitor-badge {
            display: flex;
            width: 32px;
            height: 20px;
            border: 1px solid #f9703e;
            border-radius: 100px;
            background-color: #f9703e14;
            justify-content: center;
            align-content: space-around;
            flex-direction: column;
            align-items: center;

            svg {
                width: 12px;
                height: 12px;
                color: #f9703e;
            }
        }
    }
}
