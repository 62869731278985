@use 'src/style/common';

.simple-table {
    @include common.component-border;
    @include common.rounded-corners-0;

    border-width: common.$size-border;
    width: 100%;
    margin: common.$size-spacing-4 0;
    overflow: unset;

    .simple-table-row {

        &.simple-table-header {

            .simple-table-column {
                font-size: common.$size-font-1;
                font-weight: 400;
            }
        }

        .simple-table-column {
            @include common.component-border;
            border-bottom-width: common.$size-border;

            padding: common.$size-spacing-2 common.$size-spacing-4;
            font-size: common.$size-font-1;
            font-weight: 600;

            &.simple-table-header-col {
                border-bottom-width: 0;
                padding-bottom: 0;
            }

            &.left {
                text-align: left;
            }

            &.center {
                text-align: center;
            }

            &.right {
                text-align: right;
            }
        }
    }
}
