@use 'src/style/colors';
@use 'src/style/common';

.page-navigation {
    @include common.component-background;
    @include common.component-border;
    $width: 3.5rem;

    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    bottom: 0;
    width: $width;

    border-right-width: common.$size-border;
    border-right-style: solid;

    .page-navigation-container {
        min-height: calc(100vh - 3.5rem);
        padding-bottom: 52px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .page-navigation-header {
        @include common.component-border;

        display: flex;
        align-items: center;
        justify-content: center;
        width: $width;
        height: $width;

        border-bottom-width: common.$size-border;
    }

    .page-navigation-item {
        @include common.rounded-corners-1;
        $item-size: $width - (2 * common.$size-spacing-1);

        position: relative;
        display: block;
        width: $item-size;
        height: $item-size;
        line-height: $item-size;
        margin: common.$size-spacing-1;

        color: colors.$dark-text-2;
        font-size: common.$size-font-4;
        text-align: center;

        cursor: pointer;

        * {
            line-height: initial;
        }

        &.active {
            background-color: colors.$neutral-9;
            color: colors.$dark-text-0;
        }

        &:hover {
            background-color: colors.$neutral-8;
            color: colors.$dark-text-0;
        }

        &:active {
            box-shadow: inset 0 0 common.$size-spacing-1 colors.$neutral-7;
        }

        @if common.$dark-mode-enabled {
            @media (prefers-color-scheme: dark) {
                &.active {
                    background-color: colors.$neutral-0;
                    color: colors.$light-text-1;
                }

                &:hover {
                    background-color: colors.$neutral-1;
                    color: colors.$light-text-1;
                }

                &:active {
                    box-shadow: inset 0 0 common.$size-spacing-1 colors.$neutral-2;
                }
            }
        }
    }
}
