@use 'src/style/common';

.logistics-coordinators-card {
    .logistics-coordinators-table {
        width: 100%;
        margin: common.$size-spacing-4 0;

        .table-cell {
            &.user-name-content,
            &.user-email-content {
                width: 500px;
                max-width: 500px;
                @include common.ellipsis-word-wrap;
            }
        }

        &.empty-table {
            padding: common.$size-spacing-5 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            .empty-table-description {
                color: common.$color-neutral-4;
            }
        }
    }
}
