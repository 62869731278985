@use 'src/style/common';

.extend-offer-popup-container {
    .extend-offer-popup {
        text-align: left;

        .description {
            .expiration {
                font-weight: 600;
            }
        }

        .end-date-picker {
            margin-bottom: common.$size-spacing-5;
            .label {
                font-weight: 600;
            }
        }
    }
}
