@use 'src/style/common';

.labeled-separator {
    padding-top: common.$size-spacing-3;
    
    .label {
        border: 1px solid common.$color-neutral-8;
        border-radius: common.$size-spacing-0;
        font-weight: 600;
        background: common.$color-component-background-light;
        width: fit-content;
        margin: 0 auto;
        margin-top: - common.$size-spacing-3;
        padding: common.$size-spacing-0 common.$size-spacing-3;
    }
}
