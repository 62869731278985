@use 'src/style/common';

.horizontal-card-component-container {

    .horizontal-card-component-title {
        color: common.$color-neutral-4;

        &.small {
            letter-spacing: 0.094rem;
        }
    }

    &.left {
        text-align: left;
    }

    &.center {
        text-align: center;
    }

    &.right {
        text-align: right;
    }
}
