@use 'src/style/common';

.separator {
    background-color: common.$color-border-light;

    @if common.$dark-mode-enabled {
        @media (prefers-color-scheme: dark) {
            background-color: common.$color-border-dark;
        }
    }

    &.horizontal {
        width: 100%;
        height: common.$size-border;
    }

    &.vertical {
        width: common.$size-border;
        min-height: common.$size-font-0;
    }
}
