@use 'src/style/common';

.sm-content-row {
    .name {
        width: 390px;

        .name-content {
            display: flex;
            align-items: center;
            gap: 3px;
            width: 354px;

            .carrier-warning {
                position: relative;
                font-weight: normal;
                font-size: common.$size-font-0;
                margin-right: common.$size-spacing-0;
                display: flex;
                align-items: flex-end;

                .icon {
                    margin-bottom: common.$size-spacing-min;
                    color: common.$color-red-4;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            a {
                color: inherit;

                &:hover {
                    text-decoration: underline;
                }
            }

            .name-link {
                .name-ellipsis {
                    @include common.ellipsis-word-wrap;
                    max-width: 375px;
                }
            }
        }
    }

    .negotiated-rate {
        min-width: 145px;
    }

    .email {
        min-width: 300px;

        .email-ellipsis {
            @include common.ellipsis-word-wrap;
            max-width: 275px;
        }
    }

    .date {
        min-width: 220px;
    }

    .actions {
        width: 171px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &.single-action {
            width: 85px;
        }

        .action {
            display: inline-block;
            height: 20px;
            width: common.$size-spacing-4;
            padding: 0 common.$size-spacing-0;
        
            .icon {
                font-size: common.$size-font-1;
            }
        }
    }
}
