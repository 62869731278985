
@use 'src/style/common';

.custom-checkbox {
    line-height: common.$size-spacing-3;

    .checkbox-label {
        color: common.$color-neutral-0;
    }

    &.large {
        .ant-checkbox,
        .ant-checkbox-inner {
            font-size: common.$size-font-0;
            width: 14px;
            height: 14px;
        }

        .ant-checkbox-inner {
            border: common.$size-border solid common.$color-neutral-0;
        }

        .checkbox-label {
            font-size: common.$size-font-2;
            font-weight: 600;
        }
    }

    &.regular {
        .ant-checkbox,
        .ant-checkbox-inner {
            width: common.$size-spacing-2;
            height: common.$size-spacing-2;
        }
    }
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: common.$color-neutral-0;
    border: none;
}

.ant-checkbox-checked::after {
    border: none;
}

.ant-checkbox-wrapper:hover
.ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: common.$color-neutral-0;
}
