@use 'src/style/common';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html {
    font-size: common.$size-root;
}

body {
    @include common.page-background;

    margin: 0;

    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    * {
        box-sizing: border-box;
    }

    *:hover > .tooltip {
        visibility: visible;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
p,
small,
sub,
sub {
    margin: 0;
    padding: 0;
}

h1 {
    font-size: common.$size-font-9;
    font-weight: 700;
}

h2 {
    font-size: common.$size-font-8;
    font-weight: 700;
}

h3 {
    font-size: common.$size-font-7;
    font-weight: 700;
}

h4 {
    font-size: common.$size-font-6;
    font-weight: 700;
}

h5 {
    font-size: common.$size-font-5;
    font-weight: 700;
}

h6 {
    font-size: common.$size-font-4;
    font-weight: 400;
}

strong {
    font-size: common.$size-font-3;
    font-weight: 400;
}

b {
    font-weight: 700;
}

p {
    font-size: common.$size-font-2;
    font-weight: 400;
}

small {
    font-size: common.$size-font-1;
    font-weight: 400;
}

sup {
    font-size: common.$size-font-0;
    font-weight: 600;
    vertical-align: baseline;
}

sub {
    font-size: common.$size-font-0;
    font-weight: 400;
    vertical-align: baseline;
}

.page {
    @include common.page;
    @include common.page-background;
    width: common.$size-page-width;
}

.component {
    &.main-card {
        margin: 0 0 common.$size-spacing-4 0;
    }

    @include common.component-background;
    @include common.component-border;
    @include common.rounded-corners-0;

    border-width: common.$size-border;
    border-style: solid;

    margin: 0 0 common.$size-spacing-2 0;
    padding: common.$size-spacing-2 common.$size-spacing-3;
}

.login-screen {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    font-size: common.$size-font-5;

    .loading-icon {
        color: common.$color-primary-4;
    }

    .fail-icon {
        color: common.$color-red-5;
    }

    p {
        text-align: center;
        margin-top: common.$size-spacing-1;
    }

    pre {
        @include common.component-background;
        @include common.component-border;
        @include common.rounded-corners-0;
        border-width: common.$size-border;

        width: 470px;
        padding: common.$size-spacing-3;
        font-size: common.$size-font-1;
        max-height: common.$size-spacing-11;
        overflow-y: auto;
        white-space: pre-wrap;
    }
}
