@use 'src/style/common';

.unknown-page {
    position: relative;
    background-color: common.$color-brand-black-1;
    color: common.$color-component-background-light;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .background {
        position: absolute;
        width: auto;
        height: 100vh;
        right: 0;
    }

    .logo {
        position: absolute;
        top: common.$size-spacing-5;
        z-index: 1;
    }

    .title {
        font-size: common.$size-font-10;
        font-weight: 700;
        line-height: 112px;
        padding-bottom: common.$size-spacing-4;
        z-index: 1;
    }

    .subtitle {
        color: common.$color-component-background-light;
        line-height: common.$size-spacing-5;
        padding-bottom: common.$size-spacing-1;
        z-index: 1;
    }

    .description {
        color: common.$color-component-background-light;
        line-height: common.$size-spacing-5;
        padding-bottom: common.$size-spacing-4;
        max-width: 624px;
        z-index: 1;
    }
}
