@use 'src/style/common';

.tendering-details-page {
    .heading #broker-actions-dropdown .action-name {
        display: inline-block;
        width: fit-content;
        padding-left: common.$size-spacing-0;
        padding-right: common.$size-spacing-0;
    }

    .tmw-data-mismatch-message {
        width: 100%;
        margin-bottom: common.$size-spacing-4;
        border-radius: common.$size-border-wide;
        border: common.$size-border solid common.$color-red-4;
        line-height: 18px;
        padding-top: 10px;
        padding-bottom: 10px;

        .mismatch-label {
            font-weight: 600;
        }

        &.with-actions {
            align-items: center;
        }

        &.warn { 
            border-color: common.$color-orange-5;
        }
    }

    .canceled-auction-restart {
        display: flex;
        margin-top: common.$size-spacing-4;
        margin-bottom: common.$size-spacing-3;
        align-items: center;

        .dropdown-item {
            background: common.$color-component-background-light;
            width: 176px;
            line-height: 40px;
            text-align: center;
            border: common.$size-border solid common.$color-neutral-8;
            border-radius: common.$size-spacing-0;
            margin-right: common.$size-spacing-2;

            &:hover {
                cursor: pointer;
                border: 1px solid common.$color-neutral-6;
            }

            .action-name {
                font-size: common.$size-font-2;
                font-weight: bold;
            }

            .action-icon {
                color: common.$color-red-4;
                margin-right: common.$size-spacing-2;
            }
        }
    }

    .logistics-coordinators-card {
        margin-bottom: common.$size-spacing-4;
    }
}
