@use 'src/style/common';

.edit-carrier-negotiated-rate {
    .edit-negotiated-rate-popup {
        text-align: left;

        .heading {
            margin-bottom: common.$size-spacing-1;
        }

        .subheading {
            margin-bottom: common.$size-spacing-min;
            line-height: common.$size-spacing-4;
        }
    }

    .action-container {
        position: relative;
    }
}
