@use 'src/style/common';

.notification-page {
    .mark-read-link {
        line-height: 36px;

        text-align: right;
        text-decoration-line: underline;

        &.disabled {
            color: common.$color-neutral-7;
        }

        .link {
            cursor: pointer;
        }
    }

    .notification-list {
        background-color: common.$color-component-background-light;
        padding: 0;

        .separator {
            width: unset;
            margin-left: common.$size-spacing-3;
            margin-right: common.$size-spacing-3;
        }

        .notification-card-link {
            text-decoration: none;
            color: inherit;
            cursor: initial;
        }
    }

    .no-notifications {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: common.$size-spacing-10;

        .bell-icon {
            font-size: common.$size-font-8;
            color: common.$color-brand-red-1;
            margin-bottom: common.$size-spacing-2;
        }
    }
}
