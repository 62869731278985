@use 'src/style/common';

.rfp-lane-header-card-container {

    .rfp-lane-header-card {
        padding: common.$size-spacing-4;

        .rfp-lane-header-card-exit-view-only-mode-row {
            display: flex;
            justify-content: space-between;
            margin-bottom: common.$size-spacing-3;

            a {
                color: inherit;

                .exit-icon {
                    margin-left: common.$size-spacing-0;
                    color: common.$color-hover-state;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .rfp-lane-header-card-title-row {
            margin-bottom: common.$size-spacing-4;
        }

        .rfp-lane-header-card-details-row {
            display: flex;
            justify-content: space-between;

            .rfp-lane-header-card-details-row-group {
                display: flex;
                gap: common.$size-spacing-4;
            }

            .horizontal-card-component-title {
                font-weight: 700;
            }
        }
    }
}
