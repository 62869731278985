@use 'src/style/common';

.collapsable-filter-container {
    margin-top: common.$size-spacing-3;

    .tendering-filters-container {
        display: flex;
        column-gap: common.$size-spacing-3;
        row-gap: common.$size-spacing-1;
        flex-wrap: wrap;

        .field {
            width: 224px;
    
            &.range-picker-field {
                width: 268px;
            }
    
            .custom-select-container .select {
                background-color: common.$color-component-background-light;
            }
        }
    
        .clear-all-container {
            display: flex;
            align-items: flex-end;
            margin-bottom: common.$size-spacing-1;
            color: common.$color-primary-5;
        }
    }
}
