@use 'src/style/common';

.help-center-link {
    position: relative;
    
    .help-center-guide {
        text-align: left;
        margin-bottom: common.$size-spacing-3;
    }
    
    .help-center-dismiss-guide {
        float: right;
    }
}
