@use 'src/style/common';

.rfp-candidate-list-container {
    margin-top: common.$size-spacing-2;

    .search-filter {
        .text-input {
            width: 408px;
            height: 40px;
        }
    }

    .rfp-candidate-name {
        max-width: 400px;
        width: 400px;
        @include common.ellipsis-word-wrap;

        .link {
            color: inherit;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .delete-candidate-action {
        color: common.$color-neutral-6;

        &:hover {
            cursor: pointer;
            color: common.$color-hover-state;
        }
    }

    .remove-carrier-popup {
        width: 250px;
        text-align: left;
    }

    .carrier-add-actions {   
        display: flex;
        justify-content: center;
        align-items: center;

        .csv-upload-container {
            margin-left: common.$size-spacing-2;
        }
    }

    .empty-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: common.$size-spacing-4;
        padding-bottom: common.$size-spacing-8;

        .user-icon {
            font-size: common.$size-font-8;
        }

        .content-card {
            width: 100%;
        }

        .carrier-add-actions {
            margin-top: common.$size-spacing-2;
        }
    }

    .rfp-delete-candidate-popup {
        text-align: left;
    }
}
