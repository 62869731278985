@use 'src/style/common';

.carrier-details {
    .offer-popup {
        &.accept-popup {
            .separator {
                margin-left: - common.$size-spacing-5;
                margin-right: - common.$size-spacing-5;
                width: unset;
            }
    
            .accept-popup-actions {
                display: flex;
                justify-content: space-between;
    
                .custom-checkbox {
                    margin-top: common.$size-spacing-5;
                    line-height: 40px;
                }
    
                .buttons-wrapper button {
                    width: 125px;
                }
            }
        }
    }

    .broker-tos-info-popup {
        line-height: common.$size-spacing-4;
        color: common.$color-neutral-0;

        .banner {
            margin-top: common.$size-spacing-4;
        }
    }

    .card-subheading {
        color: common.$color-neutral-3;
        margin-top: common.$size-spacing-0;
        margin-left: common.$size-spacing-1;
        display: flex;
        
        .carrier-name { 
            max-width: 190px;             
            @include common.ellipsis-word-wrap;
        }
    }

    .carrier-details-subtitle-wrapper {
        display: flex;
        align-items: center;
        gap: common.$size-spacing-3;

        .switch-wrapper {
            margin-bottom: 0;
        }

        .carrier-details-subtitle {
            .required-indicator {
                color: common.$color-red-4;
            }
        }

        .driver-info-not-required-popup {
            .banner .banner-content {
                line-height: 16px;
            }
        }
    }

    .carrier-details-description {
        color: common.$color-neutral-5;
    }

    .tooltip-icon {
        position: relative;
        display: inline;
        color: common.$color-default-icon-state;
        margin-left: common.$size-spacing-0;

        &.active {
            color: common.$color-hover-state;
        }

        &:hover {
            color: common.$color-hover-state;
            cursor: pointer;
        }
    }

    .transportation-icon {
        color: common.$color-neutral-6;
    }

    .info-provided {
        display: flex;
        gap: common.$size-spacing-4;
        justify-content: space-between;

        .section {
            flex: 1;

            .driver-info-not-required-component {
                flex-direction: column;
                height: 98px;
            }

            .component {
                display: flex;
                justify-content: space-between;
                padding: common.$size-spacing-4 common.$size-spacing-4;
                margin-top: common.$size-spacing-2;
                margin-bottom: 0;
                
                .field {
                    margin-right: common.$size-spacing-5;
                    display: flex;
                    flex-direction: column;
        
                    .title {
                        margin-bottom: common.$size-spacing-1;
                        width: 100%;
                    }
        
                    .content {
                        font-weight: 600;
                        margin-left: 0;
                        width: 100%;
                        @include common.ellipsis-word-wrap;
                        gap: 32px;
                    }

                    &.name {
                        &.dry {
                            width: 194px;
                        }

                        &.reefer,
                        &.flatbed {
                            width: 312px;
                        }
                    }

                    &.phone {
                        width: 124px;
                    }
        
                    &.truck,
                    &.trailer {
                        width: 72px;
                    }

                    &.trailer {
                        &.reefer,
                        &.flatbed {
                            margin-right: 0;
                        }
                    }

                    &.trailer-type {
                        width: 86px;
                        margin-right: 0;

                        .transportation-icon {
                            margin-right: common.$size-spacing-1;
                        }
                    }
                }
            }
        }
    }

    .edit-button-wrapper {
        text-align: right;
        margin-top: common.$size-spacing-2;
    }

    .details-form {
        height: fit-content;
        display: flex;
        flex-direction: column;

        .driver-info-not-required-form {
            margin-top: common.$size-spacing-1;
            padding: common.$size-spacing-3 common.$size-spacing-4;
        }

        .input-fields {
            display: grid;
            gap: common.$size-spacing-2;
            padding-top: common.$size-spacing-3;
            padding-bottom: common.$size-spacing-2;

            &.dry {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }
            
            &.reefer,
            &.flatbed {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            .select-option {
                .option-icon {
                    width: 15px;
                    margin-right: common.$size-spacing-0;
                }
            }
        }

        .payment-details {
            display: flex;
            padding-top: common.$size-spacing-3;

            .custom-radio-group {
                display: flex;
            }

            .select {
                width: 320px;
            }

            .button-wrapper {
                display: flex;
                flex-direction: row;
                gap: common.$size-spacing-1;
                margin-left: auto;
            }
        }

        .carrier-details-subtitle:last-of-type {
            margin-top: 20px;
        }

        .driver-details-invalid-error-message {
            color: common.$color-red-4;
        }
    }

    .banner {
        .banner-content {
            display: flex;

            .carrier-name {
                max-width: 190px;
                @include common.ellipsis-word-wrap;
            }
        }
    }
}
