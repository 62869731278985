@use 'src/style/common';

.routing-guide-lane-details-page {
    .pending-offer-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-color: common.$color-orange-5;
        padding-left: common.$size-spacing-4;
        padding-right: common.$size-spacing-4;

        .flex-component {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: common.$size-spacing-0;
        }

        .offer-information {
            .icon {
                font-size: common.$size-font-2;
                color: common.$color-orange-5;
            }
        }

        .offer-actions {
            gap: common.$size-spacing-2;
        }

        .offer-remaining-time {
            .icon {
                font-size: common.$size-font-3;
                color: common.$color-neutral-4;
            }
        }
    }

    .price-history-content-card {
        .price-history-table {
            padding-bottom: common.$size-spacing-4;
            
            .custom-table {
                width: 1223px;

                .price {
                    width: 130px;
                }

                .volume {
                    width: 100px;
                }

                .offer-status {
                    width: 150px;
                    font-weight: 600;

                    &.active {
                        color: common.$color-cyan-3;
                    }
                
                    &.pending {
                        color: common.$color-orange-5;
                    }
                
                    &.rejected {
                        color: common.$color-red-3;
                    }
                
                    &.expired,
                    &.deleted,
                    &.replaced {
                        color: common.$color-neutral-4;
                    }
                }
            }
        }
    }
}
