@use 'src/style/common';

.rfp-terms-of-service-page {
    display: flex;
    justify-content: center;

    .rfp-terms-of-service-content {
        width: 624px;
        margin: common.$size-spacing-4 0;
        padding: common.$size-spacing-5;

        .logo {
            width: 112px;
            margin-bottom: common.$size-spacing-4;
        }

        h6 {
            font-size: common.$size-font-2;
            font-weight: 700;
            margin-bottom: common.$size-spacing-1;
        }

        small {
            display: block;
            margin-bottom: common.$size-spacing-2;
            font-weight: normal;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .rfp-terms-of-service-checkbox-container {
            user-select: none;
            margin-top: common.$size-spacing-4;
            margin-bottom: common.$size-spacing-3;

            * {
                cursor: pointer;
            }

            label {
                margin-left: common.$size-spacing-0;
                font-size: common.$size-font-1;
                font-weight: normal;
            }
        }
    }

    .rfp-terms-of-service-actions {
        display: flex;

        :first-child {
            margin-left: auto;
        }
    }
}
