@use 'src/style/common';

.bill-to-ids {
    position: relative;
    display: flex;
    max-width: 350px;

    .first-bill-to {
        @include common.ellipsis-word-wrap;
    }

    &.small > .first-bill-to {         
        max-width: 80px;
    }

    &.medium > .first-bill-to {
        max-width: 150px;
    }

    .bill-to-popover {
        display: inline;

        .icon {
            color: common.$color-default-icon-state;
            margin-left: common.$size-spacing-0;

            &.active {
                color: common.$color-hover-state;
            }

            &:hover {
                color: common.$color-hover-state;
                cursor: pointer;
            }
        }

        .bill-to-popover-content {
            padding: common.$size-spacing-2 common.$size-spacing-2 common.$size-spacing-2 common.$size-spacing-4;
            margin-bottom: 0;

            li {
                line-height: common.$size-spacing-3;
            }
        }
    }
}
